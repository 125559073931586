import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import {
	Button,
	Row,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
} from 'reactstrap';
import { FaBan, FaRegCheckCircle, FaShieldAlt, FaSlidersH } from 'react-icons/fa';
import Switch from 'rc-switch';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';

import { ModalScreen } from '../Modal/Screen/Screen';
import { ModalScreenHeader } from '../Modal/Screen/Header';
import { ButtonPill, ButtonPillOutline } from '../Button';
import { useScreenMode, ModeScreenLayout } from '../Studio/ScreenMode/Provider';
import { useCookies } from './Provider';
import { list } from './index';
import { disableRollbar, enableRollbar } from '../../lib/rollbar';
import { Path } from '../../RoutePath';

import './Approval.scss';

const { VITE_GA_TRACKING_ID } = import.meta.env;

const CookiesSettings = ({ isOpen, toggle }) => {
	const { t } = useTranslation();
	const {
		accept,
		cookiesUpdated,
		reject,
		acceptAll,
		rejectAll,
		setCookiesUpdated,
		isAccepted,
		toggleCategory,
	} = useCookies();

	const close = () => {
		setCookiesUpdated(false);
		toggle();
	};

	const handleClickAcceptAll = () => {
		acceptAll();
		close();
	};

	const handleClickRejectAll = () => {
		rejectAll();
		close();
	};

	const handleClickSwitch = (isCookieAccepted, category, cookieName, id) => {
		if (isCookieAccepted) {
			accept(id);
		} else {
			reject(id);
		}
	};

	const toogleCookieCategory = (category, isCookieAccepted) => {
		toggleCategory(list.find((l) => l.category === category).cookiesList, isCookieAccepted);
	};

	return (
		<ModalScreen
			centered
			size="xl"
			isOpen={isOpen}
			toggle={toggle}
			onClose={toggle}
		>
			<ModalScreenHeader
				buttons={(
					<div className="d-flex flex-wrap justify-content-end ml-auto">
						<ButtonPillOutline
							className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
							color="secondary"
							title={t('Cookies.Approval.rejectAll')}
							onClick={handleClickRejectAll}
						>
							{t('Cookies.Approval.rejectAll')}
						</ButtonPillOutline>
						<ButtonPill
							className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
							color="primary"
							title={t('Cookies.Approval.acceptAll')}
							onClick={handleClickAcceptAll}
						>
							{t('Cookies.Approval.acceptAll')}
						</ButtonPill>
						{cookiesUpdated && (
							<ButtonPillOutline
								className="CookiesApproval_Icon_Button ml-2 mb-1 flex-shrink-0"
								color="success"
								title={t('Cookies.Approval.saveAndClose')}
								onClick={close}
							>
								{t('Cookies.Approval.save')}
							</ButtonPillOutline>
						)}
					</div>
				)}
				onClose={close}
				title={t('Cookies.Approval.cookiesManagement')}
			/>
			<Container fluid>
				<Row>
					<Col lg="8" className="mx-auto">
						{list.map((cookieData) => (
							<section
								className="mb-3"
								key={cookieData.category}
							>
								<h4 className="mb-0 mt-4"><strong>{t(cookieData.category)}</strong></h4>
								<p>{t(cookieData.description)}</p>
								<div className="mb-3">
									{cookieData.canBeDeactivated && (
										<>
											<ButtonPillOutline
												className="CookiesApproval_Icon_Button ml-auto"
												color="secondary"
												title={t('Cookies.Approval.rejectAll')}
												onClick={() => toogleCookieCategory(cookieData.category, false)}
											>
												{t('Cookies.Approval.rejectAll')}
											</ButtonPillOutline>
											<ButtonPillOutline
												className="CookiesApproval_Icon_Button ml-2"
												color="secondary"
												title={t('Cookies.Approval.acceptAll')}
												onClick={() => toogleCookieCategory(cookieData.category, true)}
											>
												{t('Cookies.Approval.acceptAll')}
											</ButtonPillOutline>
										</>
									)}
								</div>
								{cookieData.cookiesList.map((cookie) => (
									<article className="d-flex" key={cookie.id}>
										<div>
											<strong>{t(cookie.name)}</strong>
											<p>{t(cookie.description)}</p>
										</div>
										<div className="ml-3 ml-md-4">
											{cookieData.canBeDeactivated && (
												<Switch
													checked={isAccepted(cookie.id)}
													onClick={(e) => {
														handleClickSwitch(e, cookieData.category, cookie.name, cookie.id);
													}}
													className="CookiesApproval_Switch switch-medium toggle-switch-success"
													checkedChildren="on"
													unCheckedChildren="off"
												/>
											)}
										</div>
									</article>
								))}
							</section>
						))}
					</Col>
				</Row>
			</Container>
		</ModalScreen>
	);
};

CookiesSettings.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

export const CookiesApproval = () => {
	const { t } = useTranslation();
	const { acceptAll, rejectAll, isAccepted, cookies, showApproval } = useCookies();
	const isStudioRoute = useRouteMatch(Path.STUDIO_HOST);
	const isChannelRoute = useRouteMatch(Path.CHANNEL);
	const isOperatorRoute = useRouteMatch(Path.STUDIO_OPERATOR);
	const isParticipantRoute = useRouteMatch(Path.STUDIO_PARTICIPANT);
	const { currentModeScreen } = useScreenMode();

	const [showModal, setShowModal] = useState(showApproval);
	const [showModalPrivacySettings, setShowModalPrivacySettings] = useState(false);

	useEffect(() => setShowModal(showApproval), [showApproval]);

	useEffect(() => {
		if (isAccepted('functionalGa')) {
			window[`ga-disable-${VITE_GA_TRACKING_ID}`] = false;
			ReactGA.initialize(VITE_GA_TRACKING_ID, { debug: false });
		} else {
			window[`ga-disable-${VITE_GA_TRACKING_ID}`] = true;
		}
		if (isAccepted('functionalRollbar')) {
			enableRollbar();
		} else {
			disableRollbar();
		}
		if (isAccepted('socialNetworks')) {
			//load social networks plugins
		} else {
			//unload social networks plugins (if enabled)
		}
	}, [cookies, isAccepted]);

	const toggleModal = () => setShowModal(!showModal);
	const toggleSettingsModal = () => {
		setShowModal(false);
		setShowModalPrivacySettings(!showModalPrivacySettings);
	};

	const handleClickAcceptAll = () => {
		acceptAll();
		toggleModal();
	};

	const handleClickRejectAll = () => {
		rejectAll();
		toggleModal();
	};

	if (isChannelRoute
		|| isStudioRoute
		|| isOperatorRoute
		|| isParticipantRoute
	) return null;

	return (
		<div className="CookiesApproval">
			{!showModal ? (
				currentModeScreen !== ModeScreenLayout.FULLSCREEN && (
					<div className="CookiesApproval_Icon ml-3 mb-3 bottom-0">
						<Button
							className="CookiesApproval_Icon_Button d-40 p-0 mr-2"
							color="neutral-secondary"
							title={t('Cookies.Approval.privacySettings')}
							onClick={toggleModal}
						>
							<span className="btn-wrapper--icon d-inline-block">
								<FaShieldAlt size={20} />
							</span>
						</Button>
					</div>
				)
			) : (
				<Modal
					isOpen={showModal}
					toggle={toggleModal}
					zIndex={1800}
					className="CookiesApproval_Choices position-absolute ml-3 mb-3 bottom-0 text-black"
				>
					<ModalHeader toggle={toggleModal}>
						<strong>{t('Cookies.Approval.privacyChoices')}</strong>
					</ModalHeader>
					<ModalBody>
						<p>{t('Cookies.Approval.cookiesControl')}</p>
						<ButtonPill className="d-flex w-100 mb-2 align-items-center" color="primary" onClick={handleClickAcceptAll}>
							<span className="btn-wrapper--icon d-inline-block">
								<FaRegCheckCircle className="mr-2" />
							</span>
							{t('Cookies.Approval.acceptAll')}
						</ButtonPill>
						<ButtonPill className="d-flex w-100 mb-2 align-items-center" color="primary" onClick={handleClickRejectAll}>
							<span className="btn-wrapper--icon d-inline-block">
								<FaBan className="mr-2" />
							</span>
							{t('Cookies.Approval.rejectAll')}
						</ButtonPill>
						<ButtonPill className="d-flex w-100 mb-2 align-items-center" color="primary" onClick={toggleSettingsModal}>
							<span className="btn-wrapper--icon d-inline-block">
								<FaSlidersH className="mr-2" />
							</span>
							{t('Cookies.Approval.personalize')}
						</ButtonPill>
					</ModalBody>
				</Modal>
			)}
			{showModalPrivacySettings && (
				<CookiesSettings
					isOpen={showModalPrivacySettings}
					toggle={toggleSettingsModal}
				/>
			)}
		</div>
	);
};
CookiesApproval.propTypes = {
};
