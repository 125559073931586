import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FaCropAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const CropButton = ({
	className,
	handleToggleCropVideo,
}) => {
	const { t } = useTranslation();

	return (
		<Button
			className={`d-30 p-0 mr-1 flex-shrink-0 ${className}`}
			color="neutral-secondary"
			title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleCrop')}
			onClick={handleToggleCropVideo}
		>
			<span className="btn-wrapper--icon d-inline-block ">
				<FaCropAlt />
			</span>
		</Button>
	);
};

CropButton.propTypes = {
	className: PropTypes.string,
	handleToggleCropVideo: PropTypes.func.isRequired,
};

CropButton.defaultProps = {
	className: '',
};
