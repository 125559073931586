import { api } from '../api';

export const API_CHANNEL_PATH = '/channel';

export const createDonation = (channelId, payload) => api.post(
	`${API_CHANNEL_PATH}/donation`,
	{
		...payload,
		channelId,
	},
);
