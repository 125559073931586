import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';
import Nouislider from 'nouislider-react';
import { FaEyeDropper } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import {
	defaultKeyConfig,
	KeyDetectionMode,
	KeyReplacementMode,
	useMediaKey,
	useMediaCrop,
} from '@technomiam/react-video';

import { MediaKeyPreviewDouble } from './Preview';

const getPerformConfigStrFromTarget = (color) => {
	const { r, g, b } = color || { r: 255, g: 255, b: 255 };
	return `rgb(${r}, ${g}, ${b})`;
};

function hexToRgb(hex) {
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	hex = hex.replace(shorthandRegex, (m, r, g, b) => (r + r + g + g + b + b));

	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
	return result ? {
		r: parseInt(result[1], 16),
		g: parseInt(result[2], 16),
		b: parseInt(result[3], 16),
	} : undefined;
}

function rgbToHex(r, g, b) {
	// eslint-disable-next-line no-bitwise, no-mixed-operators
	return `#${(1 << 24 | r << 16 | g << 8 | b).toString(16).slice(1)}`;
}

function colorRbgToHex(color) {
	return rgbToHex(color.r, color.g, color.b);
}

export const MediaKeyForm = ({
	initialValue,
	isReplacementConfigOverriden,
	onKeySelectBackground,
}) => {
	const { t } = useTranslation();
	const [value, setValue] = useState({
		...defaultKeyConfig,
		...initialValue,
	});
	const [eyeDropperEnabled, setEyeDropperEnabled] = useState(false);
	const { cropOrUserVideoActiveTracks } = useMediaCrop();
	const { setLocalConfig } = useMediaKey();

	const videoTrack = cropOrUserVideoActiveTracks[0];

	const handleChangeDetectionMode = (e) => {
		setValue((s) => ({
			...s,
			detection: {
				...s.detection,
				mode: e.target.value,
			},
		}));
	};

	const handleSetBackgroundAsset = (assetUrl) => {
		if (assetUrl) {
			setValue((prevState) => ({
				...prevState,
				replacement: {
					...prevState.replacement,
					imageUrl: assetUrl,
				},
			}));
		}
	};

	const handleClickShowLibrary = () => {
		onKeySelectBackground(handleSetBackgroundAsset);
	};

	const handleChangeReplacementMode = (e) => {
		setValue((s) => ({
			...s,
			replacement: {
				...s.replacement,
				mode: e.target.value,
			},
		}));

		if (
			e.target.value === KeyReplacementMode.IMAGE
			&& !value.replacement.imageUrl
		) {
			handleClickShowLibrary();
		}
	};

	const handleChangeReplacementBlur = (inputValue) => {
		setValue((prevState) => ({
			...prevState,
			replacement: {
				...prevState.replacement,
				blur: parseFloat(inputValue[0]),
			},
		}));
	};

	const handleChangeReplacementEdgeBlur = (inputValue) => {
		setValue((prevState) => ({
			...prevState,
			replacement: {
				...prevState.replacement,
				edgeBlur: parseFloat(inputValue[0]),
			},
		}));
	};

	const handleClickRemoveBackground = () => {
		setValue((prevState) => ({
			...prevState,
			replacement: {
				...prevState.replacement,
				imageUrl: undefined,
			},
		}));
	};

	const handleChangeDetectionSensitivity = (inputValue) => {
		setValue((prevState) => ({
			...prevState,
			detection: {
				...prevState.detection,
				sensitivity: parseInt(inputValue[0], 10),
			},
		}));
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setLocalConfig(value);
	};

	const handlePickColor = (r, g, b) => {
		const color = { r, g, b };
		setValue((prevState) => ({
			...prevState,
			detection: {
				...prevState.detection,
				color,
			},
		}));
		setEyeDropperEnabled(false);
	};

	const handleReplacementColorChange = (e) => {
		const color = hexToRgb(e.target.value);
		setValue((prevState) => ({
			...prevState,
			replacement: {
				...prevState.replacement,
				color,
			},
		}));
	};

	const colorAsString = getPerformConfigStrFromTarget(value.detection.color);
	const isGreen = colorAsString === 'rgb(0, 255, 0)';
	const isBlue = colorAsString === 'rgb(0, 0, 255)';

	return (
		<Form
			onSubmit={handleSubmit}
		>
			{value.detection.mode !== KeyDetectionMode.DISABLED && (
				<div className="mb-2">
					<MediaKeyPreviewDouble
						config={value}
						eyeDropperEnabled={eyeDropperEnabled}
						onPickColor={handlePickColor}
						videoTrack={videoTrack}
					/>
				</div>
			)}
			<div className="w-100">
				<Label className="font-weight-bold" for="selectDetectionMode">
					{t('Media.MediaKeyForm.detectionMode')}
				</Label>
				<Input
					id="selectDetectionMode"
					type="select"
					value={value.detection.mode}
					onChange={handleChangeDetectionMode}
					name="detection.mode"
				>
					<option value={KeyDetectionMode.DISABLED}>
						{t('Global.Status', { context: 'disabled' })}
					</option>
					<option value={KeyDetectionMode.AI}>{t('Media.Key.DetectionMode', { context: KeyDetectionMode.AI })}</option>
					<option value={KeyDetectionMode.RGB}>{t('Media.Key.DetectionMode', { context: KeyDetectionMode.RGB })}</option>
				</Input>
			</div>
			{value.detection.mode === KeyDetectionMode.RGB && (
				<div className="mt-2">
					<FormGroup>
						<Label>{t('Media.MediaKeyForm.detection.color')}</Label>
						<div className="d-flex">
							<div
								className="d-40"
								style={{
									backgroundColor: colorAsString,
								}}
							/>
							<Button
								className="d-40 p-0 ml-2 align-self-center"
								color={!eyeDropperEnabled ? 'neutral-secondary' : 'primary'}
								onClick={() => { setEyeDropperEnabled((s) => !s); }}
								title={t('Media.MediaKeyForm.detection.eyeDropper')}
							>
								<FaEyeDropper />
							</Button>
							<Button
								className="d-40 p-0 ml-2 align-self-center"
								color={!isGreen ? 'white' : 'primary'}
								onClick={() => { handlePickColor(0, 255, 0); }}
								style={{ backgroundColor: 'rgb(0, 255, 0)' }}
								title={t('Media.MediaKeyForm.detection.rgb.G.title')}
							>
								{t('Media.MediaKeyForm.detection.rgb.G')}
							</Button>
							<Button
								className="d-40 p-0 ml-2 align-self-center"
								color={!isBlue ? 'white' : 'primary'}
								onClick={() => { handlePickColor(0, 0, 255); }}
								style={{ backgroundColor: 'rgb(0, 0, 255)' }}
								title={t('Media.MediaKeyForm.detection.rgb.B.title')}
							>
								{t('Media.MediaKeyForm.detection.rgb.B')}
							</Button>
						</div>
					</FormGroup>
					<FormGroup>
						<Label>{t('Media.MediaKeyForm.detection.sensitivity')} ({value.detection.sensitivity})</Label>
						<Nouislider
							className="ml-1"
							range={{ min: 0, max: 100 }}
							start={value.detection.sensitivity || 80}
							step={1}
							onSlide={handleChangeDetectionSensitivity}
							style={{ width: '120px' }}
						/>
					</FormGroup>
				</div>
			)}
			<div className="w-100 mt-2">
				<Label className="font-weight-bold" for="selectReplacementMode">
					{t('Media.MediaKeyForm.replacementMode')}
				</Label>
				<Alert color="warning" isOpen={isReplacementConfigOverriden}>
					{t('Media.MediaKeyForm.replacementModeOverriden.filter')}
				</Alert>
				<Input
					id="selectReplacementMode"
					name="replacement.mode"
					onChange={handleChangeReplacementMode}
					type="select"
					value={value.replacement.mode}
				>
					<option value={KeyReplacementMode.BLUR}>{t('Media.Key.ReplacementMode', { context: KeyReplacementMode.BLUR })}</option>
					<option value={KeyReplacementMode.COLOR}>{t('Media.Key.ReplacementMode', { context: KeyReplacementMode.COLOR })}</option>
					<option value={KeyReplacementMode.IMAGE}>{t('Media.Key.ReplacementMode', { context: KeyReplacementMode.IMAGE })}</option>
					<option value={KeyReplacementMode.TRANSPARENT}>{t('Media.Key.ReplacementMode', { context: KeyReplacementMode.TRANSPARENT })}</option>
				</Input>
			</div>
			{(value.replacement.mode === KeyReplacementMode.IMAGE) && (
				<div className="d-flex mt-2">
					{value.replacement.imageUrl ? (
						<div>
							<img
								src={value.replacement.imageUrl}
								width="100"
							/>
							<Button
								className="ml-2"
								color="light"
								onClick={handleClickRemoveBackground}
							>
								{t('Media.MediaKeyForm.replacement.image.remove')}
							</Button>
						</div>
					) : (
						<Button
							color="primary"
							onClick={handleClickShowLibrary}
						>
							{t('Media.MediaKeyForm.replacement.image.select')}
						</Button>
					)}
				</div>
			)}
			{(value.replacement.mode === KeyReplacementMode.BLUR) && (
				<div className="d-flex mt-2">
					<FormGroup className="mr-2 flex-shrink-0">
						<Label>{t('Media.MediaKeyForm.replacement.blur')} ({value.replacement.blur})</Label>
						<Nouislider
							className="ml-1"
							range={{ min: 0, max: 50 }}
							start={value.replacement.blur || 5}
							step={1}
							onSlide={handleChangeReplacementBlur}
							style={{ width: '80px' }}
						/>
					</FormGroup>
				</div>
			)}
			{(value.replacement.mode === KeyReplacementMode.COLOR) && (
				<div className="d-flex mt-2">
					<FormGroup className="mr-2 flex-shrink-0">
						<Label>{t('Media.MediaKeyForm.replacement.color')}</Label>
						<Input
							type="color"
							value={colorRbgToHex(value.replacement.color)}
							onChange={handleReplacementColorChange}
							name="replacement.color"
						/>
					</FormGroup>
				</div>
			)}
			<div className="d-flex mt-2">
				<FormGroup className="mr-2 flex-shrink-0">
					<Label>{t('Media.MediaKeyForm.replacement.edgeBlur')} ({value.replacement.edgeBlur})</Label>
					<Nouislider
						className="ml-1"
						range={{ min: 0, max: 50 }}
						start={value.replacement.edgeBlur || 5}
						step={1}
						onSlide={handleChangeReplacementEdgeBlur}
						style={{ width: '80px' }}
					/>
				</FormGroup>
			</div>
			<footer className="d-flex mt-3">
				<Button
					color="primary"
					className="ml-auto"
					type="submit"
				>
					{t('Media.MediaKeyForm.apply')}
				</Button>
			</footer>
		</Form>
	);
};

MediaKeyForm.propTypes = {
	initialValue: PropTypes.shape({}),
	isReplacementConfigOverriden: PropTypes.bool,
	onKeySelectBackground: PropTypes.func.isRequired,
};

MediaKeyForm.defaultProps = {
	initialValue: undefined,
	isReplacementConfigOverriden: false,
};
