import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Spinner } from 'reactstrap';
import { Gender } from '../../../../Profile/Details/profileDetails.schema';
import { AnalyticsPieChart } from '../PieChart';

export const COLORS = {
	[Gender.MALE]: '#6CA6E5',
	[Gender.FEMALE]: '#B950DE',
	[Gender.LGBTQ_PLUS]: '#80CBC4',
	[Gender.NOT_DISCLOSED]: '#FEC82E',
	NOT_SET: '#651ad9',
};

export const LABELS = {
	[Gender.MALE]: 'Followers.GenderGraph.male',
	[Gender.FEMALE]: 'Followers.GenderGraph.female',
	[Gender.NOT_DISCLOSED]: 'Followers.GenderGraph.notDisclosed',
	[Gender.LGBTQ_PLUS]: 'Followers.GenderGraph.lgbtqPlus',
	NOT_SET: 'Followers.GenderGraph.notSet',
};

export const AnalyticsFollowersGenderGraph = ({ genderAnalytics, loading, error }) => {
	const { t } = useTranslation();

	const formattedGenderAnalytics = useMemo(() => genderAnalytics?.map((entry) => ({
		...entry,
		label: t(LABELS[entry.name]),
		color: COLORS[entry.name],
	})) || [], [genderAnalytics, t]);

	if (error) {
		return (
			<Alert color="danger">
				{t('Global.error')}
			</Alert>
		);
	}

	return !loading ? (
		<div>
			<div className="GrahpHeading AdminGraphHeadline m-0 py-3">
				{t('Followers.GenderGraph.gender')}
			</div>
			{!!genderAnalytics && (
				<AnalyticsPieChart analytics={formattedGenderAnalytics} />
			)}
		</div>
	) : <Spinner />;
};

AnalyticsFollowersGenderGraph.propTypes = {
	genderAnalytics: PropTypes.arrayOf(PropTypes.shape),
	loading: PropTypes.bool,
	error: PropTypes.bool,
};

AnalyticsFollowersGenderGraph.defaultProps = {
	genderAnalytics: [],
	loading: false,
	error: false,
};
