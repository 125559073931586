/* eslint-disable react/prop-types */
// @ts-check

import React from 'react';
import clsx from 'clsx';

import './Hexagon.scss';

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * 	className?: string,
 * 	tag?: React.ElementType,
 * } & React.HTMLAttributes<React.ElementType>} HexagonProps
 */

const Hexagon = (
	/** @type {HexagonProps} */
	{
		children,
		className = '',
		tag: Tag = 'div',
		...props
	},
) => (
	<Tag className={clsx('Hexagon', className)} {...props}>
		<div className="HexagonShape">
			{ children }
		</div>
	</Tag>
);

export default Hexagon;
