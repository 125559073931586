/* eslint-disable react/prop-types */
// @ts-check
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTalkbackReceiver, useTalkbackSender } from '@technomiam/react-video';

import { TalkbackButton } from './Button';

import './ReplyButton.scss';

const UNSET_TIMEOUT = 7500;

/**
 * @typedef {{
 * 	className?: string,
 * 	hashtag: string,
 * }} TalkbackReplyButtonProps
 */

export const TalkbackReplyButton = (
	/** @type {TalkbackReplyButtonProps} */
	{
		className,
		hashtag,
	},
) => {
	const { recipientUser } = useTalkbackSender();
	const isTalkbackSending = !!recipientUser;
	const { talkbackStreamsPublications } = useTalkbackReceiver({ hashtag });

	const [currentTalkbackReceiving, setCurrentTalkbackReceiving] = useState(
		/** @type {((typeof talkbackStreamsPublications)[number])?} */(null),
	);
	const unsetTimeoutRef = useRef(/** @type {NodeJS.Timeout?} */(null));
	const [isReplying, setIsReplying] = useState(false);

	/**
	 * We only show the answer button for the first talkback received.
	 *
	 * If the user is currently replying to another user, we don't update the
	 * current talkback receiving, it'll be done when the user stop replying.
	 */
	useEffect(() => {
		if (unsetTimeoutRef.current) clearTimeout(unsetTimeoutRef.current);

		const isReceiving = talkbackStreamsPublications.length > 0;
		const isReceivingEnded = currentTalkbackReceiving && !isReceiving;

		if (!isReplying) {
			if (isReceiving) {
				const [firstTalkback] = talkbackStreamsPublications;
				setCurrentTalkbackReceiving(firstTalkback);
			} else if (isReceivingEnded) {
				unsetTimeoutRef.current = setTimeout(() => {
					setCurrentTalkbackReceiving(null);
				}, UNSET_TIMEOUT);
			}
		}
	}, [
		currentTalkbackReceiving,
		isReplying,
		setCurrentTalkbackReceiving,
		talkbackStreamsPublications,
	]);

	const handleStartTalkbackReply = useCallback(() => {
		setIsReplying(true);

		if (unsetTimeoutRef.current) clearTimeout(unsetTimeoutRef.current);
	}, []);

	const updateCurrentTalkbackReceiving = useCallback(() => {
		if (talkbackStreamsPublications.length === 0) {
			setCurrentTalkbackReceiving(null);
		} else {
			const [firstTalkback] = talkbackStreamsPublications;
			setCurrentTalkbackReceiving(firstTalkback);
		}
	}, [talkbackStreamsPublications]);

	// Update isReplying stopped when isTalkbackSending is updated (due to debounce
	// time in TalkbackProvider)
	useEffect(() => {
		if (!isTalkbackSending) {
			setIsReplying((wasReplying) => {
				if (wasReplying) updateCurrentTalkbackReceiving();

				return false;
			});
		}
	}, [isTalkbackSending, updateCurrentTalkbackReceiving]);

	const showButton = isReplying || !isTalkbackSending;

	return showButton && currentTalkbackReceiving?.user && (
		<TalkbackButton
			className={className}
			isReplyButton
			onStartTalkback={handleStartTalkbackReply}
			recipientUser={{
				_id: currentTalkbackReceiving.user.userId,
				nickname: currentTalkbackReceiving.user.nickname,
			}}
		/>
	);
};
