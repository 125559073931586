import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useId } from 'react';
import { Button, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, UncontrolledTooltip } from 'reactstrap';
import { MdFiberManualRecord } from 'react-icons/md';
import { BsSquareFill } from 'react-icons/bs';
import { useTranslation } from 'react-i18next';

import { useRecording } from '@technomiam/react-video';

import './RecordButton.scss';

const recordingDurationsMinutes = [
	1,
	5,
	15,
	30,
];

export const RecordButton = ({
	className,
	hashtag,
	...props
}) => {
	const { t } = useTranslation();
	const {
		isRecording,
		startRecording,
		stopRecording,
	} = useRecording({ hashtag });
	const id = useId();

	const handleStartRecording = useCallback((duration) => {
		startRecording(duration);
	}, [startRecording]);

	const handleStopRecording = useCallback(() => {
		stopRecording();
	}, [stopRecording]);

	// eslint-disable-next-line prefer-template
	const htmlId = `recordButton${id.replaceAll(':', '__')}`;

	return isRecording ? (
		<Button
			className={clsx('RecordButton d-flex align-items-center justify-content-center isRecording', className)}
			color="neutral-secondary"
			onClick={handleStopRecording}
			tag="span"
			title={t('ChannelButtonsPanel.ChannelButtonsPanel.stopRecording')}
			{...props}
		>
			<span className="btn-wrapper--icon d-inline-block">
				<BsSquareFill color="neutral-secondary" size={13} />
			</span>
		</Button>
	) : (
		<>
			<UncontrolledTooltip placement="top" target={htmlId} trigger="hover">
				{t('LocalUserMedia.RecordButton.tooltip')}
			</UncontrolledTooltip>
			<UncontrolledDropdown>
				<DropdownToggle
					className={clsx('RecordButton d-flex align-items-center justify-content-center', className)}
					color="neutral-secondary"
					id={htmlId}
					title={t('ChannelButtonsPanel.ChannelButtonsPanel.record')}
					{...props}
				>
					<span className="btn-wrapper--icon d-inline-block">
						<MdFiberManualRecord size={20} />
					</span>
				</DropdownToggle>
				<DropdownMenu className="shadow-none">
					<strong className="dropdown-header content-darker">{t('LocalUserMedia.RecordButton.selectRecordingDuration').toUpperCase()}</strong>
					{recordingDurationsMinutes.map((duration) => (
						<DropdownItem
							key={`recording-duration-${duration}`}
							onClick={() => handleStartRecording(duration)}
						>
							{duration} {t('LocalUserMedia.RecordButton.minutes')}
						</DropdownItem>
					))}
				</DropdownMenu>
			</UncontrolledDropdown>
		</>
	);
};

RecordButton.propTypes = {
	className: PropTypes.string,
	hashtag: PropTypes.string.isRequired,
};

RecordButton.defaultProps = {
	className: '',
};

export default RecordButton;
