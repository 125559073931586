import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button } from 'reactstrap';
import { MdFiberManualRecord } from 'react-icons/md';
import { AiFillCloseSquare } from 'react-icons/ai';
import {
	useMixing,
	useIsMySourceOnLive,
} from '@technomiam/react-video';
import { useAuthentication } from '../../../Authentication/Authentication';
import { GuestHandRaising } from '../Guest/HandRaising';
import { ZoomKnob } from '../ZoomKnob';
import { LocalUserMediaPreview } from './Preview';

const ZoomKnobModalPreview = ({
	isMobilePortrait,
	onClickRaiseHand,
	isHandRaised,
	isOpen,
	toggle,
}) => {
	const [redBorderFlashing, setRedBorderFlashing] = useState(false);
	const { user } = useAuthentication();
	const { channelMixerStatus } = useMixing();
	const isMySourceOnLive = useIsMySourceOnLive();

	useEffect(() => {
		if (!isOpen || !channelMixerStatus) return undefined;

		let interval;
		if (isMySourceOnLive) {
			setRedBorderFlashing(true);
			interval = setInterval(() => {
				setRedBorderFlashing((prevState) => !prevState);
			}, 1000);
		} else {
			setRedBorderFlashing(false);
			if (interval) clearInterval(interval);
		}

		return () => {
			if (interval) clearInterval(interval);
		};
	}, [channelMixerStatus, user, isOpen, isMySourceOnLive]);

	return (
		<Modal
			centered
			contentClassName="border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto"
			isOpen={isOpen}
			zIndex={2000}
		>
			<ModalBody
				className="bg-light rounded"
				style={{
					height: '75vh',
				}}
			>
				<div className="d-flex mb-2" style={{ height: isMobilePortrait ? '10%' : 'auto' }}>
					<MdFiberManualRecord
						className="d-40 p-0 ml-O mr-auto"
						size={25}
						color={redBorderFlashing ? 'red' : 'transparent'}
					/>
					<GuestHandRaising
						className="d-40 p-0 mr-2"
						onClickRaiseHand={onClickRaiseHand}
						isHandRaised={isHandRaised}
					/>
					<Button
						className="shadow-none d-40 p-0 mr-0"
						onClick={toggle}
						color="neutral-secondary"
					>
						<span className="btn-wrapper--icon">
							<AiFillCloseSquare size={25} />
						</span>
					</Button>
				</div>
				<div
					className="position-relative d-flex bg-black overflow-hidden"
					style={{
						height: '85%',
						border: redBorderFlashing ? '3px solid red' : '3px solid transparent',
					}}
				>
					{isOpen && (
						<>
							<LocalUserMediaPreview
								isOpen
								isPortrait={isMobilePortrait}
							/>
							<ZoomKnob
								isPersonalZoom
								isMobilePortrait={isMobilePortrait}
							/>
						</>
					)}
				</div>
			</ModalBody>
		</Modal>
	);
};

ZoomKnobModalPreview.propTypes = {
	isMobilePortrait: PropTypes.bool,
	onClickRaiseHand: PropTypes.func.isRequired,
	isHandRaised: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
};

ZoomKnobModalPreview.defaultProps = {
	isMobilePortrait: false,
};

export default ZoomKnobModalPreview;
