import PropTypes from 'prop-types';
import React, { lazy, useCallback, useEffect, useState } from 'react';
import { NavItem, NavLink, TabContent } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useFetchMyHosts } from '../../../api-hooks/channel/operators';
import { ModalScreenHeaderNav } from '../../Modal/Screen/Header';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useProfile } from '../../Profile/ProfileContext';
import { useUserSettings } from '../Context';
import { LibraryModalHeader } from './Header';
import { LibraryModalMode } from './LibraryModalMode';
import { LibraryModalTab } from './LibraryModalTab';
import { ModalLazyContent } from '../../Modal/LazyContent';
import { LibraryAssetCategory } from '../../../views/Controlroom/Library/AssetCategory/helper';

const LibraryModalContent = lazy(() => import('./Content'));

export const LibraryModal = ({
	defaultTab,
	enabledTabs,
	isOpen,
	isSharedBy,
	handleSelectAsset,
	initialValue,
	initialActiveMode,
}) => {
	const { t } = useTranslation();
	const [activeTab, setActiveTab] = useState(defaultTab);
	const [activeMode, setActiveMode] = useState(initialActiveMode || LibraryModalMode.MY_LIBRARY);
	const [activeOperatorForChannel, setActiveOperatorForChannel] = useState();
	const [resourceOwnerId, setResourceOwnerId] = useState();
	const [resourceQueryMode, setResourceQueryMode] = useState(LibraryAssetCategory.MINE);
	const [fileToUpload, setFileToUpload] = useState();

	const { closeModal } = useUserSettings();

	const { profile } = useProfile();
	const { data: myHosts } = useFetchMyHosts();

	useEffect(() => {
		setActiveTab(defaultTab);
	}, [defaultTab]);

	useEffect(() => {
		if (isSharedBy) {
			setActiveMode(
				isSharedBy._id === profile._id
					? LibraryModalMode.MY_SHARES
					: LibraryModalMode.SHARED_BY_CHANNEL,
			);
			setActiveOperatorForChannel(isSharedBy);
		}
	}, [isSharedBy, profile]);

	const handleCloseModal = useCallback(() => {
		if (handleSelectAsset) handleSelectAsset(undefined);
		closeModal();
	}, [closeModal, handleSelectAsset]);

	const handleChangeActiveMode = useCallback((mode) => {
		if (mode === LibraryModalMode.BEEYOU && fileToUpload) setFileToUpload(null);
		setActiveMode(mode);
	}, [fileToUpload]);

	const handleChangeActiveTab = useCallback((tab) => {
		if (fileToUpload) setFileToUpload(null);
		setActiveTab(tab);
	}, [fileToUpload]);

	useEffect(() => {
		(async () => {
			const ownerChannelId = activeMode === LibraryModalMode.SHARED_BY_CHANNEL
				? activeOperatorForChannel._id
				: profile?._id;
			// eslint-disable-next-line no-nested-ternary
			const mode = activeMode === LibraryModalMode.MY_LIBRARY
				? LibraryAssetCategory.MINE
				: activeMode === LibraryModalMode.BEEYOU
					? LibraryAssetCategory.BEEYOU
					: LibraryAssetCategory.SHARED;

			setResourceOwnerId(ownerChannelId);
			setResourceQueryMode(mode);
		})();
	}, [activeMode, activeOperatorForChannel, profile]);

	useEffect(() => {
		if (activeMode === LibraryModalMode.BEEYOU && activeTab === LibraryModalTab.UPLOAD) {
			setActiveTab(LibraryModalTab.VIDEOS);
		}
	}, [activeMode, activeTab]);

	const isTabDisabled = (tab) => {
		if (!handleSelectAsset) {
			return false;
		}
		const allEnabledTabs = [defaultTab, ...enabledTabs];
		return !allEnabledTabs.includes(tab);
	};

	return (
		<ModalScreen
			bodyClassName="py-0"
			header={(
				<LibraryModalHeader
					activeMode={activeMode}
					setActiveMode={handleChangeActiveMode}
					avatar={profile.avatar}
					onClose={handleCloseModal}
					activeOperatorForChannel={activeOperatorForChannel}
					operatorForChannels={myHosts ?? []}
					setActiveOperatorForChannel={setActiveOperatorForChannel}
				>
					<ModalScreenHeaderNav className="mt-3">
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.videos')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.VIDEOS)}
								active={activeTab === LibraryModalTab.VIDEOS}
								disabled={isTabDisabled(LibraryModalTab.VIDEOS)}
							>
								<span>{t('LibraryModal.Modal.videos')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.images')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.IMAGES)}
								active={activeTab === LibraryModalTab.IMAGES}
								disabled={isTabDisabled(LibraryModalTab.IMAGES)}
							>
								<span>{t('LibraryModal.Modal.images')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.gfx')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.GFX)}
								active={activeTab === LibraryModalTab.GFX}
								disabled={isTabDisabled(LibraryModalTab.GFX)}
							>
								<span>{t('LibraryModal.Modal.gfx')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.playlists')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.PLAYLISTS)}
								active={activeTab === LibraryModalTab.PLAYLISTS}
								disabled={isTabDisabled(LibraryModalTab.PLAYLISTS)}
							>
								<span>{t('LibraryModal.Modal.playlists')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.scenes')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.SCENES)}
								active={activeTab === LibraryModalTab.SCENES}
								disabled={isTabDisabled(LibraryModalTab.SCENES)}
							>
								<span>{t('LibraryModal.Modal.scenes')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.simulCasts')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.SIMULCASTS)}
								active={activeTab === LibraryModalTab.SIMULCASTS}
								disabled={isTabDisabled(LibraryModalTab.SIMULCASTS)}
							>
								<span>{t('LibraryModal.Modal.simulCasts')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
						<NavItem className="mx-3">
							<NavLink
								title={t('LibraryModal.Modal.upload')}
								onClick={() => handleChangeActiveTab(LibraryModalTab.UPLOAD)}
								active={activeTab === LibraryModalTab.UPLOAD}
								disabled={isTabDisabled(LibraryModalTab.UPLOAD)
									|| activeMode === LibraryModalMode.BEEYOU}
							>
								<span>{t('LibraryModal.Modal.upload')}</span>
								<div className="divider" />
							</NavLink>
						</NavItem>
					</ModalScreenHeaderNav>
				</LibraryModalHeader>
			)}
			isOpen={isOpen}
			onClose={handleCloseModal}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<LibraryModalContent
						activeTab={activeTab}
						fileToUpload={fileToUpload}
						handleSelectAsset={handleSelectAsset}
						resourceOwnerId={resourceOwnerId}
						resourceQueryMode={resourceQueryMode}
						setActiveMode={setActiveMode}
						setActiveTab={setActiveTab}
						setFileToUpload={setFileToUpload}
						initialValue={initialValue}
					/>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

LibraryModal.propTypes = {
	defaultTab: PropTypes.string,
	enabledTabs: PropTypes.arrayOf(PropTypes.string),
	isOpen: PropTypes.bool.isRequired,
	isSharedBy: PropTypes.shape({
		_id: PropTypes.string,
	}),
	handleSelectAsset: PropTypes.func,
	initialValue: PropTypes.shape({}),
	initialActiveMode: PropTypes.string,
};

LibraryModal.defaultProps = {
	defaultTab: LibraryModalTab.VIDEOS,
	enabledTabs: [],
	isSharedBy: undefined,
	handleSelectAsset: undefined,
	initialValue: undefined,
	initialActiveMode: undefined,
};
