import { FaVideo, FaMicrophone, FaMicrophoneSlash, FaVideoSlash } from 'react-icons/fa';
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Col,
	FormGroup,
	Input,
	Label,
	Nav,
	Navbar,
	Row,
	TabContent,
	TabPane,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { components } from 'react-select';
import {
	Preview,
	useInputs,
	useMediaKey,
	useMediaUser,
} from '@technomiam/react-video';

import { InputsSelector } from '../Inputs/Selector';
import { SimpleSelector } from './SimpleSelector';
import { isIOS } from '../../../lib/userAgent';

import './Manager.scss';

const CustomOption = ({ children, data, ...props }) => {
	const {
		getIsUserAudioActive,
		getIsUserVideoActive,
	} = useMediaUser();

	return (
		<components.Option {...props}>
			<div className="d-flex align-items-center text-align-center flex-row justify-content-between">
				<span>{children}</span>
				<div>
					<span className="mr-1">
						{
							data.videoInputId && (
								getIsUserVideoActive(data.id)
									? <FaVideo color="var(--bs-primary)" />
									: <FaVideoSlash color="var(--bs-secondary)" />
							)
						}
					</span>
					{
						data.audioInputId && (
							getIsUserAudioActive(data.id)
								? <FaMicrophone color="var(--bs-primary)" />
								: <FaMicrophoneSlash color="var(--bs-secondary)" />
						)
					}
				</div>
			</div>
		</components.Option>
	);
};

CustomOption.propTypes = {
	children: PropTypes.node,
	data: PropTypes.shape({
		id: PropTypes.number,
		audioInputId: PropTypes.string,
		videoInputId: PropTypes.string,
	}),
};

CustomOption.defaultProps = {
	children: null,
	data: {},
};

const CustomValue = ({ children, data, ...props }) => {
	const {
		getIsUserAudioActive,
		getIsUserVideoActive,
	} = useMediaUser();

	return (
		<components.SingleValue {...props}>
			<div className="d-flex align-items-center text-align-center flex-row justify-content-between">
				<span>{children}</span>
				<div>
					<span className="mr-1">
						{
							data.videoInputId && (
								getIsUserVideoActive(data.id)
									? <FaVideo color="var(--bs-primary)" />
									: <FaVideoSlash color="var(--bs-secondary)" />
							)
						}
					</span>
					{
						data.audioInputId && (
							getIsUserAudioActive(data.id)
								? <FaMicrophone color="var(--bs-primary)" />
								: <FaMicrophoneSlash color="var(--bs-secondary)" />
						)
					}
				</div>
			</div>
		</components.SingleValue>
	);
};

CustomValue.propTypes = {
	children: PropTypes.node,
	data: PropTypes.shape({
		id: PropTypes.number,
		audioInputId: PropTypes.string,
		videoInputId: PropTypes.string,
	}),
};

CustomValue.defaultProps = {
	children: null,
	data: {},
};

export const MediaManager = ({
	isInStudioPreLoading,
	isAlwaysDisplayMediaChecked,
	onKeySelectBackground,
	toggleAlwaysDisplayMediaManagerScreen,
	userPreferenceKey,
}) => {
	const { t } = useTranslation();
	const {
		inputsConfig,
		changeConfigName,
		addNewConfig,
		deleteConfig,
	} = useInputs();
	const sortedInputsConfig = inputsConfig.sort((a, b) => a.id - b.id);
	const [config, setConfig] = useState(inputsConfig[0]);
	const configIdRef = useRef(config.id);

	const handleClickAdd = () => {
		const id = inputsConfig[inputsConfig.length - 1].id + 1;
		const newConfig = {
			id,
			label: `Source ${id + 1}`,
		};
		setConfig(newConfig);
		addNewConfig(newConfig);
	};

	const handleLabelChange = ({ target: { value } }) => {
		changeConfigName(config.id, value);
		setConfig((prevState) => ({ ...prevState, label: value }));
	};

	const handleClickDelete = () => {
		deleteConfig(config.id);
		setConfig(inputsConfig[0]);
	};

	const handleChange = (newConfig) => {
		setConfig(newConfig);
		configIdRef.current = newConfig.id;
	};

	useMemo(() => {
		const configId = inputsConfig.find((c) => c.id === configIdRef.current);
		if (configId) {
			setConfig(configId);
		}
	}, [inputsConfig]);

	return (
		<div className="MediaManager m-0 p-0 w-100">
			<Navbar
				className="ModalScreenHeaderNav w-100 p-0 nav-line-alt"
				light
			>
				<Nav className="nav-line w-100 justify-content-start text-nowrap text-uppercase">
					<Row xs="2" sm="4" className="g-0 mb-2 w-100">
						<SimpleSelector
							className="w-100"
							options={sortedInputsConfig}
							value={config}
							onChange={handleChange}
							isSearchable
							components={{
								Option: CustomOption,
								SingleValue: CustomValue,
							}}
						/>
						<div className="w-100 mt-2 d-flex justify-content-end">
							<Button
								className="btn-pill font-weight-bold text-inherit px-2 mr-2"
								color="outline-primary"
								onClick={handleClickAdd}
								size="sm"
								disabled={isIOS}
							>
								{t('MediaManager.MediaManager.add')}
							</Button>
							<Button
								className="btn-pill font-weight-bold text-inherit px-2"
								size="sm"
								color={config.id === 0 ? 'outline-secondary' : 'outline-danger'}
								disabled={config.id === 0}
								onClick={handleClickDelete}
							>
								{t('MediaManager.MediaManager.delete')}
							</Button>
						</div>
					</Row>
				</Nav>
				<FormGroup check>
					<Label>
						<Input
							checked={isAlwaysDisplayMediaChecked}
							name={userPreferenceKey}
							onChange={() => toggleAlwaysDisplayMediaManagerScreen()}
							type="checkbox"
						/>
						{t('MediaManager.MediaManager.alwaysShowMediaManager')}
					</Label>
				</FormGroup>
			</Navbar>
			<TabContent activeTab={config?.id}>
				{sortedInputsConfig.map((c) => (
					<TabPane key={c.id} tabId={c.id}>
						{config?.id === c.id && (
							<Col className="mt-3">
								{c.id !== 0 && (
									<div className="mb-3">
										<Label for="configName">{t('MediaManager.MediaManager.label')}</Label>
										<Input id="configName" type="text" onChange={handleLabelChange} value={c.label} />
									</div>
								)}
								<MediaManagerInputSelector
									onKeySelectBackground={onKeySelectBackground}
									onChange={(value) => setConfig(value)}
									config={c}
									isInStudioPreLoading={isInStudioPreLoading}
								/>
							</Col>
						)}
					</TabPane>
				))}
			</TabContent>
		</div>
	);
};
MediaManager.propTypes = {
	isInStudioPreLoading: PropTypes.bool,
	isAlwaysDisplayMediaChecked: PropTypes.bool.isRequired,
	onKeySelectBackground: PropTypes.func,
	toggleAlwaysDisplayMediaManagerScreen: PropTypes.func.isRequired,
	userPreferenceKey: PropTypes.string.isRequired,
};

MediaManager.defaultProps = {
	onKeySelectBackground: undefined,
	isInStudioPreLoading: false,
};

export const MediaManagerInputSelector = ({
	onKeySelectBackground,
	config,
	isInStudioPreLoading,
}) => {
	const { t } = useTranslation();
	const { getIsUserAudioActive } = useMediaUser();
	const { keyOrUserMediastream } = useMediaKey();
	const [muted, setMuted] = useState(true);
	const userAudioActive = getIsUserAudioActive(config.id);

	return (
		<section>
			<div className="position-relative overflow-hidden rounded mb-4">
				<Preview
					playsinline
					autoPlay
					className="bg-black"
					muted={muted}
					src={keyOrUserMediastream.find((s) => s.configId === config.id)}
				/>
				{userAudioActive
					? <FaMicrophone className="h2 position-absolute bottom-0 left-0 ml-3 mb-3" />
					: <FaMicrophoneSlash className="h2 position-absolute bottom-0 left-0 ml-3 mb-3" />}
				<Label className="position-absolute bottom-0 right-0 mr-3 mb-2 font-size-sm d-flex align-items-center">
					<input
						checked={!muted}
						className="mr-1"
						onChange={({ target }) => { setMuted(!target.checked); }}
						type="checkbox"
					/>
					<span className="text-body-secondary">{t('MediaManager.listen')}</span>
				</Label>
			</div>
			<div>
				<InputsSelector
					onKeySelectBackground={onKeySelectBackground}
					config={config}
					isInStudioPreLoading={isInStudioPreLoading}
				/>
			</div>
		</section>
	);
};

MediaManagerInputSelector.propTypes = {
	onKeySelectBackground: PropTypes.func,
	config: PropTypes.shape({
		id: PropTypes.number,
		videoInputOff: PropTypes.bool,
		audioInputOff: PropTypes.bool,
	}),
	isInStudioPreLoading: PropTypes.bool,
};

MediaManagerInputSelector.defaultProps = {
	onKeySelectBackground: undefined,
	config: {},
	isInStudioPreLoading: false,
};
