import React from 'react';
import PropTypes from 'prop-types';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from 'recharts';

const BAR_COLORS = ['#6CA6E5', '#6ca6e566'];

export const AnalyticsBarGraph = ({ data, label, bars, height }) => (data ? (
	<div style={{ height: height ? `${height}px` : '300px' }}>
		<ResponsiveContainer width="100%" height="100%">
			<BarChart
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
				padding={{
					left: 0,
				}}
				backgroundColor="#fff"
			>
				<CartesianGrid fill="#fff" strokeDasharray="3 3" />
				<XAxis dataKey={label} />
				<YAxis />
				<Tooltip labelStyle={{ color: 'black' }} />
				<Legend />
				{bars.map((bar, i) => (
					// eslint-disable-next-line react/no-array-index-key
					<Bar key={`bar-graph-${i}`} dataKey={bar} fill={BAR_COLORS[i % BAR_COLORS.length]} />
				))}
			</BarChart>
		</ResponsiveContainer>
	</div>
) : null);

AnalyticsBarGraph.propTypes = {
	data: PropTypes.arrayOf(PropTypes.shape({})),
	label: PropTypes.string,
	bars: PropTypes.arrayOf(PropTypes.string),
	height: PropTypes.number,
};

AnalyticsBarGraph.defaultProps = {
	data: undefined,
	label: 'label',
	bars: [],
	height: undefined,
};
