import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import ReactGA from 'react-ga';
import { FieldError, Form as SchemaForm } from 'react-jsonschema-form-validation';
import {
	Alert,
	Button,
	Container,
	FormGroup,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { FormLabel } from '../Form/Label';
import FormPassword from '../Form/Password';
import FormSubmit from '../Form/Submit';
import { getPasswordValidationMessage, schema } from './password.schema';

const initAccount = (initialState) => ({
	password: initialState.password || '',
});

export const LoginPassword = ({
	initialState,
	onBack,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [account, setAccount] = useState(initAccount(initialState || {}));

	const handleSubmitAsync = useAsyncCallback(() => {
		ReactGA.event({ category: 'signup', action: 'password:next', label: 'User clicked on Create your account button in Signup Password step' });
		return onSubmit(account);
	}); //
	const { error } = handleSubmitAsync;
	if (error) console.error(error);

	const handleClickBack = useCallback(() => {
		ReactGA.event({ category: 'signup', action: 'password:back', label: 'User clicked on back button in Signup Password step' });
		return onBack(account);
	}, [onBack, account]);

	const handleChange = useCallback((data) => {
		handleSubmitAsync.reset();
		setAccount(data);
	}, [handleSubmitAsync]);

	useEffect(() => {
		setAccount((state) => ({ ...state, ...initAccount(initialState || {}) }));
	}, [initialState]);

	return (
		<Container fluid>
			<SchemaForm
				className="h-100 d-flex flex-column mt-4 mx-0 form-light"
				data={account}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
			>
				<input onChange={() => {}} type="text" name="username" autoComplete="new-username" className="d-none" value={initialState.nickname} />
				<FormGroup className="mb-4">
					<FormLabel className="w-100">{t('Login.Password.password')}</FormLabel>
					<div className="text-secondary mb-2">
						<small>
							{t('Login.Password.passwordRequirements')}
						</small>
					</div>
					<FormPassword autoComplete="new-password" name="password" value={account.password} />
					<FieldError
						name="password"
						errorMessages={{
							pattern: (data) => getPasswordValidationMessage(data.params.pattern) || data.message,
							minLength: () => t('Login.Password.passwordLengthRequirements'),
						}}
					/>
				</FormGroup>
				<footer className="mt-auto">
					{error && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
					<div className="d-flex">
						<Button
							color="link"
							disabled={handleSubmitAsync.loading}
							onClick={handleClickBack}
						>
							{t('Login.Password.back')}
						</Button>
						<FormSubmit
							className="ml-auto"
							loading={handleSubmitAsync.loading}
						>
							{t('Login.Password.confirm')}
						</FormSubmit>
					</div>
				</footer>
			</SchemaForm>
		</Container>
	);
};

LoginPassword.propTypes = {
	initialState: PropTypes.shape({
		nickname: PropTypes.string,
	}),
	onBack: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

LoginPassword.defaultProps = {
	initialState: {},
};
