import { t as tStatic } from 'i18next';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';
import { Field, FieldError, Form as SchemaForm } from 'react-jsonschema-form-validation';
import {
	Alert,
	Button,
	Container,
	FormGroup,
	Input,
} from 'reactstrap';

import Ajv from 'ajv';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FormLabel } from '../Form/Label';
import FormPassword from '../Form/Password';
import FormSubmit from '../Form/Submit';
import { ContinueWithGoogle } from '../GoogleButton/ContinueWithGoogle';
import { PhoneInputInput } from '../Phone/PhoneInputInput';
import { IdentificationField } from '../Signup/signup.schema';
import { schema } from './stepLogin.schema';

const ajv = new Ajv({
	allErrors: true,
	v5: true,
	$data: true,
});

ajv.addKeyword('phone', {
	schema: false,
	validate: function validatePhone(data) {
		const isValid = isPossiblePhoneNumber(data);
		if (!isValid) validatePhone.errors = [{ message: tStatic('Input.Verified.phone.invalidNumber'), keyword: 'phone', params: {} }];
		return isValid;
	},
});

const initCredentials = (initialState) => ({
	identificationField: initialState.identificationField || IdentificationField.EMAIL,
	email: initialState.email || '',
	phoneNumber: initialState.phoneNumber || '',
	password: initialState.password || '',
});

const LoginStepLogin = ({
	initialState,
	onForgottenPassword,
	onSubmit,
}) => {
	const { t } = useTranslation();
	const [credentials, setCredentials] = useState(initCredentials(initialState));

	const handleSubmitAsync = useAsyncCallback(async () => onSubmit(credentials));
	const haldeGoogleSubmitAsync = useAsyncCallback(async (data) => onSubmit(data));

	const { error: loginError } = handleSubmitAsync;
	const { error: googleLoginError } = haldeGoogleSubmitAsync;
	const error = loginError || googleLoginError;

	if (error) console.error(error);
	const errCode = error?.response?.status;

	const resetRef = useRef(handleSubmitAsync.reset);
	useEffect(() => { resetRef.current = handleSubmitAsync.reset; });

	const handleChange = useCallback((data) => {
		resetRef.current();
		setCredentials((state) => ({ ...state, ...data }));
	}, []);

	const handleClickForgottenPassword = () => {
		onForgottenPassword({ email: credentials.email });
	};

	useEffect(() => {
		setCredentials((state) => ({ ...state, ...initCredentials(initialState) }));
	}, [initialState]);

	useEffect(() => {
		if (error && errCode === 403) {
			setCredentials((state) => ({ ...state, password: '' }));
		}
	}, [errCode, error]);

	const handleGoogleResponse = useCallback((codeResponse) => {
		haldeGoogleSubmitAsync.execute(codeResponse);
	}, [haldeGoogleSubmitAsync]);

	return (
		<Container fluid>
			<SchemaForm
				className="h-100 d-flex flex-column mt-4 mx-0 form-light"
				data={credentials}
				onChange={handleChange}
				onSubmit={handleSubmitAsync.execute}
				schema={schema}
				ajv={ajv}
			>
				<FormGroup>
					<FormLabel>
						{t('Login.StepLogin.loginWith')}
					</FormLabel>
					<Field
						component={Input}
						name="identificationField"
						type="select"
						value={credentials.identificationField}
					>
						{Object.keys(IdentificationField).map((key) => (
							<option
								key={`account-type-${IdentificationField[key]}`}
								value={IdentificationField[key]}
							>
								{t(`Login.StepLogin.${IdentificationField[key].toLowerCase()}`)}
							</option>
						))}
					</Field>
					<FieldError name="identificationField" />
				</FormGroup>

				{credentials.identificationField !== IdentificationField.GOOGLE
					&& (
						<>
							{credentials.identificationField === IdentificationField.EMAIL ? (
								<FormGroup>
									<FormLabel>{t('Login.StepLogin.email')}</FormLabel>
									<Field
										component={Input}
										placeholder={t('Login.StepLogin.enterEmail')}
										name="email"
										type="email"
										value={credentials.email || ''}
									/>
									<FieldError name="email" />
								</FormGroup>
							) : (
								<FormGroup>
									<FormLabel>
										{t('Login.StepLogin.phone')}
									</FormLabel>
									<Field
										component={PhoneInput}
										inputComponent={PhoneInputInput}
										defaultCountry="US"
										international
										name="phoneNumber"
										placeholder={t('Login.StepLogin.enterPhoneNumber')}
										type="text"
										value={credentials.phoneNumber || ''}
										onChange={(value) => handleChange({ phoneNumber: value })}
									/>
									<FieldError name="phoneNumber" />
								</FormGroup>
							)}

							<FormGroup>
								<FormLabel className="w-100">{t('Login.StepLogin.password')}</FormLabel>
								<FormPassword name="password" value={credentials.password} />
								<FieldError name="password" />
							</FormGroup>
						</>
					)}
				<p>
					<Button
						className="p-0"
						color="link"
						disabled={handleSubmitAsync.loading}
						onClick={handleClickForgottenPassword}
					>
						{t('Login.StepLogin.forgotPassword')}
					</Button>
				</p>
				<footer className="mt-auto">
					{(error && errCode === 403) && (
						<Alert color="danger">
							{t(
								credentials.identificationField === IdentificationField.EMAIL
									? 'Login.StepLogin.error.badEmailOrPassword'
									: 'Login.StepLogin.error.badPhoneOrPassword',
							)}
						</Alert>
					)}
					{(error && errCode !== 403) && (
						<Alert color="danger">
							{t('Global.error')}
						</Alert>
					)}
					<div className="d-xl-flex d-lg-flex d-md-block align-items-center justify-content-between">
						{credentials.identificationField !== IdentificationField.GOOGLE
							&& (
								<>
									<FormSubmit className="px-5" loading={handleSubmitAsync.loading}>
										{t('Login.StepLogin.error.signIn')}
									</FormSubmit>
									<div className="text-center">{t('Login.StepLogin.type.or')}</div>
								</>
							)}
						<ContinueWithGoogle handleSubmit={handleGoogleResponse} />
					</div>
				</footer>
			</SchemaForm>
		</Container>
	);
};

LoginStepLogin.propTypes = {
	initialState: PropTypes.shape({}),
	onForgottenPassword: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
};

LoginStepLogin.defaultProps = {
	initialState: {},
};

export default LoginStepLogin;
