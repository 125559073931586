import React from 'react';
import PropTypes from 'prop-types';
import { ModalScreen } from '../../Modal/Screen/Screen';
import { useUserSettings } from '../Context';
import { AnalyticsAndAdvertisingContent } from './Content';

import './Modal.scss';

export const AnalyticsAndAdvertisingModal = ({ isOpen, defaultTab }) => {
	const { closeAnalyticsAndAdModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="py-0"
			isOpen={isOpen}
			onClose={closeAnalyticsAndAdModal}
			className="AnalyticsAndAdvertisingModal mx-auto"
		>
			<AnalyticsAndAdvertisingContent defaultTab={defaultTab} />
		</ModalScreen>
	);
};

AnalyticsAndAdvertisingModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	defaultTab: PropTypes.string,
};

AnalyticsAndAdvertisingModal.defaultProps = {
	defaultTab: 'ANALYTICS',
};
