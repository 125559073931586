/* eslint-disable react/prop-types */
// @ts-check

import React from 'react';
import clsx from 'clsx';

/**
 * @typedef {{
 * 	children?: React.ReactNode,
 * 	className?: string,
 * }} RatioContainerProps
 */

const RatioContainer = (
	/** @type {RatioContainerProps} */
	{ children, className },
) => (
	<div className={clsx('ratio ratio-16x9 overflow-hidden zIndex-0', className)}>
		{children}
	</div>
);

export default RatioContainer;
