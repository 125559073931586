// @ts-check

import { FaFacebookSquare, FaLinkedin, FaTwitch, FaVimeo, FaYoutube } from 'react-icons/fa';

export const MuxSimulCastStatus = {
	IDLE: 'idle',
	STARTING: 'starting',
	BROADCASTING: 'broadcasting',
	ERRORED: 'errored',
};

export const MuxSimulCastStatusColor = {
	[MuxSimulCastStatus.IDLE]: 'secondary',
	[MuxSimulCastStatus.STARTING]: 'info',
	[MuxSimulCastStatus.BROADCASTING]: 'success',
	[MuxSimulCastStatus.ERRORED]: 'danger',
};

/**
 * @readonly
 * @enum {string}
 */
export const SimulCastPlatform = {
	FACEBOOK: 'facebook',
	TWITCH: 'twitch',
	VIMEO: 'vimeo',
	YOUTUBE: 'youtube',
	LINKEDIN: 'linkedin',
};

export const SimulCastPlatformData = {
	[SimulCastPlatform.FACEBOOK]: {
		_id: 'facebook-new',
		name: 'Facebook',
		icon: FaFacebookSquare,
		iconColor: '#3b5998',
		url: 'rtmps://live-api-s.facebook.com:443/rtmp/',
		streamKey: '',
	},
	[SimulCastPlatform.TWITCH]: {
		_id: 'twitch-new',
		name: 'Twitch',
		icon: FaTwitch,
		iconColor: '#6441a5',
		url: 'rtmp://live.twitch.tv/app/',
		streamKey: '',
	},
	[SimulCastPlatform.VIMEO]: {
		_id: 'vimeo-new',
		name: 'Vimeo',
		icon: FaVimeo,
		iconColor: '#1ab7ea',
		url: 'rtmps://rtmp-global.cloud.vimeo.com:443/live/',
		streamKey: '',
	},
	[SimulCastPlatform.YOUTUBE]: {
		_id: 'youtube-new',
		name: 'YouTube',
		icon: FaYoutube,
		iconColor: '#ff0000',
		url: 'rtmp://a.rtmp.youtube.com/live2/',
		streamKey: '',
	},
	[SimulCastPlatform.LINKEDIN]: {
		_id: 'linkedin-new',
		name: 'LinkedIn (Beta)',
		icon: FaLinkedin,
		iconColor: '#0077b5',
		url: '',
		streamKey: '',
	},
};

/**
 * @param {string?} [urlToTest]
 * @returns {string} platform key
 */
export const getPlatformKeyFromUrl = (urlToTest) => (Object.entries(SimulCastPlatformData)
	.find(([, { url }]) => url === urlToTest) || [])[0]
	|| SimulCastPlatform.LINKEDIN;
