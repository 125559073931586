import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { FaCheck } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useMixing } from '@technomiam/react-video';

export const ChannelMode = {
	LISTEN_EVERYBODY: 'LISTEN_EVERYBODY',
	LISTEN_LIVE_ONLY: 'LISTEN_LIVE_ONLY',
	MUTE_ALL_INCLUDING_HOST: 'MUTE_ALL_INCLUDING_HOST',
};

export const ChannelModeExtended = {
	LISTEN_EVERYBODY: 'LISTEN_EVERYBODY',
	LISTEN_LIVE_ONLY: 'LISTEN_LIVE_ONLY',
	LISTEN_LIVE_ONLY_AND_MUTE: 'LISTEN_LIVE_ONLY_AND_MUTE',
	MUTE_ALL_INCLUDING_HOST: 'MUTE_ALL_INCLUDING_HOST',
};

export const ChannelModeByChannelModeExtended = {
	LISTEN_EVERYBODY: ChannelMode.LISTEN_EVERYBODY,
	LISTEN_LIVE_ONLY: ChannelMode.LISTEN_LIVE_ONLY,
	LISTEN_LIVE_ONLY_AND_MUTE: ChannelMode.LISTEN_LIVE_ONLY,
	MUTE_ALL_INCLUDING_HOST: ChannelMode.MUTE_ALL_INCLUDING_HOST,
};

const DEFAULT_CHANNEL_MODE_EXTENDED = ChannelModeExtended.LISTEN_EVERYBODY;

const ChannelModeOption = ({
	checked,
	label,
	onChange,
	value,
}) => (
	<div role="menuitem">
		<span
			className={clsx('dropdown-item', { checked })}
			onClick={() => {
				onChange({ target: { value } });
			}}
		>
			{checked && (
				<FaCheck className="mr-1 text-primary" size={12} style={{ marginLeft: '-1rem' }} />
			)}
			{label}
		</span>
	</div>
);

ChannelModeOption.propTypes = {
	checked: PropTypes.bool,
	label: PropTypes.node.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.oneOf(Object.values(ChannelModeExtended)).isRequired,
};

ChannelModeOption.defaultProps = {
	checked: false,
};

export const ChannelModeSelect = ({
	setAllMuted,
	setHostMuted,
}) => {
	const { t } = useTranslation();
	const {
		channelMixerStatus,
		notifyModeChange,
	} = useMixing();

	const channelCurrentMode = channelMixerStatus?.mode;

	const [mode, setMode] = useState(
		(channelCurrentMode && ChannelModeExtended[channelCurrentMode])
		|| DEFAULT_CHANNEL_MODE_EXTENDED,
	);

	useEffect(() => {
		if (!channelCurrentMode) return;
		setMode((s) => {
			/* Update mode only if currentChannelMode is incompatible with (extended) mode.
			For example we can have:
				channelCurrentMode === LISTEN_LIVE_ONLY and mode === LISTEN_LIVE_ONLY_AND_MUTE
			but we cannot have:
				 channelCurrentMode === LISTEN_LIVE_ONLY and mode === LISTEN_EVERYBODY */
			if (!s || ChannelModeByChannelModeExtended[s] !== channelCurrentMode) {
				return ChannelModeExtended[channelCurrentMode];
			}
			return s;
		});
	}, [channelCurrentMode]);

	useEffect(() => {
		setAllMuted(mode === ChannelModeExtended.LISTEN_LIVE_ONLY_AND_MUTE
			|| mode === ChannelModeExtended.MUTE_ALL_INCLUDING_HOST);
		setHostMuted(mode === ChannelModeExtended.MUTE_ALL_INCLUDING_HOST);
	}, [mode, setAllMuted, setHostMuted]);

	const handleChange = (event) => {
		const { value } = event.target;
		setMode(value);
		notifyModeChange(ChannelModeByChannelModeExtended[value]);
	};

	return (
		<>
			<ChannelModeOption
				checked={mode === ChannelModeExtended.LISTEN_EVERYBODY}
				label={t('ChannelMode.ChannelModeSelect.allHearAll')}
				onChange={handleChange}
				value={ChannelModeExtended.LISTEN_EVERYBODY}
			/>
			<ChannelModeOption
				checked={mode === ChannelModeExtended.LISTEN_LIVE_ONLY}
				label={t('ChannelMode.ChannelModeSelect.allHearLiveHostAll')}
				onChange={handleChange}
				value={ChannelModeExtended.LISTEN_LIVE_ONLY}
			/>
			<ChannelModeOption
				checked={mode === ChannelModeExtended.LISTEN_LIVE_ONLY_AND_MUTE}
				label={t('ChannelMode.ChannelModeSelect.liveOnly')}
				onChange={handleChange}
				value={ChannelModeExtended.LISTEN_LIVE_ONLY_AND_MUTE}
			/>
			<ChannelModeOption
				checked={mode === ChannelModeExtended.MUTE_ALL_INCLUDING_HOST}
				label="Mute all"
				onChange={handleChange}
				value={ChannelModeExtended.MUTE_ALL_INCLUDING_HOST}
			/>
		</>
	);
};

ChannelModeSelect.propTypes = {
	setAllMuted: PropTypes.func.isRequired,
	setHostMuted: PropTypes.func.isRequired,
};
