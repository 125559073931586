import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'reactstrap';

import { useTranslation } from 'react-i18next';
import { useAsync } from 'react-async-hook';
import { FaArchive, FaPaperPlane, FaPencilAlt, FaRegChartBar, FaRegCopy, FaRegTrashAlt, FaTrashRestore, FaUsers } from 'react-icons/fa';
import { ButtonPill } from '../../../../../components/Button';
import { useSurveyTemplate } from '../../../../../components/Survey/Providers/SurveyTemplateProvider';
import { useAsyncErrorLog } from '../../../../../lib/hooks';
import { usePagination } from '../../../../../components/Pagination/Context';
import { useListSorting } from '../../../../../components/ListSorting/Provider';
import { ShareModalButton } from '../../../../../components/Share/Modal/Button';
import { ShareAboutType, ShareTabType } from '../../../../../components/Share/Share.helper';
import { useProfile } from '../../../../../components/Profile/ProfileContext';

const SettingsLibrarySurveysTab = ({ isArchived }) => {
	const { t } = useTranslation();
	const { profile } = useProfile();
	const {
		fetchSurveyTemplates,
		handleDeleteSurveyTemplate,
		handleOpenSurveyTemplateModal,
		handleRestoreSurveyTemplate,
		studioId,
		surveyTemplates,
	} = useSurveyTemplate();

	const {
		currentPage,
		itemsPerPage,
		updateItemsCount,
		goPrevPage,
	} = usePagination();

	const {
		currentSort,
	} = useListSorting();

	const surveyTemplateReq = useAsync(
		() => fetchSurveyTemplates(isArchived, itemsPerPage, currentPage, currentSort),
		[
			fetchSurveyTemplates,
			isArchived,
			itemsPerPage,
			currentPage,
			currentSort,
			surveyTemplates?.totalItemsCount,
		],
	);
	useAsyncErrorLog(surveyTemplateReq);

	useEffect(() => {
		updateItemsCount(surveyTemplates?.totalItemsCount || 0);
	}, [updateItemsCount, surveyTemplates?.totalItemsCount]);

	useEffect(() => {
		if (surveyTemplates?.data?.length === 0) goPrevPage();
	}, [goPrevPage, surveyTemplates?.data?.length]);

	const handleOpenSurvey = useCallback((
		surveyTemplateId,
		copy,
		focusTab,
	) => {
		handleOpenSurveyTemplateModal(
			surveyTemplateId,
			copy,
			focusTab,
		);
	}, [handleOpenSurveyTemplateModal]);

	return (!surveyTemplates?.data?.length ? (
		<div className="flex-grow-1 d-flex justify-content-center align-items-center">{t('Surveys.Tab.noSurveys')}</div>
	) : (
		surveyTemplates.data.map((surveyTemplate) => (
			<div key={`survey-templates-list-${surveyTemplate._id}`} className="SettingsLibrarySurveysTab_row">
				<Row className="d-flex align-items-center justify-content-between p-3 w-100 m-0">
					<Col xs="6" className="p-0">
						<EditableTitle
							id={surveyTemplate._id}
							title={surveyTemplate.title}
							completed={surveyTemplate.completed}
							openSurveyTemplateModal={handleOpenSurvey}
						/>
					</Col>
					<Col xs="2" className="d-flex justify-content-center p-0">
						{surveyTemplate.completed && !surveyTemplate.deleted && (
							<span
								className="btn-wrapper--icon mr-3 text-secondary cursor-pointer"
								onClick={() => {
									handleOpenSurvey(surveyTemplate._id, false, 'PEOPLE');
								}}
								title={t('Surveys.Tab.respondents')}
							>
								<FaUsers /> {surveyTemplate.participantsCount}
							</span>
						)}
					</Col>
					<Col xs="2" className="d-flex justify-content-center p-0">
						{surveyTemplate.completed && !surveyTemplate.deleted && (
							<span
								className={
									`btn-wrapper--icon mx-3 ${surveyTemplate.participantsCount ? 'text-secondary cursor-pointer' : 'text-gray-dark'}`
								}
								onClick={() => (surveyTemplate.participantsCount ? handleOpenSurvey(surveyTemplate._id, false, 'REPORT') : undefined)}
								title={t('Surveys.Tab.results')}
							>
								<FaRegChartBar />
							</span>
						)}
						{!surveyTemplate.deleted && (
							<span
								className="btn-wrapper--icon cursor-pointer text-secondary mx-3"
								onClick={() => handleOpenSurvey(surveyTemplate._id, true)}
								title={t('Surveys.Tab.copy')}
							>
								<FaRegCopy />
							</span>
						)}
						<span
							className="btn-wrapper--icon cursor-pointer text-secondary mx-3"
							onClick={() => handleDeleteSurveyTemplate(surveyTemplate._id)}
							title={t(!surveyTemplate.deleted && surveyTemplate.completed ? 'Surveys.Tab.archive' : 'Surveys.Tab.delete')}
						>
							{!surveyTemplate.deleted && surveyTemplate.completed ? (
								<FaArchive />
							) : (
								<FaRegTrashAlt />
							)}
						</span>
						{surveyTemplate.deleted }
					</Col>
					<Col xs="2" className="d-flex justify-content-end p-0">
						{surveyTemplate.completed && !surveyTemplate.deleted && (
							<ShareModalButton
								color="neutral-secondary"
								content={surveyTemplate}
								contentType={ShareAboutType.SURVEY}
								defaultShareMessage={t('Invite.InviteTab.inviteMessage', profile)}
								size="sm"
								studio={{ _id: studioId }}
								tabs={[
									studioId && ShareTabType.STUDIO_PARTICIPANTS,
									ShareTabType.USERS_FOLLOWERS_FRIENDS,
									ShareTabType.EMAIL,
									ShareTabType.SMS,
									ShareTabType.GROUPS,
								].filter(Boolean)}
							>
								<FaPaperPlane className="mr-1" />
								{t('Surveys.Tab.send')}
							</ShareModalButton>
						)}
						{surveyTemplate.deleted && (
							<ButtonPill
								size="sm"
								color="neutral-secondary"
								onClick={() => handleRestoreSurveyTemplate(surveyTemplate._id)}
							>
								<FaTrashRestore className="mr-1" />
								{t('Surveys.Tab.restore')}
							</ButtonPill>
						)}
					</Col>
				</Row>
				<div className="divider w-100 bg-light" />
			</div>
		))
	)
	);
};

SettingsLibrarySurveysTab.propTypes = {
	isArchived: PropTypes.bool,
};

SettingsLibrarySurveysTab.defaultProps = {
	isArchived: undefined,
};

export const EditableTitle = ({ id, title, completed, openSurveyTemplateModal }) => {
	const { t } = useTranslation();
	const [onHover, setOnHover] = useState(false);

	return (
		<div
			className="d-flex align-items-center"
		>
			<Button
				className={`font-weight-bold shadow-none btn-transition-none ${(completed && onHover) ? 'text-info' : 'content-dark'}`}
				color="link"
				onMouseOut={() => setOnHover(false)}
				onMouseOver={() => setOnHover(true)}
				onClick={() => openSurveyTemplateModal(id)}
			>
				{title}
				{!completed && <span className="text-secondary ml-1"><i>({t('Surveys.Tab.draft')})</i></span> }
			</Button>
			{(!completed && onHover) && (
				<span className="btn-wrapper--icon">
					<FaPencilAlt />
				</span>
			)}
		</div>
	);
};

EditableTitle.propTypes = {
	id: PropTypes.string.isRequired,
	openSurveyTemplateModal: PropTypes.func.isRequired,
	completed: PropTypes.bool,
	title: PropTypes.string,
};
EditableTitle.defaultProps = {
	completed: false,
	title: 'New Survey',
};

export default SettingsLibrarySurveysTab;
