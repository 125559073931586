import React from 'react';
import PropTypes from 'prop-types';

import { ChatProvider } from '../Chat/Provider';
import { ChannelConnectionProvider } from './ChannelConnectionProvider';
import { ChannelSocketConnector } from './Connector';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { VoteProvider } from '../../Vote/Provider';
import { AuctionProvider } from '../../Auction/Provider';

const StudioOnlyProviders = ({ children, studioId, role }) => {
	if (!studioId) return children;
	return (
		<ChatProvider studioId={studioId}>
			<VoteProvider>
				<AuctionProvider studioRole={role}>
					{children}
				</AuctionProvider>
			</VoteProvider>
		</ChatProvider>
	);
};

StudioOnlyProviders.propTypes = {
	children: PropTypes.node.isRequired,
	studioId: PropTypes.string,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};

StudioOnlyProviders.defaultProps = {
	studioId: undefined,
};

export const ChannelSocketWrapper = ({
	channelId,
	children,
	role,
	studioId,
	token,
	organizationId,
	onWsError,
}) => (
	<ChannelConnectionProvider>
		<StudioOnlyProviders studioId={studioId} role={role}>
			<ChannelSocketConnector
				channelId={channelId}
				role={role}
				studioId={studioId}
				token={token}
				organizationId={organizationId}
				onWsError={onWsError}
			/>
			{children}
		</StudioOnlyProviders>
	</ChannelConnectionProvider>
);

ChannelSocketWrapper.propTypes = {
	channelId: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	role: PropTypes.oneOf(Object.values(ResourceAccessRole)),
	studioId: PropTypes.string,
	token: PropTypes.string,
	onWsError: PropTypes.func,
	organizationId: PropTypes.string,
};

ChannelSocketWrapper.defaultProps = {
	role: ResourceAccessRole.PUBLIC,
	studioId: undefined,
	token: undefined,
	onWsError: undefined,
	organizationId: undefined,
};
