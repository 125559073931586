import React, {
	createContext,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import PropTypes from 'prop-types';

const SidebarContext = createContext();

export const useSidebar = () => useContext(SidebarContext);

export const SidebarProvider = ({ children }) => {
	const [toggleMobile, setToggleMobile] = useState(false);
	const [openSidebarMenu, setOpenSidebarMenu] = useState(false);

	const toggleSidebarMenu = useCallback(() => {
		setOpenSidebarMenu((prevToggleMenu) => !prevToggleMenu);
	}, []);

	const toggleSidebarMobile = useCallback(() => {
		setToggleMobile((s) => !s);
	}, [setToggleMobile]);

	const hideSidebarMenu = useCallback(() => {
		setToggleMobile(false);
	}, [setToggleMobile]);

	const contextValue = useMemo(() => ({
		setToggleMobile,
		toggleMobile,
		toggleSidebarMobile,
		hideSidebarMenu,
		toggleSidebarMenu,
		openSidebarMenu,
	}), [
		toggleMobile,
		toggleSidebarMobile,
		hideSidebarMenu,
		toggleSidebarMenu,
		openSidebarMenu,
	]);

	return (
		<SidebarContext.Provider value={contextValue}>
			{children}
		</SidebarContext.Provider>
	);
};

SidebarProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
