import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useFetchAdAgeAnalytics } from '../../../../../../api-hooks/analytics/ads';
import { AnalyticsBarGraph } from '../../../Analytics/BarGraph';

export const AdvertisingDashboardDemographicsAge = ({ adCampaignId }) => {
	const { data: adAgeAnalytics, isError, isLoading } = useFetchAdAgeAnalytics(adCampaignId);
	const { t } = useTranslation();

	if (isError) {
		return <Alert color="danger">{t('Global.error')}</Alert>;
	}

	if (isLoading) {
		return <Spinner />;
	}

	return adAgeAnalytics ? (
		<div>
			<div className="GrahpHeading m-0 p-3">
				{t('AdCampaings.Ad.audienceAge')}
			</div>
			<AnalyticsBarGraph
				data={adAgeAnalytics}
				label="label"
				bars={['viewers']}
			/>
		</div>
	) : <Spinner />;
};

AdvertisingDashboardDemographicsAge.propTypes = {
	adCampaignId: PropTypes.string,
};

AdvertisingDashboardDemographicsAge.defaultProps = {
	adCampaignId: undefined,
};
