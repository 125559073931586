import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCurrentStudio } from '../Studio/useCurrentStudio';
import { useQueryParams } from '../../lib/hooks';
import { useMarkNotificationRead } from '../../api-hooks/notification/notification';

const NotificationContext = createContext({});

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);
	const [lastChargeNotification, setLastChargeNotification] = useState(null);
	const { handleCurrentStudioCharge } = useCurrentStudio();
	const { mutate: markNotificationRead } = useMarkNotificationRead();
	const query = useQueryParams();
	const notificationId = query.get('notificationId');

	useEffect(() => {
		if (notificationId) {
			markNotificationRead(notificationId);
		}
	}, [notificationId, markNotificationRead]);

	const handleEventNotificationStudioCharge = useCallback((notification) => {
		setLastChargeNotification(notification);
	}, []);

	const handleEventNotification = useCallback((notification) => {
		setNotifications((n) => [...n, notification]);
	}, []);

	const removeNotification = useCallback((notification) => {
		setNotifications((n) => n.filter((_n) => _n._id !== notification._id));
	}, []);

	useEffect(() => {
		if (lastChargeNotification) {
			handleCurrentStudioCharge(lastChargeNotification);
		}
	}, [handleCurrentStudioCharge, lastChargeNotification]);

	const value = useMemo(() => ({
		notifications,
		handleEventNotification,
		removeNotification,
		handleEventNotificationStudioCharge,
	}), [
		notifications,
		handleEventNotification,
		removeNotification,
		handleEventNotificationStudioCharge,
	]);

	return (
		<NotificationContext.Provider value={value}>
			{children}
		</NotificationContext.Provider>
	);
};

NotificationProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
