import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from 'reactstrap';
import clsx from 'clsx';

import './Screen.scss';

export const ModalScreen = ({
	bodyClassName,
	children,
	className,
	contentClassName,
	isOpen,
	onClose,
	header,
	size,
	backdrop,
}) => (
	<>
		<Modal
			centered
			className={className}
			contentClassName={clsx(
				'border-0 shadow-lg rounded overflow-hidden align-self-start bg-dark content-dark',
				contentClassName,
			)}
			isOpen={isOpen}
			size={size}
			zIndex={2000}
			toggle={onClose}
			backdrop={backdrop}
		>
			{header}
			<ModalBody className={bodyClassName}>
				{children}
			</ModalBody>
		</Modal>
		{!backdrop && (
			<div style={{ zIndex: 1900 }} className="backdrop-disabled modal-backdrop fade show" />
		)}
	</>
);

ModalScreen.propTypes = {
	bodyClassName: PropTypes.string,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	contentClassName: PropTypes.string,
	header: PropTypes.node,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	size: PropTypes.string,
	backdrop: PropTypes.bool,
};

ModalScreen.defaultProps = {
	bodyClassName: '',
	className: '',
	contentClassName: '',
	header: undefined,
	size: 'xl',
	backdrop: true,
};
