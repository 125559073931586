import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'react-jsonschema-form-validation';
import {
	Input,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import countries from 'i18n-iso-countries';
import countriesEn from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(countriesEn);
export const countryList = Object.entries(countries.getNames('en'));

const FormCountry = ({
	code,
	name,
	onChange,
}) => {
	const { t } = useTranslation();
	return (
		<Field
			component={Input}
			name={name}
			type="select"
			value={code}
			onChange={onChange}
		>
			<option value="">{t('Form.Country.choose')}</option>
			{
				countryList.map(([countryCode, countryName]) => (
					<option
						key={countryCode}
						value={countryCode}
					>
						{countryName}
					</option>
				))
			}
		</Field>
	);
};

FormCountry.propTypes = {
	code: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
};

FormCountry.defaultProps = {
	code: '',
	name: 'country',
	onChange: undefined,
};

export default React.memo(FormCountry);
