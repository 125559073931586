import PropTypes from 'prop-types';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useUpdateChannelPreferences } from '../../../../api-hooks/channel/preference';

const INCREMENT_PRECENATE_FONT_SIZE = 1;
const FONT_SIZE_KEY = 'fontSize';

export const adjustFontSizes = (adjustmentPercentage) => {
	const allElements = document.querySelectorAll('*');
	const incrementPercentage = adjustmentPercentage / 100;

	allElements.forEach((element) => {
		const computedStyle = getComputedStyle(element);
		const currentSize = parseFloat(computedStyle.fontSize);

		// eslint-disable-next-line no-restricted-globals
		if (!isNaN(currentSize)) {
			const currentSizeValue = parseFloat(computedStyle.fontSize);

			const adjustmentInPixels = currentSizeValue * incrementPercentage;

			const newSizeInPixels = currentSizeValue + adjustmentInPixels;
			element.style.fontSize = `${newSizeInPixels}px`;
		}
	});
};

const DisplayContext = createContext({});

export const useDisplaySettings = () => useContext(DisplayContext);

export const DisplayProvider = ({ children }) => {
	const [currentFontSize, setCurrentFontSize] = useState(0);
	const { mutate: updateChannelPreferences } = useUpdateChannelPreferences();

	const handleFontSize = useCallback((value) => {
		setCurrentFontSize(value);
		localStorage.setItem(FONT_SIZE_KEY, value);
		adjustFontSizes(INCREMENT_PRECENATE_FONT_SIZE * value);
	}, []);

	const incrementFontSize = useCallback(() => {
		if (currentFontSize < 20) {
			setCurrentFontSize((prevSize) => {
				updateChannelPreferences({ fontSize: prevSize + 1 });
				localStorage.setItem(FONT_SIZE_KEY, INCREMENT_PRECENATE_FONT_SIZE * (prevSize + 1));
				return prevSize + 1;
			});
			adjustFontSizes(INCREMENT_PRECENATE_FONT_SIZE);
		}
	}, [currentFontSize, updateChannelPreferences]);

	const decrementFontSize = useCallback(() => {
		if (currentFontSize > -10) {
			setCurrentFontSize((prevSize) => {
				// eslint-disable-next-line radix
				localStorage.setItem(FONT_SIZE_KEY, INCREMENT_PRECENATE_FONT_SIZE * (prevSize - 1));
				updateChannelPreferences({ fontSize: prevSize - 1 });
				return prevSize - 1;
			});
			adjustFontSizes(-INCREMENT_PRECENATE_FONT_SIZE);
		}
	}, [currentFontSize, updateChannelPreferences]);

	useEffect(() => {
		const fontSize = localStorage.getItem(FONT_SIZE_KEY);
		if (fontSize) {
			// eslint-disable-next-line radix
			handleFontSize(parseInt(fontSize));
		}
	}, [handleFontSize]);

	const value = useMemo(() => ({
		currentFontSize,
		incrementFontSize,
		decrementFontSize,
		handleFontSize,
	}), [currentFontSize, incrementFontSize, decrementFontSize, handleFontSize]);

	return (
		<DisplayContext.Provider value={value}>
			{children}
		</DisplayContext.Provider>
	);
};

DisplayProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
