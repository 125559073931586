import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const InputVerifiedBadge = () => {
	const { t } = useTranslation();

	return (
		<div>
			<FaCheckCircle className="text-success mr-1" />
			{t('Input.VerifiedBadge.verified')}
		</div>
	);
};
