import { api } from '../api';

const API_PROFILE_PASSWORD_PATH = '/profile/password';

export const resetPasswordBegin = async (account, userLanguage) => api.post(
	`${API_PROFILE_PASSWORD_PATH}/reset/begin`,
	{ ...account, userLanguage },
);

export const resetPasswordConfirm = async (account, verificationToken) => {
	await api.post(
		`${API_PROFILE_PASSWORD_PATH}/reset/confirm`,
		{
			...account,
			verification_token: verificationToken,
		},
	);
};

export const changePassword = async ({ currentPassword, newPassword }) => api.post(
	`${API_PROFILE_PASSWORD_PATH}/change`,
	{ currentPassword, newPassword },
);
