import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaInfoCircle } from 'react-icons/fa';
import { Link, useHistory } from 'react-router-dom';
import { Path, getLink } from '../../RoutePath';
import { useAuthentication } from '../../components/Authentication/Authentication';
import ReallyPerfectScrollbar from '../../components/ReallyPerfectScrollbar/ReallyPerfectScrollbar';
import { SidebarMenuFollowing } from './Following/Following';
import { MenuCollapse } from './MenuCollapse/MenuCollapse';
import { useSidebarItems } from '../Sidebar/useSidebarItems';
import { LanguageSelector } from '../../components/Language/LanguageSelector';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const SidebarMenu = ({ toggleSidebarMobile, isStudioRoute, hideSidebarMenu }) => {
	const { t } = useTranslation();
	const { user } = useAuthentication();

	const history = useHistory();

	const {
		generalSidebarItems,
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		studioSidebarItems,
		studioSidebarParentItem,
	} = useSidebarItems();

	const [activeLink, setActiveLink] = useState('');

	const handleLinkClick = (link, url) => {
		if (isStudioRoute && url) {
			window.open(url, '_blank', 'location=yes,height=700,width=1080px');
		} else {
			setActiveLink(link);
		}
	};

	const isCurrentLocationHome = /\/|\/home\/|\/help\//.test(history.location.pathname);

	const newUserMenuItems = [
		{ label: t('NewUser.Banner.Blue.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'about' }) },
		{ label: t('NewUser.Banner.Green.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'monetization' }) },
		{ label: t('NewUser.Banner.Teal.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'philosophy' }) },
		{ label: t('NewUser.Banner.Yellow.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'users' }) },
	];

	const newUserMenu = {
		label: t('NewUser.About'),
		icon: <FaInfoCircle className="icon" />,
	};

	return (
		<ReallyPerfectScrollbar>
			<div className="sidebar-navigation">
				<ul className="pt-3">
					{!!user && (
						<MenuCollapse
							parentItem={studioSidebarParentItem}
							childItems={studioSidebarItems}
							hideSidebarMenu={hideSidebarMenu}
						/>
					)}
					{!!user && generalSidebarItems.map((menuItem) => menuItem && (
						<li key={menuItem.label}>
							<Link
								to={menuItem.to}
								className={clsx('sidebar-navigation-item mx-2', { active: activeLink === menuItem.label })}
								onClick={() => {
									if (menuItem.action) {
										menuItem.action();
									}
									if (menuItem.toggleSidebarOnClick) {
										toggleSidebarMobile();
									}
									if (menuItem.hideSidebarOnClick) {
										hideSidebarMenu();
									}
									handleLinkClick(menuItem.label);
								}}
							>
								{menuItem.badge}
								<i className="sidebar-icon">
									{menuItem.icon && (
										menuItem.icon
									)}
								</i>
								{menuItem.label}
							</Link>
						</li>
					))}
				</ul>
				{!!user && <div className="divider bg-secondary opacity-25 mb-3" />}
				<ul>
					{secondarySidebarItems.map((menuItem) => menuItem && (
						<li key={menuItem.label}>
							<Link
								to={menuItem.to}
								className={clsx('sidebar-navigation-item mx-2', { active: activeLink === menuItem.label })}
								onClick={() => {
									if (menuItem.action) {
										menuItem.action();
									}
									if (menuItem.toggleSidebarOnClick) {
										toggleSidebarMobile();
									}
									handleLinkClick(menuItem.label);
								}}
							>
								{menuItem.badge}
								<i className="sidebar-icon">
									{menuItem.icon && (
										menuItem.icon
									)}
								</i>
								{menuItem.label}
							</Link>
						</li>
					))}
				</ul>
				{!!user && (
					<>
						<div className="divider bg-secondary opacity-25" />
						<div className="pt-2 pb-1">
							<span className="font-size-xs mb-0 ml-2 text-uppercase text-secondary ml-4">{t('SidebarMenu.Index.channelsYouFollow')}</span>
						</div>
						<ul>
							<SidebarMenuFollowing
								activeLink={activeLink}
								isCurrentLocationHome={isCurrentLocationHome}
							/>
						</ul>
					</>
				)}
				{isBeeyou && (
					<>
						<div className="divider bg-secondary opacity-25" />
						<ul className="pt-3">
							<MenuCollapse parentItem={newUserMenu} childItems={newUserMenuItems} />
						</ul>
						<ul>
							<MenuCollapse parentItem={helpCenterParentItem} childItems={helpCenterItems} />
						</ul>
					</>
				)}
				<div className="d-md-none ml-1">
					<LanguageSelector />
				</div>
			</div>
		</ReallyPerfectScrollbar>
	);
};
SidebarMenu.propTypes = {
	toggleSidebarMobile: PropTypes.func,
	isStudioRoute: PropTypes.bool,
	hideSidebarMenu: PropTypes.func,
};
SidebarMenu.defaultProps = {
	toggleSidebarMobile: undefined,
	isStudioRoute: false,
	hideSidebarMenu: undefined,
};
export default SidebarMenu;
