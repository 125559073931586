/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback, useEffect } from 'react';
import { Button } from 'reactstrap';
import { MdRecordVoiceOver } from 'react-icons/md';
import clsx from 'clsx';
import { useTalkbackSender } from '@technomiam/react-video';
import { useTranslation } from 'react-i18next';

import { MediaErrorPopover } from '../LocalUserMedia/MediaErrorPopover';

import './Button.scss';

/**
 * @typedef {{
 *  className?: string,
 * 	iconColor?: string,
 * 	isReplyButton?: boolean,
 * 	onStartTalkback?: () => void,
 * 	onStopTalkback?: () => void,
 * 	recipientUser: {
 * 		_id: string,
 * 		nickname: string,
 * 	}
 * }} TalkbackButtonProps
 */

export const TalkbackButton = (
	/** @type {TalkbackButtonProps} */
	{
		className,
		iconColor = 'black',
		isReplyButton = false,
		onStartTalkback,
		onStopTalkback,
		recipientUser,
	},
) => {
	const { t } = useTranslation();

	const {
		recipientUser: activeRecipientUser,
		startTalkback,
		stopTalkback,
		userAudioRequestError,
	} = useTalkbackSender();

	const isTalkbackActive = activeRecipientUser?._id === recipientUser._id;

	const handleStartTalkback = useCallback(() => {
		if (userAudioRequestError) return;

		if (!isTalkbackActive) {
			startTalkback(recipientUser);
			if (onStartTalkback) onStartTalkback();
		}
	}, [isTalkbackActive, recipientUser, startTalkback, userAudioRequestError, onStartTalkback]);

	useEffect(() => {
		if (isTalkbackActive) {
			const handleStopTalkback = () => {
				stopTalkback();
				if (onStopTalkback) onStopTalkback();
			};

			document.addEventListener('mouseup', handleStopTalkback);

			return () => {
				document.removeEventListener('mouseup', handleStopTalkback);
			};
		}
		return undefined;
	}, [isTalkbackActive, stopTalkback, onStopTalkback]);

	const buttonColor = userAudioRequestError ? 'neutral-danger' : 'neutral-primary';
	const animatedIconColor = userAudioRequestError ? 'danger' : 'primary';

	return (
		<>
			<Button
				className={clsx(
					'TalkbackButton p-0 border-0 shadow-none btn-animated-icon',
					`btn-animated-icon--${animatedIconColor}`,
					{ isReplyButton: isReplyButton && !isTalkbackActive },
					{ isActive: isTalkbackActive },
					className,
				)}
				color={buttonColor}
				id={`TalkbackButton-${recipientUser._id}`}
				onMouseDown={handleStartTalkback}
				tag="span"
				title={t(
					`Talkback.Button.${isReplyButton ? 'pushToReply' : 'pushToTalk'}`,
					{ nickname: recipientUser.nickname },
				)}
			>
				<span className="btn-wrapper--icon">
					<MdRecordVoiceOver
						size={20}
						className={clsx(
							'm-1',
							isTalkbackActive ? '' : `text-${iconColor}`,
						)}
					/>
				</span>
			</Button>
			<MediaErrorPopover error={userAudioRequestError?.error} target={`TalkbackButton-${recipientUser._id}`} type="microphone" />
		</>
	);
};
