import React, { useEffect, useState } from 'react';
import { useAsyncCallback } from 'react-async-hook';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button, DropdownItem, UncontrolledDropdown, DropdownToggle, DropdownMenu, Spinner } from 'reactstrap';
import { FaAd } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';

import { useStudio } from '../../../Studio/Context';
import { useAd } from '../../../Ad/Context';
import { launchAd } from '../../../../api/ads/ads';
import { useFetchDefaultOrganization } from '../../../../api-hooks/public/organization';
import { useProfile } from '../../../Profile/ProfileContext';
import './Button.scss';

// We assume that the live delay for viewers is 30 seconds
const LIVE_DELAY = 30 * 1000;
// Offset of the ad screen in the stream (to have some margin with network latency)
const AD_SCREEN_OFFSET = 6 * 1000;

const adsDurationMinutes = [
	0.5,
	1,
	2,
];

export const AdButton = ({
	className,
	...props
}) => {
	const { t } = useTranslation();
	const { studio } = useStudio();
	const { adDuration, isAdActive } = useAd();

	const { profile } = useProfile();
	const { data: defaultOrganization } = useFetchDefaultOrganization();

	const [remainingTimeBeforeNextAd, setRemainingTimeBeforeNextAd] = useState(0);

	const handleAdLaunch = useAsyncCallback(async (duration) => {
		if (!isAdActive && studio) await launchAd({ duration, studioId: studio._id });
	});

	// Update the timer
	useEffect(() => {
		if (remainingTimeBeforeNextAd > 0) {
			const timer = setInterval(() => {
				setRemainingTimeBeforeNextAd((time) => time - 1000);
			}, 1000);
			return () => clearInterval(timer);
		}
		return undefined;
	}, [remainingTimeBeforeNextAd]);

	// Launch the timer in a useEffect to set it for all the controllers (host and operators)
	useEffect(() => {
		if (isAdActive) {
			setRemainingTimeBeforeNextAd(
				adDuration + AD_SCREEN_OFFSET + (studio.isLiveEnabled ? LIVE_DELAY : 0),
			);
		}
	}, [adDuration, isAdActive, studio.isLiveEnabled]);

	return remainingTimeBeforeNextAd > 0 ? (
		<Button
			className={clsx('AdButton AdButton_Active position-relative', className)}
			color="secondary shadow-none text-white"
			disabled
			title={t('ChannelButtonsPanel.Ads.Button.nextAdIn', { time: format(new Date(remainingTimeBeforeNextAd), t('Global.DateFormat.timeMinutesSeconds')) })}
			{...props}
		>
			<span className="btn-wrapper--icon position-absolute top-0">
				<FaAd size={20} />
			</span>
			<small className="position-absolute bottom-0">{format(new Date(remainingTimeBeforeNextAd), t('Global.DateFormat.timeMinutesSeconds'))}</small>
		</Button>
	) : (
		<UncontrolledDropdown>
			{profile?.organization?._id === defaultOrganization?._id && (
				<DropdownToggle
					className={clsx('AdButton d-flex align-items-center justify-content-center', className)}
					color="neutral-secondary"
					title={t('ChannelButtonsPanel.Ads.Button.launchAnAd')}
					disabled={handleAdLaunch.loading}
					{...props}
				>
					{handleAdLaunch.loading ? (
						<Spinner size="sm" />
					) : (
						<span className="btn-wrapper--icon d-inline-block">
							<FaAd size={24} />
						</span>
					)}
				</DropdownToggle>
			)}
			<DropdownMenu className="shadow-none">
				<strong className="dropdown-header content-darker">{t('ChannelButtonsPanel.Ads.Button.chooseAdDuration').toUpperCase()}</strong>
				{adsDurationMinutes.map((duration) => (
					<DropdownItem
						key={`ads-duration-${duration}`}
						onClick={() => handleAdLaunch.execute(duration * 60 * 1000)}
					>
						{t(`ChannelButtonsPanel.Ads.Button.duration.${duration}`)}
					</DropdownItem>
				))}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

AdButton.propTypes = {
	className: PropTypes.string,
};

AdButton.defaultProps = {
	className: '',
};
