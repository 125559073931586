import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// TODO: avoid this import for every player because it loads react-video
import { PlayerColorPicker } from '@technomiam/react-video';
import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from './helpers';
import './Player.scss';

const { MediaStream } = window;

export const propTypes = {
	autoPlay: PropTypes.bool,
	allowColorPicking: PropTypes.bool,
	className: PropTypes.string,
	controls: PropTypes.bool,
	cover: PropTypes.bool,
	noDuration: PropTypes.bool,
	noTimeline: PropTypes.bool,
	muted: PropTypes.bool,
	onPickColor: PropTypes.func,
	preload: PropTypes.oneOf(['auto', 'metadata', 'none']),
	src: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.instanceOf(MediaStream),
	]),
	onDuration: PropTypes.func,
	currentTime: PropTypes.number,
	onCanPlay: PropTypes.func,
};

const defaultProps = {
	autoPlay: false,
	allowColorPicking: false,
	className: '',
	controls: false,
	cover: false,
	muted: false,
	noDuration: false,
	noTimeline: false,
	onPickColor: undefined,
	preload: 'metadata',
	src: null,
	onDuration: undefined,
	currentTime: 1,
	onCanPlay: undefined,
};

const Player = ({
	autoPlay,
	allowColorPicking,
	className,
	controls,
	cover,
	muted,
	noDuration,
	noTimeline,
	onPickColor,
	preload,
	src,
	onDuration,
	currentTime,
	onCanPlay,
	...rest

}) => {
	const [duration, setDuration] = useState(null);
	const [isPlaying, setPlaying] = useState(false);
	const video = useRef(null);

	if (typeof src === 'string') src = `${src}#t=0.1`;

	useEffect(() => {
		if (src instanceof MediaStream) {
			const onAddtrack = () => {
				if (video.current) video.current.srcObject = src; // fix safari
			};
			src.addEventListener('addtrack', onAddtrack);
			return () => {
				src.removeEventListener('addtrack', onAddtrack);
			};
		}
		return undefined;
	}, [src]);

	const setVideoSrcobject = useCallback(() => {
		if (src instanceof MediaStream
			&& video.current.srcObject !== src) { // fix firefox
			video.current.srcObject = src;
		}

		if (!src) {
			video.current.srcObject = null;
			setPlaying(false);
		}
	}, [src]);

	const handleLoadedMetadata = useCallback((event) => {
		setDuration(Math.round(event.target.duration));
		if (onDuration) onDuration(Math.round(event.target.duration));
		if (onCanPlay) onCanPlay(video.current);
	}, [onDuration, onCanPlay]);

	const handlePause = useCallback(() => {
		setPlaying(false);
	}, []);

	const handlePlaying = useCallback(() => {
		setPlaying(true);
	}, []);

	const isMediaStream = src && (src instanceof MediaStream);

	useEffect(() => {
		setVideoSrcobject();
	});

	// Prevent memory leak
	useEffect(() => {
		const currentVideoElement = video.current;
		return () => {
			if (currentVideoElement) {
				currentVideoElement.srcObject = null;
			}
		};
	}, []);

	useEffect(() => {
		if (video.current && Number.isFinite(currentTime)) video.current.currentTime = currentTime;
	}, [currentTime]);

	return (
		<RatioContainer
			className={
				clsx(
					'PlayerWebapp',
					{
						cover,
						isPlaying,
						noTimeline,
						paused: !isPlaying,
					},
					className,
				)
			}
			{...rest}
		>
			{allowColorPicking && <PlayerColorPicker videoRef={video} onClick={onPickColor} />}
			<div className="PlayerWebapp_container">
				<video
					autoPlay={autoPlay}
					controls={controls}
					muted={muted}
					controlsList="nodownload"
					onLoadedMetadata={handleLoadedMetadata}
					onPause={handlePause}
					onPlaying={handlePlaying}
					playsInline
					preload={preload}
					ref={video}
					src={!isMediaStream ? src : null}
				/>
				{
					!controls
					&& !noDuration
					&& duration
					&& Number.isFinite(duration)
					&& (
						<span className="PlayerWebapp_duration">
							{minutes(duration)}:{seconds(duration)}
						</span>
					)
				}
			</div>
		</RatioContainer>
	);
};

Player.propTypes = propTypes;
Player.defaultProps = defaultProps;

export default React.memo(Player);
