/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { DropdownItem, DropdownToggle, DropdownMenu, Badge, Dropdown } from 'reactstrap';
import { FaImage, FaVideo } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { FaDisplay } from 'react-icons/fa6';
import { MdScreenShare, MdStopScreenShare } from 'react-icons/md';
import { useMediaShareImage, useMediaShareScreen, useMediaShareVideo, MediaShareImageParams, MediaShareVideoParams } from '@technomiam/react-video';

import { isDesktop, isSafari, isChromeOS } from '../../../../lib/userAgent';
import { MediaErrorPopover } from './MediaErrorPopover';
import { ElementsSectionTabs, useElementsSection } from '../../../../views/Controlroom/ElementsSection/Context';
import { ButtonPillOutline } from '../../../Button';

import './LocalShare.scss';

const isLocalScreenSharingCapable = (isDesktop || isChromeOS)
	&& navigator.mediaDevices?.getDisplayMedia;
const isLocalVideoSharingCapable = !isSafari;
const isLocalImageSharingCapable = !isSafari;

export const isLocalSharingCapable = isLocalScreenSharingCapable
	|| isLocalVideoSharingCapable || isLocalImageSharingCapable;

/**
 * @typedef {{
 * 	isOn: boolean;
 * }} OnOffBadgeProps
 */

const OnOffBadge = (
	/** @type {OnOffBadgeProps} */
	{ isOn },
) => {
	const { t } = useTranslation();
	return (
		<Badge className={isOn ? '' : 'content-darker'} color={isOn ? 'danger' : 'darker'}>{t(`LocalUserMedia.LocalShare.badge${isOn ? 'ON' : 'OFF'}`)}</Badge>
	);
};

/**
 * @typedef {{
 * 	className?: string;
 * }} LocalShareProps
 */

export const LocalShare = (
	/** @type {LocalShareProps} */
	{ className = '' },
) => {
	const { t } = useTranslation();
	const { setSelectedMedia } = useElementsSection();

	const inputImageRef = useRef(/** @type {HTMLInputElement?} */(null));
	const inputVideoRef = useRef(/** @type {HTMLInputElement?} */(null));

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);

	const {
		screenshareActive,
		screenshareRequestError,
		toggleScreenshare,
	} = useMediaShareScreen();

	const {
		videoshareActive,
		videoshareRequestError,
		requestVideoshare,
		setVideoshareRequestError,
		stopVideoshare,
	} = useMediaShareVideo();

	const {
		imageshareActive,
		imageshareRequestError,
		requestImageshare,
		setImageshareRequestError,
		stopImageshare,
	} = useMediaShareImage();

	const isActive = screenshareActive || videoshareActive || imageshareActive;
	const error = screenshareRequestError || videoshareRequestError || imageshareRequestError;
	const errorType = screenshareRequestError ? 'screen' : 'video';

	// eslint-disable-next-line no-nested-ternary
	const buttonColor = error ? 'neutral-danger' : isActive ? 'secondary' : 'neutral-secondary';
	const animatedIconColor = error ? 'danger' : 'secondary';
	// eslint-disable-next-line no-nested-ternary
	const iconColor = error ? 'text-danger' : isActive ? 'content-secondary' : 'text-secondary';

	useEffect(() => {
		if (screenshareActive && setSelectedMedia) setSelectedMedia(ElementsSectionTabs.GUESTS);
	}, [screenshareActive, setSelectedMedia]);

	const handleInputShareVideo = useCallback((
		/** @type {React.ChangeEvent<HTMLInputElement>} */event,
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			setVideoshareRequestError(new Error());
			return;
		}

		const acceptedFile = event.target.files[0];
		requestVideoshare(acceptedFile);
		if (setSelectedMedia) setSelectedMedia(ElementsSectionTabs.VIDEOS);

		// Reset input value to allow selecting the same file again
		event.target.value = '';
	}, [setSelectedMedia, requestVideoshare, setVideoshareRequestError]);

	const handleShareVideo = useCallback(() => {
		if (!videoshareActive && inputVideoRef.current) {
			setIsDropdownOpen(false);
			inputVideoRef.current.click();
		} else if (videoshareActive) stopVideoshare();
	}, [videoshareActive, stopVideoshare]);

	const handleInputShareImage = useCallback((
		/** @type {React.ChangeEvent<HTMLInputElement>} */event,
	) => {
		if (!event.target.files || event.target.files.length === 0) {
			setImageshareRequestError(new Error());
			return;
		}

		const acceptedFile = event.target.files[0];
		requestImageshare(acceptedFile);
		if (setSelectedMedia) setSelectedMedia(ElementsSectionTabs.IMAGES);

		// Reset input value to allow selecting the same file again
		event.target.value = '';
	}, [setSelectedMedia, requestImageshare, setImageshareRequestError]);

	const handleShareImage = useCallback(() => {
		if (!imageshareActive && inputImageRef.current) {
			setIsDropdownOpen(false);
			inputImageRef.current.click();
		} else if (imageshareActive) stopImageshare();
	}, [imageshareActive, stopImageshare]);

	const numberOfActiveShares = [screenshareActive, videoshareActive, imageshareActive]
		.filter(Boolean).length;

	const handleStopAll = useCallback(() => {
		if (screenshareActive) toggleScreenshare();
		if (videoshareActive) stopVideoshare();
		if (imageshareActive) stopImageshare();
		setIsDropdownOpen(false);
	}, [
		screenshareActive,
		toggleScreenshare,
		videoshareActive,
		stopVideoshare,
		imageshareActive,
		stopImageshare,
	]);

	return (
		<>
			<Dropdown
				isOpen={isDropdownOpen}
				toggle={() => setIsDropdownOpen((prev) => !prev)}
			>
				<DropdownToggle
					className={clsx('LocalShare btn-animated-icon', `btn-animated-icon--${animatedIconColor}`, className)}
					color={buttonColor}
					id="LocalShare"
					title={t('LocalUserMedia.LocalShare.streamMedia')}
				>
					<span className="btn-wrapper--icon d-inline-block">
						<MdScreenShare className={iconColor} size={20} />
					</span>
				</DropdownToggle>
				<MediaErrorPopover error={error} target="LocalShare" type={errorType} />
				<DropdownMenu className="shadow-none">
					<strong className="dropdown-header content-darker text-uppercase">{t('LocalUserMedia.LocalShare.selectMediaToStream')}</strong>
					{isLocalScreenSharingCapable && (
						<DropdownItem
							className="d-flex align-items-center justify-content-between"
							onClick={toggleScreenshare}
							title={screenshareActive ? t('LocalUserMedia.LocalShare.turnOffScreenSharing') : t('LocalUserMedia.LocalShare.turnOnScreenSharing')}
						>
							<div>
								<FaDisplay className="me-2" />
								{t('LocalUserMedia.LocalShare.screen')}
							</div>
							<OnOffBadge isOn={screenshareActive} />
						</DropdownItem>
					)}
					{isLocalVideoSharingCapable && (
						<DropdownItem
							className="d-flex align-items-center justify-content-between"
							onClick={handleShareVideo}
							title={videoshareActive ? t('LocalUserMedia.LocalShare.turnOffVideoSharing') : t('LocalUserMedia.LocalShare.turnOnVideoSharing')}
						>
							<div>
								<FaVideo className="me-2" />
								{t('LocalUserMedia.LocalShare.video')}
							</div>
							<OnOffBadge isOn={videoshareActive} />
						</DropdownItem>
					)}
					{isLocalImageSharingCapable && (
						<DropdownItem
							className="d-flex align-items-center justify-content-between"
							onClick={handleShareImage}
							title={imageshareActive ? t('LocalUserMedia.LocalShare.turnOffImageSharing') : t('LocalUserMedia.LocalShare.turnOnImageSharing')}
						>
							<div>
								<FaImage className="me-2" />
								{t('LocalUserMedia.LocalShare.image')}
							</div>
							<OnOffBadge isOn={imageshareActive} />
						</DropdownItem>
					)}
					{numberOfActiveShares >= 2 && (
						<>
							<DropdownItem divider className="border-secondary" />
							<div className="text-center content-dark">
								<ButtonPillOutline color="danger" size="sm" className="fw-bold text-uppercase" onClick={handleStopAll}>
									<MdStopScreenShare className="me-2" size={18} />
									{t('LocalUserMedia.LocalShare.stopAll')}
								</ButtonPillOutline>
							</div>
						</>
					)}
				</DropdownMenu>
			</Dropdown>
			<input accept={MediaShareVideoParams.ACCEPTED_FORMATS.join(',')} className="d-none" type="file" onInput={handleInputShareVideo} ref={inputVideoRef} />
			<input accept={MediaShareImageParams.ACCEPTED_FORMATS.join(',')} className="d-none" type="file" onInput={handleInputShareImage} ref={inputImageRef} />
		</>
	);
};
