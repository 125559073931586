import PropTypes from 'prop-types';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useQueryClient } from 'react-query';

import { GRAPH_GLOBALS_QUERY_KEYS, useFetchGraphGlobalsActiveStudio, useSetGraphGlobalsActiveStudio } from '../../../api-hooks/graph/globals';
import { useAuthentication } from '../../Authentication/Authentication';
import { usePoints } from '../../Membership/Points';
import { StudioStatus } from '../../StudioSchedule/helper';
import { ActiveStudioContext, useActiveStudio } from './Context';
import { useProfile } from '../../Profile/ProfileContext';

export { ActiveStudioContext, useActiveStudio };

export const ActiveStudioProvider = ({ children }) => {
	const { user } = useAuthentication();
	const { data: activeStudio } = useFetchGraphGlobalsActiveStudio();
	const setActiveStudio = useSetGraphGlobalsActiveStudio();
	const [estimation, setEstimation] = useState(0);
	const [isEstimationBellowLimit, setIsEstimationBellowLimit] = useState(false);
	const { handleEventBalanceChange } = usePoints();
	const { profile } = useProfile();

	const queryClient = useQueryClient();

	const isActiveStudioStarted = [
		StudioStatus.RUNNING,
		StudioStatus.PENDING,
	].includes(activeStudio?.status);

	const updateActiveStudio = useCallback((data) => {
		if (!activeStudio || data._id !== activeStudio?._id) return;
		setActiveStudio((currentData) => ({
			...currentData,
			...data,
		}));
	}, [activeStudio, setActiveStudio]);

	const reloadActiveStudio = useCallback(() => {
		queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(user?.sub));
	}, [queryClient, user?.sub]);

	const handleEventActiveStudioStatus = useCallback((data) => {
		if (activeStudio && data._id === activeStudio?._id) {
			setActiveStudio((currentData) => ({
				...currentData,
				...data,
			}));
		} else {
			reloadActiveStudio(); // TODO: needed ?
		}
	}, [activeStudio, reloadActiveStudio, setActiveStudio]);

	const handleEventBalanceChangeRef = useRef(handleEventBalanceChange);
	useEffect(() => {
		handleEventBalanceChangeRef.current = handleEventBalanceChange;
	});

	const handleStudioCharge = useCallback((notification) => {
		if (notification.studio === activeStudio?._id || profile.organizationOwner) {
			// TODO: remove ?
			// Should we send a balance change event instead ?
			if (handleEventBalanceChangeRef.current) {
				handleEventBalanceChangeRef.current({
					balance: notification.balance,
					chipBalance: notification.chipBalance,
				});
			}

			const minutesLeft = Math.ceil(
				(notification.balance + notification.chipBalance) / notification.lastMinuteCharge,
			);
			const totalEstimation =	minutesLeft
				+ (activeStudio ? Math.floor((new Date() - new Date(activeStudio?.readyAt)) / 60000) : 0);

			setEstimation(Math.min(12 * 60, totalEstimation));
			setIsEstimationBellowLimit(minutesLeft <= 5);
		}
	}, [activeStudio, profile]);

	const context = useMemo(() => ({
		activeStudio,
		handleEventActiveStudioStatus,
		reloadActiveStudio,
		isActiveStudioStarted,
		estimation,
		isEstimationBellowLimit,
		handleStudioCharge,
		updateActiveStudio,
	}), [
		activeStudio,
		handleEventActiveStudioStatus,
		reloadActiveStudio,
		isActiveStudioStarted,
		estimation,
		isEstimationBellowLimit,
		handleStudioCharge,
		updateActiveStudio,
	]);

	return (
		<ActiveStudioContext.Provider value={context}>
			{children}
		</ActiveStudioContext.Provider>
	);
};

ActiveStudioProvider.propTypes = {
	children: PropTypes.node,
};

ActiveStudioProvider.defaultProps = {
	children: undefined,
};
