// @ts-check

import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Button } from 'reactstrap';

/**
 * @typedef {{
 * 	className?: string,
 * 	color?: string,
 * } & import('reactstrap').ButtonProps} ButtonPillProps
 */

export const ButtonPill = (
	/** @type {ButtonPillProps} */
	{
		className = '',
		color = 'primary',
		...props
	},
) => (
	<Button
		className={clsx('btn-pill font-weight-bold', className)}
		color={color}
		{...props}
	/>
);

ButtonPill.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

ButtonPill.defaultProps = {
	className: '',
	color: 'primary',
};

/**
 * @typedef {{
 * 	className?: string,
 * 	color?: string,
 * } & ButtonPillProps} ButtonPillOutlineProps
 */

export const ButtonPillOutline = (
	/** @type {ButtonPillOutlineProps} */
	{
		className = '',
		color = 'primary',
		...props
	},
) => (
	<ButtonPill
		className={clsx('text-inherit', className)}
		color={`outline-${color}`}
		{...props}
	/>
);

ButtonPillOutline.propTypes = {
	className: PropTypes.string,
	color: PropTypes.string,
};

ButtonPillOutline.defaultProps = {
	className: '',
	color: 'primary',
};
