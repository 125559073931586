import React from 'react';
import { useTranslation } from 'react-i18next';

import { ButtonPillOutline } from '../Button';
import { NewUserSignUpOrHomeButton } from './SignUpOrHomeButton';
import { NewUserLearnMoreButton } from './LearnMoreButton';

export const NewUserBannerBlue = () => {
	const { t } = useTranslation();

	return (
		<div className="NewUserBanner_Item NewUserBanner_Mobile NewUserBanner_Desktop NewUser_BgBlue">
			<div className="NewUser_LeftDiv">
				<div className="NewUserBanner_Title">
					<div>{t('NewUser.Banner.Blue.title')}</div>
				</div>
				<div className="NewUserBanner_Body">
					<div>
						{t('NewUser.Banner.Blue.body.summary')}
					</div>
				</div>
				<div className="NewUser_ButtonContainer">
					<NewUserLearnMoreButton className="NewUserBanner_Button" anchor="about" />
					<NewUserSignUpOrHomeButton
						className="NewUserBanner_Button"
						Component={ButtonPillOutline}
					/>
				</div>
			</div>
			<div className="NewUser_RightDiv NewUser_ImageBlue" />
		</div>
	);
};
