/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from 'react-i18next';

import RatioContainer from '../RatioContainer/RatioContainer';
import { minutes, seconds } from '../Player/helpers';
import { getThumbnail } from '../../lib/file';
import { useValidateImage } from '../../lib/useValidateImage';

import './Thumbnail.scss';

/**
 * @typedef {{
 * 	absoluteChildren?: React.ReactNode,
 * 	className?: string,
 * 	duration?: number,
 * 	video: {
 * 		duration?: number?,
 * 		status?: string?,
 * 		playbackId?: string?,
 * 		thumbnailTimeCode?: number?,
 * 		filename?: string?,
 * 	},
 * }} VideoThumbnailProps
 */

export const VideoThumbnail = (
	/** @type {VideoThumbnailProps} */
	{
		absoluteChildren,
		className = '',
		duration,
		video,
	},
) => {
	const { t } = useTranslation();
	const [videoDuration, setVideoDuration] = useState(0);
	const validateImage = useValidateImage();

	const handleLoadedMetadata = useCallback((
		/** @type {React.SyntheticEvent<HTMLVideoElement>} */event,
	) => {
		setVideoDuration(event.currentTarget.duration);
	}, []);

	const durationValue = Math.floor(duration ?? video?.duration ?? videoDuration);
	const src = getThumbnail(video);
	const isVideo = video.status !== 'ready';

	return (
		<RatioContainer
			className={
				clsx(
					'VideoThumbnail',
					className,
					{ 'position-relative': !!absoluteChildren },
				)
			}
		>
			<div className="VideoThumbnail_container">
				{isVideo ? (
					<video
						onLoadedMetadata={handleLoadedMetadata}
						muted
						src={src}
					/>
				) : (
					<LazyLoadImage
						alt={t('Video.Thumbnail.videoThumbnail')}
						src={src}
						onError={validateImage}
					/>
				)}
				{
					!Number.isNaN(durationValue)
					&& Number.isFinite(durationValue)
					&& durationValue > 0
					&& (
						<span className="Player_duration">
							{minutes(durationValue)}:{seconds(durationValue)}
						</span>
					)
				}
			</div>
			{absoluteChildren}
		</RatioContainer>
	);
};
