// @ts-check
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuthentication } from '../Authentication/Authentication';
import * as publicChannelApi from '../../api/public/channel';
import * as channelApi from '../../api/channel/channel';

/**
 * @typedef {{
 * 	channel?: channelApi.IChannelPublicDto?,
 * 	fetchOneChannelByHashtag: (hashtag: string) => Promise<void>,
 * 	setChannel: (channel: channelApi.IChannelPublicDto) => void,
 * }} IChannelContext
 */

export const ChannelContext = createContext(/** @type {IChannelContext | undefined} */(undefined));

// See : https://stackoverflow.com/a/66331283/2440064
// and for further details by Kent C. Dodds:
// https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
export const useChannel = () => {
	const channelContext = useContext(ChannelContext);
	// type guard (removes undefined type)
	if (!channelContext) {
		throw new Error('useChannel must be used within a ChannelProvider');
	}
	return channelContext;
};

// TODO: complete when IChannelPublic is completed
/**
 * @typedef {{
* 	channel?: channelApi.IChannelPublicDto,
* 	children: React.ReactNode,
* }} ChannelProviderProps
*/

export const ChannelProvider = (
	/** @type {ChannelProviderProps} */
	{
		channel: channelProp,
		children,
	},
) => {
	const [channel, setChannel] = useState(
		/** @type {channelApi.IChannelPublicDto | null | undefined} */(channelProp),
	);
	const { isLoggedIn } = useAuthentication();

	const fetchOneChannelByHashtag = useCallback(async (/** @type {string} */hashtag) => {
		const doRequest = isLoggedIn
			? () => channelApi.fetchOneChannel(hashtag)
			: () => publicChannelApi.fetchOneChannelByHashtag(hashtag);
		const { data } = await doRequest();
		setChannel(data);
	}, [isLoggedIn]);

	useEffect(() => {
		setChannel(channelProp);
	}, [channelProp]);

	const value = useMemo(() => ({
		channel,
		fetchOneChannelByHashtag,
		setChannel,
	}), [
		channel,
		fetchOneChannelByHashtag,
		setChannel,
	]);

	return (
		<ChannelContext.Provider value={value}>
			{children}
		</ChannelContext.Provider>
	);
};

ChannelProvider.propTypes = {
	channel: PropTypes.shape({
		_id: PropTypes.string.isRequired,
		hashtag: PropTypes.string.isRequired,
		nickname: PropTypes.string.isRequired,
		numberOfLikes: PropTypes.number.isRequired,
	}),
	children: PropTypes.node.isRequired,
};

ChannelProvider.defaultProps = {
	channel: undefined,
};
