import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';
import { Button } from 'reactstrap';
import {
	useMediaUser,
} from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';

export const LocalCam = ({ className, configId, ...props }) => {
	const { toggleVideo, getIsUserVideoActive, userVideoRequestErrors } = useMediaUser();
	const userVideoRequestError = userVideoRequestErrors.find((e) => (
		e.configId === configId && e.error
	));
	const userVideoActive = getIsUserVideoActive(configId);

	let color = 'primary';
	if (userVideoRequestError) {
		color = 'neutral-danger';
	} else if (!userVideoActive) {
		color = 'neutral-primary';
	}

	const animatedIconColor = userVideoRequestError ? 'danger' : 'primary';

	return (
		<>
			<Button
				id="LocalCam"
				tag="span"
				className={clsx('LocalCam btn-animated-icon', `btn-animated-icon--${animatedIconColor} content-${animatedIconColor}`, className)}
				color={color}
				onClick={() => toggleVideo(configId)}
				{...props}
			>
				<span className="btn-wrapper--icon d-inline-block">
					{!userVideoActive
						? <FaVideoSlash size={20} />
						: <FaVideo size={20} />}
				</span>
			</Button>
			<MediaErrorPopover error={userVideoRequestError?.error} target="LocalCam" type="camera" />
		</>
	);
};

LocalCam.propTypes = {
	className: PropTypes.string,
	configId: PropTypes.number,
};

LocalCam.defaultProps = {
	className: '',
	configId: 0,
};
