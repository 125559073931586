import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ResourceAccessCard } from './Card';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';
import { getResourceCurrencyLabel } from './Buy';
import { ButtonLoading, ButtonPill } from '../Button';
import * as resourceAccessApi from '../../api/resourceAccess';
import BuyPointsButton from '../Transactions/BuyPointsButton';

export const ResourceAccessPricePerMinuteWarning = ({
	resourceType,
	role,
	resourceData,
	onSuccess,
}) => {
	const { t } = useTranslation();
	const price = useMemo(() => (role === ResourceAccessRole.VIEWER
		? resourceData.price.viewerPerMinute
		: resourceData.price.participantPerMinute),
	[resourceData, role]);

	const currency = useMemo(() => (role === ResourceAccessRole.VIEWER
		? getResourceCurrencyLabel(resourceData.currencies.viewerPerMinute)
		: getResourceCurrencyLabel(resourceData.currencies.participantPerMinute)),
	[resourceData, role]);

	const { execute: confirm, loading, error } = useAsyncCallback(async () => {
		await resourceAccessApi.buy(
			resourceType,
			resourceData._id,
			role,
			{
				chips: 0,
				piPoints: 0,
			},
		);

		onSuccess();
	});

	return (
		<ResourceAccessCard>
			<h3>
				{t('PricePerMinuteWarning.thisMeeting')} {price} {currency}
			</h3>
			<p>
				{t('PricePerMinuteWarning.youMustConfirm')}
			</p>
			{!error ? (
				<ButtonLoading
					className="ml-auto shadow-none"
					color="primary"
					component={ButtonPill}
					loading={loading}
					onClick={confirm}
				>
					{t('PricePerMinuteWarning.confirm')}
				</ButtonLoading>
			) : (
				<>
					<Alert color="danger">
						{error.response?.data.message ?? error.message}
					</Alert>
					<BuyPointsButton color="primary" size="sm">{t('PricePerMinuteWarning.buy')}</BuyPointsButton>
				</>
			)}
		</ResourceAccessCard>
	);
};

ResourceAccessPricePerMinuteWarning.propTypes = {
	resourceType: PropTypes.string.isRequired,
	resourceData: PropTypes.shape({
		_id: PropTypes.string,
		price: PropTypes.shape({
			viewerPerMinute: PropTypes.number,
			participantPerMinute: PropTypes.number,
		}),
		currencies: PropTypes.shape({
			viewerPerMinute: PropTypes.arrayOf(PropTypes.string),
			participantPerMinute: PropTypes.arrayOf(PropTypes.string),
		}),
	}).isRequired,
	role: PropTypes.string.isRequired,
	onSuccess: PropTypes.func.isRequired,
};
