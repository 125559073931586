import React from 'react';
import {
	Row,
	Col,
	Container,
	Card,
	CardBody,
	CardText,
	CardTitle,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';

const SubscriptionCompleted = () => {
	const { t } = useTranslation();
	return (
		<Container className="mt-5 mb-5">
			<Row>
				<Col xl={{ size: 6, offset: 3 }} md={{ size: 8, offset: 2 }}>
					<Card>
						<CardBody className="text-black">
							<CardTitle>{t('SubscriptionCompleted.SubscriptionCompeted.congratulations')}</CardTitle>
							<CardText>{t('SubscriptionCompleted.SubscriptionCompeted.subscriptionActive')}</CardText>
						</CardBody>
					</Card>
				</Col>
			</Row>
		</Container>
	);
};

export default SubscriptionCompleted;
