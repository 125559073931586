/* eslint-disable react/prop-types */
// @ts-check

import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';

import { notifyHandRaised } from '../../../api/channel/handRaising';
import { Sound, useSound } from '../../Sound/Provider';

const RAISE_HAND_TIMEOUT = 1000 * 5;

/**
 * @typedef {{
 *  guestId: string,
 * 	timestamp: number,
 * 	order: number,
 * }} GuestHandRaised
 */

// TODO : studio type in raiseHand
/**
 * @typedef {{
 * raiseHand: (studio: object) => void,
 * setGuestRaisedHand: (guestId: string) => void,
 * removeGuestHandRaised: (guest: string) => void,
 * isHandRaised: boolean,
 * guestsHandRaised: GuestHandRaised[],
 * }} IGuestHandRaisingContext
 */

const GuestHandRaisingContext = createContext(/** @type {IGuestHandRaisingContext} */({}));

export const useHandRaising = () => useContext(GuestHandRaisingContext);

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} GuestHandRaisingProviderProps
 */

export const GuestHandRaisingProvider = (
	/** @type {GuestHandRaisingProviderProps} */
	{ children },
) => {
	const [isHandRaised, setIsHandRaised] = useState(false);
	const [guestsHandRaised, setGuestsHandRaised] = useState(/** @type {GuestHandRaised[]} */([]));
	const { playSound } = useSound();

	const timeoutHandRaised = () => {
		setIsHandRaised((prevState) => !prevState);
		setTimeout(() => {
			setIsHandRaised((prevState) => !prevState);
		}, RAISE_HAND_TIMEOUT);
	};

	const raiseHand = useCallback((
		// TODO : studio type
		/** @type {object}*/studio,
	) => {
		timeoutHandRaised();
		notifyHandRaised(studio);
	}, []);

	const setGuestRaisedHand = useCallback((
		/** @type {string} */guestId,
	) => {
		playSound(Sound.HAND_RAISED);
		timeoutHandRaised();
		setGuestsHandRaised((prevState) => (
			[...prevState, { guestId, timestamp: Date.now() }]
				.sort((a, b) => a.timestamp - b.timestamp)
				.map((guest, index) => ({ ...guest, order: index }))
		));
	}, [playSound]);

	const removeGuestHandRaised = useCallback((
		/** @type {string} */guest,
	) => {
		setGuestsHandRaised((prevState) => (
			[...prevState.filter(({ guestId }) => guestId !== guest)]
				.sort((a, b) => a.timestamp - b.timestamp)
				.map((g, index) => ({ ...g, order: index }))
		));
	}, []);

	const value = useMemo(() => ({
		raiseHand,
		setGuestRaisedHand,
		removeGuestHandRaised,
		isHandRaised,
		guestsHandRaised,
	}), [
		raiseHand,
		setGuestRaisedHand,
		removeGuestHandRaised,
		isHandRaised,
		guestsHandRaised,
	]);

	return (
		<GuestHandRaisingContext.Provider value={value}>
			{children}
		</GuestHandRaisingContext.Provider>
	);
};
