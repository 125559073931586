import React, { useCallback, useContext, useEffect, useMemo, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import { useQueryClient } from 'react-query';
import Identity, { IdentityError } from '../../lib/Identity';

const { VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT } = import.meta.env;

const BEEYOU_SESSION_LOCALSTORAGE_KEY = 'beeyou.session';

export const Role = {
	ADMIN: 'ROLE_ADMIN',
	GUEST: 'ROLE_GUEST',
	USER: 'ROLE_USER',
};

/**
 * Simplified authentication mechanism using a single token (id_token)
 */
class AuthenticationIdentity extends Identity {
	baseURL = VITE_API_IDENTITY_AUTHENTICATION_ENDPOINT;

	localstorageSessionKey = BEEYOU_SESSION_LOCALSTORAGE_KEY;

	isSessionExpired() {
		return (
			!this.session.id_token
			|| this.session.id_token.expiresAt <= Date.now()
		);
	}

	getIdTokenSync() {
		const { id_token } = this.session;
		if (!id_token) throw new IdentityError('id_token_not_found', 'ID Token not found.');
		if (!this.isTokenValid(id_token)) {
			this.logout(); // this is an weird case, a logout is preferred
			throw new IdentityError('id_token_invalid', 'ID Token is invalid.');
		}
		return id_token;
	}

	getUserSync() {
		const { payload } = this.getIdTokenSync();
		return payload;
	}

	async requestToken(accessToken) {
		const { data: tokens } = await this.api.post(
			'/token',
			null,
			{
				headers: { authorization: `Bearer ${accessToken}` },
			},
		);
		return tokens;
	}

	async getRefreshToken() {
		return this.getAccessToken();
	}
}

export const identity = new AuthenticationIdentity().initialize();

export const AuthenticationContext = React.createContext();

export const useAuthentication = () => useContext(AuthenticationContext);

export const useHasRole = (role) => {
	const authentication = useAuthentication();
	return authentication.isLoggedIn && authentication.user.role === role;
};

const isGuest = (user) => user.role === Role.GUEST;

const reducer = (state, action) => {
	switch (action.type) {
	case 'AUTH_PENDING': {
		return {
			...state,
			isPending: true,
		};
	}
	case 'LOGIN': {
		if (isGuest(action.user)) {
			return {
				isGuest: true,
				isLoggedIn: false,
				guest: action.user,
			};
		}

		return {
			isLoggedIn: true,
			user: action.user,
		};
	}
	case 'LOGOUT': {
		return {};
	}
	case 'AUTH_ERROR':
	case 'CANCEL_ORGANIZATION_CHALLENGE':
	default:
		return state;
	}
};

const initAuthentication = () => {
	try {
		const user = identity.getUserSync();
		ReactGA.set({ userId: user.sub });

		if (isGuest(user)) {
			return {
				isGuest: true,
				isLoggedIn: false,
				guest: user,
			};
		}

		return {
			isLoggedIn: true,
			user,
		};
	} catch (error) {
		if (!(error instanceof IdentityError)) {
			throw error;
		}
		return {};
	}
};

export const AuthenticationProvider = ({ children }) => {
	const [authentication, dispatch] = useReducer(reducer, {}, initAuthentication);
	const queryClient = useQueryClient();

	const login = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.login(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const loginChallengeSelectOrganization = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.selectOrganization(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const loginAfterGoogleSignUp = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.loginAfterGoogleSignUp(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const loginAsGuest = useCallback(async (...args) => {
		dispatch({ type: 'AUTH_PENDING' });
		try {
			const loginResponse = await identity.loginAsGuest(...args);
			return loginResponse;
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const switchTokens = useCallback(async (tokens) => {
		identity.loginWithTokens(tokens);
	}, []);

	const storePreviousSession = useCallback(() => {
		identity.setPreviousSession();
	}, []);

	const restorePreviousSession = useCallback(() => {
		const previousSession = identity.getPreviousSession();

		switchTokens({
			access_token: previousSession.access_token.token,
			id_token: previousSession.id_token.token,
			userId: previousSession.access_token.payload.sub,
		});

		identity.clearPreviousSession();
	}, [switchTokens]);

	const cancelLoginChallengeSelectOrganization = useCallback(() => {
		dispatch({ type: 'CANCEL_ORGANIZATION_CHALLENGE' });
	}, []);

	const logout = useCallback((...args) => {
		try {
			identity.logout(...args);
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const refreshSession = useCallback(async () => {
		try {
			await identity.refreshSession();
		} catch (error) {
			dispatch({ type: 'AUTH_ERROR' });
			throw error;
		}
	}, []);

	const isSessionActive = authentication.isLoggedIn || authentication.isGuest;
	const { isPending } = authentication;
	const isSessionActiveOrPending = isSessionActive || isPending;

	const contextValue = useMemo(() => ({
		...authentication,
		isSessionActive,
		login,
		loginAfterGoogleSignUp,
		loginAsGuest,
		logout,
		loginChallengeSelectOrganization,
		cancelLoginChallengeSelectOrganization,
		refreshSession,
		switchTokens,
		storePreviousSession,
		restorePreviousSession,
	}), [
		authentication,
		isSessionActive,
		login,
		loginAfterGoogleSignUp,
		loginAsGuest,
		logout,
		loginChallengeSelectOrganization,
		cancelLoginChallengeSelectOrganization,
		refreshSession,
		switchTokens,
		storePreviousSession,
		restorePreviousSession,
	]);

	useEffect(() => {
		const onLogin = () => {
			try {
				const user = identity.getUserSync();
				ReactGA.set({ userId: user.sub });
				dispatch({
					type: 'LOGIN',
					user,
				});
			} catch (error) {
				console.error(error);
			}
		};

		const onLogout = () => {
			dispatch({ type: 'LOGOUT' });
		};

		identity.on('login', onLogin);
		identity.on('logout', onLogout);
		identity.on('refresh', onLogin);

		return () => {
			identity.off('login', onLogin);
			identity.off('logout', onLogout);
			identity.off('refresh', onLogin);
		};
	}, [queryClient]);

	const isInitialRender = useRef(true);
	const userOrGuest = authentication.user || authentication.guest;

	useEffect(() => {
		if (isInitialRender.current) {
			isInitialRender.current = false;
			return;
		}
		/**
		 * Invalidate queries when user or guest changes :
		 * - login
		 * - logout
		 * - refresh
		 * Dont need to invalidate after first render as the user is retrieved in sync
		 */
		queryClient.invalidateQueries();
	}, [queryClient, userOrGuest]);

	useEffect(() => {
		if (!isSessionActiveOrPending) {
			/**
			 * When the session is not active or pending, login as guest
			 */
			loginAsGuest();
		}
	}, [isSessionActiveOrPending, loginAsGuest]);

	return (
		<AuthenticationContext.Provider value={contextValue}>
			{/* Session must be establised before rendering the app (guest or user)  */}
			{isSessionActive ? children : null}
		</AuthenticationContext.Provider>
	);
};

AuthenticationProvider.propTypes = {
	children: PropTypes.node,
};

AuthenticationProvider.defaultProps = {
	children: undefined,
};
