import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	useMediaKey,
} from '@technomiam/react-video';
import './preview.scss';

const useConst = (initialValue) => {
	const ref = useRef();
	if (typeof ref.current === 'undefined') {
		ref.current = {
			value: typeof initialValue === 'function' ? initialValue() : initialValue,
		};
	}
	return ref.current.value;
};

export const LocalUserMediaPreview = ({ isOpen, isPortrait }) => {
	const {
		keyOrUserVideoActiveTracks,
	} = useMediaKey();
	const source = useConst(() => document.createElement('video'));
	const canvasRef = useRef();
	const id = useRef();

	const perform = useCallback(() => {
		if (!canvasRef.current) {
			cancelAnimationFrame(id.current);
			return;
		}
		const ctx = canvasRef.current.getContext('2d');

		ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
		ctx.drawImage(
			source,
			0,
			0,
			canvasRef.current.width,
			canvasRef.current.height,
		);

		if (isOpen) id.current = window.requestAnimationFrame(perform);
	}, [
		source,
		isOpen,
	]);

	useEffect(() => {
		source.srcObject = undefined;
		source.playsInline = true;
		if (id.current) window.cancelAnimationFrame(id.current);

		const timeout = setTimeout(() => {
			if (keyOrUserVideoActiveTracks?.length > 0) {
				const { width, height } = keyOrUserVideoActiveTracks[0].getSettings();
				canvasRef.current.width = width;
				canvasRef.current.height = height;
			}
		}, 1000);

		if (isOpen) {
			const videotrack = new MediaStream(keyOrUserVideoActiveTracks);
			source.srcObject = videotrack;
			source.oncanplay = () => {
				source.play();
				perform();
			};
		}

		return () => {
			source.srcObject = null;
			clearTimeout(timeout);
		};
	}, [keyOrUserVideoActiveTracks, perform, source, isOpen, isPortrait]);

	return (
		<div className="w-100 h-100 position-relative d-flex">
			<canvas
				className="LocalUserMediaPreview d-flex m-auto"
				ref={canvasRef}
			/>
		</div>
	);
};

LocalUserMediaPreview.propTypes = {
	isOpen: PropTypes.bool,
	isPortrait: PropTypes.bool,
};

LocalUserMediaPreview.defaultProps = {
	isOpen: false,
	isPortrait: false,
};
