/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { StudioStatus } from '../../StudioSchedule/helper';
import { StudioStatusContext } from './Context';

/**
 * @typedef {{
 *   children: React.ReactNode,
 *   studio?: {
 *    _id?: string,
 *    isLiveEnabled?: boolean,
 *    liveStatus?: string,
 *    status?: string,
 *    isPublic?: boolean,
 *  },
 * }} StudioStatusProvider
 */
// TODO: replace studio by studio DTO

export const StudioStatusProvider = (
	/** @type {StudioStatusProvider} */
	{ children, studio = {} },
) => {
	const [currentStudioId, setCurrentStudioId] = useState(studio?._id);
	// This state is used to allow viewers to watch ends of live (after the live is stopped)
	// For real time users, use isLiveAlmostTerminated instead
	const [isLiveStopped, setIsLiveStopped] = useState(studio?.liveStatus === 'live_stopped');

	const isStudioCancelled = studio?.status === StudioStatus.CANCELLED;
	const isStudioPending = studio?.status === StudioStatus.PENDING;
	const isStudioPlanned = studio?.status === StudioStatus.PLANNED;
	const isStudioRunning = studio?.status === StudioStatus.RUNNING;
	const isStudioStopped = studio?.status === StudioStatus.STOPPED;

	const isStudioPublic = studio?.isPublic || false;
	const isLiveEnabled = studio?.isLiveEnabled || false;

	const isLiveRecordingStarted = studio?.liveStatus === 'live_recording_started';
	const isLiveActive = studio?.liveStatus === 'active';
	const isLiveAlmostTerminated = studio?.liveStatus === 'live_stopped' && !isLiveStopped;
	const isLivePlanned = isStudioPublic
		&& !isLiveActive
		&& !isLiveAlmostTerminated
		&& !isLiveStopped
		&& !isStudioStopped;
	const isLiveAboutToStart = isLiveEnabled && isLivePlanned;

	const handleLiveStopped = useCallback(() => {
		setIsLiveStopped(true);
	}, []);

	useEffect(() => {
		// Reset correctly isLiveStopped value when new studio
		if (studio?._id && currentStudioId !== studio._id) {
			setIsLiveStopped(studio.liveStatus === 'live_stopped');
			setCurrentStudioId(studio._id);
			return;
		}

		// Stop live correctly if studio was stopped before the live was active
		if (isStudioStopped && !isLiveAlmostTerminated && !isLiveActive) {
			setIsLiveStopped(true);
		}
	}, [
		currentStudioId,
		isLiveActive,
		isLiveAlmostTerminated,
		isStudioStopped,
		studio?._id,
		studio?.liveStatus,
	]);

	const value = useMemo(() => ({
		handleLiveStopped,
		isLiveAboutToStart,
		isLiveRecordingStarted,
		isLiveActive,
		isLiveAlmostTerminated,
		isLiveEnabled,
		isLivePlanned,
		isLiveStopped,
		isStudioCancelled,
		isStudioPending,
		isStudioPlanned,
		isStudioPublic,
		isStudioRunning,
		isStudioStopped,
	}), [
		handleLiveStopped,
		isLiveAboutToStart,
		isLiveRecordingStarted,
		isLiveActive,
		isLiveAlmostTerminated,
		isLiveEnabled,
		isLivePlanned,
		isLiveStopped,
		isStudioCancelled,
		isStudioPending,
		isStudioPlanned,
		isStudioPublic,
		isStudioRunning,
		isStudioStopped,
	]);

	return (
		<StudioStatusContext.Provider value={value}>
			{children}
		</StudioStatusContext.Provider>
	);
};
