import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { Container } from 'reactstrap';
import { useEnterOrganization, useFetchOrganizations } from '../../api-hooks/organization/organization';
import { ButtonPill } from '../Button';
import { LoginStepOrganizationChallenge } from '../Login/StepOrganizationChallenge';
import { ModalScreen } from '../Modal/Screen/Screen';

export const OrganizationSwitchModal = ({ isOpen, closeModal }) => {
	const { data: organizations } = useFetchOrganizations();
	const { mutate: enterOrganization } = useEnterOrganization();

	const handleSubmitStepOrganizationChallenge = useCallback(async (organizationId) => {
		enterOrganization(organizationId);
	}, [enterOrganization]);

	return (
		<ModalScreen
			bodyClassName="py-0"
			isOpen={isOpen}
			onClose={closeModal}
			size="lg"
		>
			<Container className="d-flex flex-column align-items-center p-4">
				<LoginStepOrganizationChallenge
					availableOptions={organizations}
					onSubmit={handleSubmitStepOrganizationChallenge}
					excludeCurrentOrganization
				/>
				<ButtonPill
					color="secondary"
					className="w-25 mt-4"
					onClick={closeModal}
				>
					Close
				</ButtonPill>
			</Container>
		</ModalScreen>
	);
};

OrganizationSwitchModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeModal: PropTypes.func,
};

OrganizationSwitchModal.defaultProps = {
	closeModal: undefined,
};
