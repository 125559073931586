import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getFileUrl, THUMBNAIL_WIDTH, COVER_SIZE } from '../../../lib/file';

import Hexagon from '../../Hexagon/Hexagon';
import defaultAvatar from '../../../images/default-avatar.png';

import './Cover.scss';
import { ChannelCoverAvatarModal } from './Avatar/Modal';

const ChannelCover = ({ className, channel, formatCover }) => {
	const { t } = useTranslation();
	const style = {
		backgroundImage: `url(${formatCover ? getFileUrl({ name: channel.cover }, COVER_SIZE.width) : channel.cover})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	};
	const { avatar } = channel;
	const userAvatar = avatar ? getFileUrl({ name: avatar }, THUMBNAIL_WIDTH)
		: defaultAvatar;

	const [magnifyAvatar, setMagnifyAvatar] = useState(false);

	const handleCloseAvatarCoverModal = useCallback(() => {
		setMagnifyAvatar(false);
	}, []);

	return (
		<>
			<section className={clsx('ChannelCover bg-dark w-100', className)} style={style}>
				<div className="ChannelCover_overlay d-flex flex-column align-items-center justify-content-center ">
					<Hexagon onClick={() => setMagnifyAvatar(true)} className="ChannelCover_overlay_avatar d-flex align-items-center cursor-pointer justify-content-center">
						<img alt={t('Cover.Cover.avatar')} className="img-fit-container" src={userAvatar} />
					</Hexagon>
					<h1 className="ChannelCover_overlay_title">{channel.nickname}</h1>
				</div>
			</section>
			{magnifyAvatar && (
				<ChannelCoverAvatarModal
					avatar={userAvatar}
					isOpen={magnifyAvatar}
					closeModal={handleCloseAvatarCoverModal}
				/>
			) }
		</>
	);
};

ChannelCover.propTypes = {
	channel: PropTypes.shape({
		avatar: PropTypes.string,
		cover: PropTypes.string,
		nickname: PropTypes.string,
	}),
	className: PropTypes.string,
	formatCover: PropTypes.bool,
};

ChannelCover.defaultProps = {
	channel: {},
	className: '',
	formatCover: true,
};

export default ChannelCover;
