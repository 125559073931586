// @ts-check

import React, { useCallback, useMemo, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { AdContext } from './Context';

export const DEFAULT_AD_DURATION = 30 * 1000;

/** @typedef {import('./Context').AdInfo} AdInfo */

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} AdProviderProps
 */

export const AdProvider = (
	/** @type {AdProviderProps} */
	{ children },
) => {
	const [adDuration, setAdDuration] = useState(0);
	const [isAdPlaying, setIsAdPlaying] = useState(false);
	const [isAdActive, setIsAdActive] = useState(false);
	const [chipsEarnedBySeconds, setChipsEarnedBySeconds] = useState(1);
	const [currentAdsTimeElapsed, setCurrentAdsTimeElapsed] = useState({});

	const adInfoRef = useRef({});

	const handleEventAdLaunch = useCallback((
		/** @type {{ adInfo: AdInfo }} */{ adInfo },
	) => {
		setAdDuration(adInfo.duration);
		setIsAdActive(true);
		adInfoRef.current = adInfo;
	}, []);

	const handleAdEnded = useCallback(() => {
		setAdDuration(0);
		setIsAdPlaying(false);
		setIsAdActive(false);
		setCurrentAdsTimeElapsed({});
		adInfoRef.current = {};
	}, []);

	const estimatedCurrentAdReward = useMemo(
		() => Math.floor(Object.values(currentAdsTimeElapsed)
			.reduce((acc, cur) => acc + cur, 0)) * chipsEarnedBySeconds,
		[chipsEarnedBySeconds, currentAdsTimeElapsed],
	);

	const value = useMemo(() => ({
		adDuration,
		adInfo: adInfoRef.current,
		currentAdsTimeElapsed,
		estimatedCurrentAdReward,
		handleAdEnded,
		handleEventAdLaunch,
		isAdActive,
		isAdPlaying,
		setChipsEarnedBySeconds,
		setCurrentAdsTimeElapsed,
		setIsAdPlaying,
	}), [
		adDuration,
		adInfoRef,
		currentAdsTimeElapsed,
		estimatedCurrentAdReward,
		handleAdEnded,
		handleEventAdLaunch,
		isAdActive,
		isAdPlaying,
		setChipsEarnedBySeconds,
		setCurrentAdsTimeElapsed,
		setIsAdPlaying,
	]);

	return (
		<AdContext.Provider value={value}>
			{children}
		</AdContext.Provider>
	);
};

AdProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
