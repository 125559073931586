import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useAsync } from 'react-async-hook';

import { ShareTabUsers } from './Users';
import { getStudioParticipants } from '../../../api/studio/studio';

export const ShareTabStudioParticipants = ({ studio, ...props }) => {
	const [suggestions, setSuggestions] = useState([]);
	const [searchValue, setSearchValue] = useState('');

	const { loading } = useAsync(async () => {
		const { data } = await getStudioParticipants(studio._id);

		setSuggestions(data);
	}, []);

	const filteredSuggestions = useMemo(
		() => (searchValue
			? suggestions
				.filter((suggestion) => suggestion.label.toLowerCase().includes(searchValue.toLowerCase()))
			: suggestions),
		[suggestions, searchValue],
	);

	return (
		<ShareTabUsers
			isLoading={loading}
			suggestions={filteredSuggestions}
			setSuggestionsSearchValue={setSearchValue}
			{...props}
		/>
	);
};

ShareTabStudioParticipants.propTypes = {
	studio: PropTypes.shape({
		_id: PropTypes.string.isRequired,
	}).isRequired,
};
