import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Row } from 'reactstrap';
import { FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../../../../../Form/Label';
import { StudioScheduleSettingsFormStartTimePicker } from '../../../../../../StudioSchedule/SettingsForm/StartTimePicker';
import { formatDate } from '../../../../../../../lib/time';

export const AdvertisingCampaignsCreateDates = ({ adCampaign, setAdCampaign, isViewOnly }) => {
	const { t } = useTranslation();
	const [showDate, setShowDate] = useState({
		startDate: false,
		endDate: false,
	});

	const campaignStartDate = useMemo(() => (
		adCampaign.startDate ? new Date(adCampaign.startDate) : new Date()
	), [adCampaign]);

	const campaignEndDate = useMemo(() => (
		adCampaign.endDate ? new Date(adCampaign.endDate) : new Date()
	), [adCampaign]);

	const toggleShowDate = useCallback((name) => {
		setShowDate((prevShow) => ({
			...prevShow,
			[name]: !prevShow[name],
		}));
	}, []);

	const handleChangeDate = useCallback((value, name) => {
		if (name === 'endDate' && value < new Date(adCampaign.startDate)) {
			return;
		}

		setAdCampaign((prevAdCampaign) => ({
			...prevAdCampaign,
			[name]: value.toISOString(),
		}));

		setShowDate((prevShow) => ({
			...prevShow,
			[name]: false,
		}));
	}, [adCampaign, setAdCampaign]);

	return (
		<Row>
			<Col>
				{!isViewOnly ? (
					<FormGroup>
						<FormLabel>Start date</FormLabel>
						<StudioScheduleSettingsFormStartTimePicker
							startTime={campaignStartDate}
							onChange={(value) => handleChangeDate(value, 'startDate')}
							open={showDate.startDate}
							toggle={() => toggleShowDate('startDate')}
							calendarName="openStartDate"
						/>
						<FieldError name="startDate" />
					</FormGroup>
				) : (
					<div>
						<FormLabel>Start date</FormLabel>
						<p>{formatDate(adCampaign.startDate, t)}</p>
					</div>
				)}
			</Col>
			<Col>
				{!isViewOnly ? (
					<FormGroup>
						<FormLabel>End date</FormLabel>
						<StudioScheduleSettingsFormStartTimePicker
							startTime={campaignEndDate}
							onChange={(value) => handleChangeDate(value, 'endDate')}
							open={showDate.endDate}
							toggle={() => toggleShowDate('endDate')}
							calendarName="openEndDate"
						/>
						<FieldError name="endDate" />
					</FormGroup>
				) : (
					<div>
						<FormLabel>End date</FormLabel>
						<p>{formatDate(adCampaign.endDate, t)}</p>
					</div>
				)}
			</Col>
		</Row>
	);
};

AdvertisingCampaignsCreateDates.propTypes = {
	adCampaign: PropTypes.shape({
		startDate: PropTypes.string,
		endDate: PropTypes.string,
	}).isRequired,
	setAdCampaign: PropTypes.func.isRequired,
	isViewOnly: PropTypes.bool,
};

AdvertisingCampaignsCreateDates.defaultProps = {
	isViewOnly: false,
};
