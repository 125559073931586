import React from 'react';
import PropTypes from 'prop-types';
import { DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ChannelModeSelect } from './ChannelModeSelect';
import { ChannelAutomaticSwitch } from './ChannelAutomaticSwitch';
import { ReactComponent as ChannelModeSelectIcon } from './ChannelModeSelectIcon.svg';

import './ChannelMode.scss';

export const ChannelModeListening = ({ className, setAllMuted, setHostMuted }) => {
	const { t } = useTranslation();
	return (
		<UncontrolledDropdown className="ChannelModeListening">
			<DropdownToggle
				color={clsx(className)}
				title={t('ChannelMode.ChannelMode.defineWhoCanHear')}
			>
				<ChannelModeSelectIcon width="20" height="20" />
			</DropdownToggle>
			<DropdownMenu className="shadow-none">
				<strong className="dropdown-header content-darker">{t('ChannelMode.ChannelMode.listening')}</strong>
				<ChannelModeSelect setAllMuted={setAllMuted} setHostMuted={setHostMuted} />
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

ChannelModeListening.propTypes = {
	className: PropTypes.string,
	setAllMuted: PropTypes.func.isRequired,
	setHostMuted: PropTypes.func.isRequired,
};

ChannelModeListening.defaultProps = {
	className: undefined,
};

export const ChannelModeSpeakerSwitch = ({ className }) => (
	<ChannelAutomaticSwitch className={className} />
);

ChannelModeSpeakerSwitch.propTypes = {
	className: PropTypes.string,
};

ChannelModeSpeakerSwitch.defaultProps = {
	className: undefined,
};
