import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { TabContent, TabPane } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { getLanguageKey } from '../../i18n';
import { resetPasswordBegin, resetPasswordConfirm } from '../../api/profile/password';
import { verificationConfirm, verificationResend } from '../../api/verification';
import { useAuthentication } from '../Authentication/Authentication';
import LoginStepForgottenPassword from './StepForgottenPassword';
import LoginStepLogin from './StepLogin';

import { ModalLazyContent } from '../Modal/LazyContent';
import { ModalScreenHeader } from '../Modal/Screen/Header';
import { ModalScreen } from '../Modal/Screen/Screen';
import { IdentificationField } from '../Signup/signup.schema';
import { LoginConfirm } from './Confirm';
import { LoginPassword } from './Password';
import './Modal.scss';

export const clearAccount = (account) => {
	const preparedAccount = { ...account };
	delete preparedAccount.identificationField;

	if (account.identificationField === IdentificationField.EMAIL) {
		delete preparedAccount.phoneNumber;
	} else if (account.identificationField === IdentificationField.PHONE) {
		delete preparedAccount.email;
	}

	return preparedAccount;
};

const LoginModal = ({
	isOpen,
	toggle,
	openOrganizationChallenge,
}) => {
	const [account, setAccount] = useState({});
	const [activeTab, setActiveTab] = useState('stepLogin');
	const [verificationToken, setVerificationToken] = useState();
	const { login } = useAuthentication();
	const { t, i18n: { language } } = useTranslation();

	const handleSubmitStepLogin = useCallback(async (stepLoginData) => {
		setAccount(
			(state) => ({
				...state,
				...stepLoginData,
			}),
		);

		const loginResponse = await login(clearAccount(stepLoginData));
		if (loginResponse.requireOrganizationChallenge) {
			openOrganizationChallenge(loginResponse);
		}
	}, [login, openOrganizationChallenge]);

	const handleSubmitStepForgottenPassword = useCallback(
		async (stepForgottenPasswordData) => {
			setAccount((state) => ({ ...state, ...stepForgottenPasswordData }));
			const result = await resetPasswordBegin(
				clearAccount(stepForgottenPasswordData),
				getLanguageKey(language),
			);
			setVerificationToken(result.data.verification_token);
			setActiveTab('stepForgottenPasswordConfirm');
		},
		[language],
	);

	const handleSubmitStepForgottenPasswordConfirm = useCallback(
		async ({ code }) => {
			await verificationConfirm({ code, verification_token: verificationToken });
			setActiveTab('stepForgottenPasswordReset');
		},
		[verificationToken],
	);

	const handleResendConfirmationCode = useCallback(
		async () => {
			const result = await verificationResend(verificationToken, getLanguageKey(language));
			setVerificationToken(result.data.verification_token);
		},
		[language, verificationToken],
	);

	const handleSubmitStepForgottenPasswordReset = useCallback(
		async (stepForgottenPasswordReset) => {
			await resetPasswordConfirm(
				clearAccount({
					...account,
					...stepForgottenPasswordReset,
				}),
				verificationToken,
			);
			setActiveTab('stepLogin');
		},
		[account, verificationToken],
	);

	const handleBack = useCallback((step, stepData) => {
		if (stepData) {
			setAccount((state) => ({ ...state, ...stepData }));
		}
		setActiveTab(step);
	}, []);

	const handleBackStepConfirm = useCallback(() => {
		handleBack('stepForgottenPassword');
	}, [handleBack]);

	const handleBackStepPassword = useCallback(() => {
		handleBack('stepForgottenPassword');
	}, [handleBack]);

	const handleForgottenPassword = useCallback((stepLoginData) => {
		setAccount((state) => ({ ...state, ...stepLoginData }));
		setActiveTab('stepForgottenPassword');
	}, []);

	const showCloseButton = ['stepLogin', 'stepForgottenPassword'].includes(activeTab);

	const modalTitle = useMemo(() => {
		if (activeTab === 'stepLogin') {
			return {
				title: t('Login.Modal.signIn'),
				subTitle: t('Login.Modal.welcome', { projectName: import.meta.env.VITE_PROJECT === 'beeyou' ? 'Beeyou' : 'Commando Vision' }),
			};
		}
		if (activeTab === 'stepForgottenPassword') {
			return {
				title: t('Login.Modal.forgotYourPassword'),
				subTitle: account.identificationField === IdentificationField.EMAIL
					? t('Login.Modal.forgotYourPasswordMessage.email')
					: t('Login.Modal.forgotYourPasswordMessage.phoneNumber'),
			};
		} if (activeTab === 'stepForgottenPasswordConfirm') {
			return {
				title: account.identificationField === IdentificationField.EMAIL
					? t('Login.Modal.confirmYourEmail')
					: t('Login.Modal.confirmYourPhoneNumber'),
				subTitle: t('Login.Modal.enterConfirmationCode'),
			};
		} if (activeTab === 'stepForgottenPasswordReset') {
			return {
				title: t('Login.Modal.chooseANewPassword'),
				subTitle: t('Login.Modal.chooseANewPasswordSubtitle'),
			};
		}

		return {
			title: '',
			subTitle: '',
		};
	}, [account.identificationField, activeTab, t]);

	return (
		<ModalScreen
			bodyClassName="py-0 pb-5 bg-light"
			contentClassName="border-0 shadow-lg rounded overflow-scroll m-auto"
			isOpen={isOpen}
			onClose={showCloseButton ? toggle : undefined}
			size="lg"
			header={(
				<ModalScreenHeader
					onClose={showCloseButton ? toggle : undefined}
					title={modalTitle.title}
					subTitle={modalTitle.subTitle}
					emphasisOnTitle
					noBorder
				/>
			)}
		>
			<TabContent activeTab={activeTab}>
				<ModalLazyContent>
					<TabPane tabId="stepLogin" className="h-100 px-md-5">
						<LoginStepLogin
							initialState={account}
							onForgottenPassword={handleForgottenPassword}
							onSubmit={handleSubmitStepLogin}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPassword" className="h-100 standardTabs">
						<LoginStepForgottenPassword
							initialState={account}
							onBack={handleBack}
							onSubmit={handleSubmitStepForgottenPassword}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPasswordConfirm" className="h-100 standardTabs">
						<LoginConfirm
							initialState={account}
							onBack={handleBackStepConfirm}
							onResend={handleResendConfirmationCode}
							onSubmit={handleSubmitStepForgottenPasswordConfirm}
						/>
					</TabPane>
					<TabPane tabId="stepForgottenPasswordReset" className="h-100 standardTabs">
						<LoginPassword
							initialState={account}
							onBack={handleBackStepPassword}
							onSubmit={handleSubmitStepForgottenPasswordReset}
						/>
					</TabPane>
				</ModalLazyContent>
			</TabContent>
		</ModalScreen>
	);
};

LoginModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	toggle: PropTypes.func.isRequired,
	openOrganizationChallenge: PropTypes.func.isRequired,
};

export default React.memo(LoginModal);
