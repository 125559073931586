import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
	Button,
	Col,
	Modal,
	Row,
} from 'reactstrap';
import { FaTimes } from 'react-icons/fa';

const FormModal = ({
	children,
	contentClassName,
	isOpen,
	showCloseButton,
	toggle,
}) => {
	if (!isOpen) return null;
	return (
		<Modal
			backdrop="static"
			centered
			contentClassName={clsx('border-0 shadow-none rounded overflow-hidden bg-transparent', contentClassName)}
			isOpen={isOpen}
			size="xl"
			toggle={toggle}
			zIndex={2000}
		>
			<div className="h-100">
				<Row className="g-0 h-100 w-100 bg-transparent">
					<Col xs="12" lg="8" className="d-flex h-100 mx-auto rounded overflow-hidden position-relative">
						<div className="bg-darker content-darker w-100 p-5 h-100 shadow-lg">
							{children}
						</div>
						{showCloseButton && (
							<Button
								color="neutral-secondary"
								className="position-absolute card-badges shadow btn-pill d-30 p-0 m-2"
								onClick={toggle}
							>
								<span className="btn-wrapper--icon">
									<FaTimes />
								</span>
							</Button>
						)}
					</Col>
				</Row>
			</div>
		</Modal>
	);
};

FormModal.propTypes = {
	children: PropTypes.node.isRequired,
	contentClassName: PropTypes.string,
	isOpen: PropTypes.bool,
	showCloseButton: PropTypes.bool,
	toggle: PropTypes.func.isRequired,
};

FormModal.defaultProps = {
	contentClassName: '',
	showCloseButton: true,
	isOpen: false,
};

export default React.memo(FormModal);
