import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { StudioSettingsContext } from './Context';

const defaultValue = {
	value:
	{
		label: 'Default',
		leftMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: 0,
		},
		rightMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: 0,
		},
		midRightMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: 0,
		},
		bottomRightMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: -350,
		},
		bottomMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 200,
			y: 200,
		},
		midLeftMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: 0,
		},
		bottomLeftMenu:
		{
			favoriteTab: '',
			isOpen: false,
			isPinned: false,
			isCollapsed: false,
			x: 0,
			y: -350,
		},
	},
};

export const StudioSettingsProvider = ({ children }) => {
	const savedValue = useMemo(() => (
		localStorage.getItem('studioSettings') ? { value: JSON.parse(localStorage.getItem('studioSettings')) } : defaultValue
	), []);
	const [studioSettings, setStudioSettings] = useState(savedValue);

	const context = useMemo(() => ({
		handleChangeStudioSettings: (data) => {
			setStudioSettings({
				value: {
					...data,
				},
			});
			localStorage.setItem('studioSettings', JSON.stringify(data));
		},
		handleChangeLeftMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					leftMenu: {
						...prev.value.leftMenu,
						...data,
					},
				},
			}));
		},
		handleChangeRightMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					rightMenu: {
						...prev.value.rightMenu,
						...data,
					},
				},
			}));
		},
		handleChangeMidRightMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					midRightMenu: {
						...prev.value.midRightMenu,
						...data,
					},
				},
			}));
		},
		handleChangeBottomRightMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					bottomRightMenu: {
						...prev.value.bottomRightMenu,
						...data,
					},
				},
			}));
		},
		handleChangeBottomLeftMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					bottomLeftMenu: {
						...prev.value.bottomLeftMenu,
						...data,
					},
				},
			}));
		},
		handleChangeMidLeftMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					midLeftMenu: {
						...prev.value.midLeftMenu,
						...data,
					},
				},
			}));
		},
		handleChangeBottomMenu: (data) => {
			setStudioSettings((prev) => ({
				...prev,
				value: {
					...prev.value,
					bottomMenu: {
						...prev.value.bottomMenu,
						...data,
					},
				},
			}));
		},
		studioSettings,
		defaultValue,
	}), [studioSettings]);

	return (
		<StudioSettingsContext.Provider value={context}>
			{children}
		</StudioSettingsContext.Provider>
	);
};

StudioSettingsProvider.propTypes = {
	children: PropTypes.node,
};

StudioSettingsProvider.defaultProps = {
	children: undefined,
};
