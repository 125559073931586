import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { usePreview } from '@technomiam/react-video';

import Player from '../../../Player/Player';

import './LocalCamPreview.scss';

export const LocalCamPreview = ({
	className,
	useKeyOrUserMediaStreamOnly,
}) => {
	const { previewMediastrearm } = usePreview(useKeyOrUserMediaStreamOnly);

	return previewMediastrearm && (
		<Player
			className={clsx('LocalCamPreview bg-light', className)}
			autoPlay
			muted
			src={previewMediastrearm}
		/>
	);
};

LocalCamPreview.propTypes = {
	className: PropTypes.string,
	useKeyOrUserMediaStreamOnly: PropTypes.bool,
};

LocalCamPreview.defaultProps = {
	className: '',
	useKeyOrUserMediaStreamOnly: false,
};
