import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import { useTranslation } from 'react-i18next';
import { FormLabel } from '../../../../../../Form/Label';
import { CampaignCreateTooltip } from './Tooltip';
import { AdAgeGroup, AdAudienceInterest, AdGender, AdLanguage, AdSharedProps, AdTimeSlot, AdLanguageLabel } from '../../createAds.schema';
import { AdvertisingCampaignsCreateDates } from './Dates';
import { AdvertisingCampaignsCreateCountries } from './Countries';
import { AdvertisingCampaignsCreateViewes } from './Views';

export const AdSharedPropsLabel = {
	[AdSharedProps.ANY]: 'Any',
};

export const AdGenderLabel = {
	...AdSharedPropsLabel,
	[AdGender.MALE]: 'Male',
	[AdGender.FEMALE]: 'Female',
	[AdGender.LGBTQ_PLUS]: 'LQBTQ+',
	[AdGender.NOT_DISCLOSED]: 'Not disclosed',
};

export const AdAgeGroupLabel = {
	...AdSharedPropsLabel,
	[AdAgeGroup.AGE_13_17]: '13 - 17',
	[AdAgeGroup.AGE_18_24]: '18 - 24',
	[AdAgeGroup.AGE_25_34]: '25 - 34',
	[AdAgeGroup.AGE_35_44]: '35 - 44',
	[AdAgeGroup.AGE_45_54]: '45 - 54',
	[AdAgeGroup.AGE_55_64]: '55 - 64',
	[AdAgeGroup.AGE_65_PLUS]: '65+',
};

export const AdAudienceInterestLabel = {
	...AdSharedPropsLabel,
	[AdAudienceInterest.TECHNOLOGY]: 'Technology',
	[AdAudienceInterest.EDUCATION]: 'Education',
	[AdAudienceInterest.GAMING]: 'Gaming',
	[AdAudienceInterest.SPORT]: 'Sport',
};

export const AdTimeSlotLabel = {
	...AdSharedPropsLabel,
	[AdTimeSlot.MORNING]: 'Morning',
	[AdTimeSlot.AFTERNOON]: 'Afternoon',
	[AdTimeSlot.EVENING]: 'Evening',
};

export const AdvertisingCampaignsCreate = ({ adCampaign, setAdCampaign, isViewOnly }) => {
	const { t } = useTranslation();

	const handleToggleCampaignArrayProp = useCallback((propName, value) => {
		setAdCampaign((prevAdCampaign) => {
			const removeValueFromArray = prevAdCampaign[propName].includes(value);

			// eslint-disable-next-line no-nested-ternary
			const newPropArray = removeValueFromArray && prevAdCampaign[propName].length === 1
				? ['ANY']
				: removeValueFromArray
					? prevAdCampaign[propName].filter((item) => item !== value)
					: [...prevAdCampaign[propName], value].filter((item) => item !== 'ANY');

			return {
				...prevAdCampaign,
				[propName]: value !== 'ANY'
					? newPropArray
					: ['ANY'],
			};
		});
	}, [setAdCampaign]);

	return (
		<>
			<h4 className="my-4">{t('AdCampaings.Ad.campaignCriteria')}</h4>
			<Row>
				<Col>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.campaignName')}</FormLabel>
						<Field
							component={Input}
							type="text"
							name="name"
							value={adCampaign.name}
							placeholder={t('AdCampaings.Ad.enterCampaignName')}
							disabled={isViewOnly}
						/>
						<FieldError name="name" />
					</FormGroup>
					<AdvertisingCampaignsCreateViewes
						adCampaign={adCampaign}
						setAdCampaign={setAdCampaign}
						isViewOnly={isViewOnly}
					/>
					<AdvertisingCampaignsCreateDates
						adCampaign={adCampaign}
						setAdCampaign={setAdCampaign}
						isViewOnly={isViewOnly}
					/>
					<div className="d-flex">
						<div className="w-50">
							<Label id="TimeSlotsOptions" className="mt-3" for="currency">{t('AdCampaings.Ad.timeSlot')} *</Label>
							<CampaignCreateTooltip
								target="TimeSlotsTooltip"
								container="TimeSlotsOptions"
								text={
									adCampaign.timeSlots.includes(AdSharedProps.ANY)
										? t('AdCampaings.Ad.noAdditionalChargeTimeSlot')
										: `You will be charged ${(10 / adCampaign.timeSlots.length).toFixed(2)}% more, for selecting ${adCampaign.timeSlots.length} specific time slots`
								}
							/>
							{Object.keys(AdTimeSlot).map((timeSlotKey) => (
								<FormGroup key={`timeSlot${timeSlotKey}`} className="ml-2" check>
									<Field
										component={Input}
										type="checkbox"
										name={`timeSlot${timeSlotKey}`}
										id={`timeSlot${timeSlotKey}`}
										value={AdTimeSlot[timeSlotKey]}
										onChange={(e) => {
											handleToggleCampaignArrayProp('timeSlots', e.target.value);
										}}
										checked={adCampaign.timeSlots.includes(AdTimeSlot[timeSlotKey])}
										disabled={isViewOnly}
									/>
									<Label check for={`timeSlot${timeSlotKey}`} className="blue">
										{ AdTimeSlotLabel[timeSlotKey] }
									</Label>
								</FormGroup>
							))}
							<FieldError name="timeSlots" />
						</div>
						<div className="w-50">
							<Label id="GenderOptions" className="mt-3" for="currency">{t('AdCampaings.Ad.gender')} *</Label>
							<CampaignCreateTooltip
								target="GenderTooltip"
								container="GenderOptions"
								text={
									adCampaign.genders.includes(AdSharedProps.ANY)
										? t('AdCampaings.Ad.noAdditionalChargeGender')
										: `You will be charged ${(10 / adCampaign.genders.length).toFixed(2)}% more, for selecting ${adCampaign.genders.length} specific gender`
								}
							/>
							{Object.keys(AdGender).map((genderKey) => (
								<FormGroup key={`gender${genderKey}`} className="ml-2" check>
									<Field
										component={Input}
										type="checkbox"
										name={`gender${genderKey}`}
										id={`gender${genderKey}`}
										value={AdGender[genderKey]}
										onChange={(e) => {
											handleToggleCampaignArrayProp('genders', e.target.value);
										}}
										checked={adCampaign.genders.includes(AdGender[genderKey])}
										disabled={isViewOnly}
									/>
									<Label check for={`gender${genderKey}`} className="blue">
										{ AdGenderLabel[genderKey] }
									</Label>
								</FormGroup>
							))}
							<FieldError name="genders" />
						</div>
					</div>
				</Col>
				<Col>
					<FormGroup>
						<FormLabel>{t('AdCampaings.Ad.language')} *</FormLabel>
						<Field
							component={Input}
							type="select"
							name="language"
							value={adCampaign.language}
						>
							{Object.keys(AdLanguage).map((adLanguageKey) => (
								<option key={`language-option-${adLanguageKey}`} value={AdLanguage[adLanguageKey]}>
									{AdLanguageLabel[adLanguageKey]}
								</option>
							))}
						</Field>
						<FieldError name="language" />
					</FormGroup>
					<Label id="AgeGroupsOptions" className="mt-3" for="currency">{t('AdCampaings.Ad.ageGroups')} *</Label>
					<CampaignCreateTooltip
						target="AgeGroupsTooltip"
						container="AgeGroupsOptions"
						text={
							adCampaign.ageGroups.includes(AdSharedProps.ANY)
								? t('AdCampaings.Ad.noAdditionalChargeAgeGroup')
								: `You will be charged ${(10 / adCampaign.ageGroups.length).toFixed(2)}% more, for selecting ${adCampaign.ageGroups.length} specific age group`
						}
					/>
					{Object.keys(AdAgeGroup).map((ageGroupKey) => (
						<FormGroup key={`ageGroup${ageGroupKey}`} className="ml-2" check>
							<Field
								component={Input}
								type="checkbox"
								name={`ageGroup${ageGroupKey}`}
								id={`ageGroup${ageGroupKey}`}
								value={AdAgeGroup[ageGroupKey]}
								onChange={(e) => {
									handleToggleCampaignArrayProp('ageGroups', e.target.value);
								}}
								checked={adCampaign.ageGroups.includes(AdAgeGroup[ageGroupKey])}
								disabled={isViewOnly}
							/>
							<Label check for={`ageGroup${ageGroupKey}`} className="blue">
								{ AdAgeGroupLabel[ageGroupKey] }
							</Label>
						</FormGroup>
					))}
					<FieldError name="ageGroups" />
					<Label id="AudienceInterestsOptions" className="mt-3" for="currency">Audience Interests *</Label>
					<CampaignCreateTooltip
						target="AudienceInterestsTooltip"
						container="AudienceInterestsOptions"
						text={
							adCampaign.interests.includes(AdSharedProps.ANY)
								? t('AdCampaings.Ad.noAdditionalChargeInterest')
								: `You will be charged ${(10 / adCampaign.interests.length).toFixed(2)}% more, for selecting ${adCampaign.interests.length} specific interest`
						}
					/>
					{Object.keys(AdAudienceInterest).map((audienceInterestKey) => (
						<FormGroup key={`audienceInterest${audienceInterestKey}`} className="ml-2" check>
							<Field
								component={Input}
								type="checkbox"
								name={`audienceInterest${audienceInterestKey}`}
								id={`audienceInterest${audienceInterestKey}`}
								value={AdAudienceInterest[audienceInterestKey]}
								onChange={(e) => {
									handleToggleCampaignArrayProp('interests', e.target.value);
								}}
								checked={
									adCampaign.interests.includes(AdAudienceInterest[audienceInterestKey])
								}
								disabled={isViewOnly}
							/>
							<Label check for={`audienceInterest${audienceInterestKey}`} className="blue">
								{ AdAudienceInterestLabel[audienceInterestKey] }
							</Label>
						</FormGroup>
					))}
					<FieldError name="interests" />
					<AdvertisingCampaignsCreateCountries
						adCampaign={adCampaign}
						setAdCampaign={setAdCampaign}
						isViewOnly={isViewOnly}
					/>
				</Col>
			</Row>
		</>
	);
};

AdvertisingCampaignsCreate.propTypes = {
	adCampaign: PropTypes.shape({
		name: PropTypes.string,
		interests: PropTypes.arrayOf(PropTypes.string),
		ageGroups: PropTypes.arrayOf(PropTypes.string),
		genders: PropTypes.arrayOf(PropTypes.string),
		timeSlots: PropTypes.arrayOf(PropTypes.string),
		ads: PropTypes.arrayOf(PropTypes.shape({
			views: PropTypes.number,
		})),
		language: PropTypes.oneOf(Object.values(AdLanguage)).isRequired,
	}),
	setAdCampaign: PropTypes.func,
	isViewOnly: PropTypes.bool,
};

AdvertisingCampaignsCreate.defaultProps = {
	adCampaign: undefined,
	setAdCampaign: undefined,
	isViewOnly: false,
};
