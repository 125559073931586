import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export const InputUnverifiedBadge = () => {
	const { t } = useTranslation();

	return (
		<div>
			<FaExclamationCircle className="text-danger mr-1" />
			{t('Input.UnverifiedBadge.unverified')}
		</div>
	);
};
