import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldError } from 'react-jsonschema-form-validation';
import 'react-phone-number-input/style.css';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { FormLabel } from '../../Form/Label';

export const SignupFormCreateOrganization = ({ signupData }) => {
	const { t } = useTranslation();

	return (
		<Row>
			<Col>
				<FormGroup>
					<FormLabel>
						{t('Onboarding.Signup.organizationName')}
					</FormLabel>
					<Field
						component={Input}
						name="organizationName"
						type="text"
						placeholder={t('Onboarding.Signup.organizationName.placeholder')}
						value={signupData.organizationName || ''}
					/>
					<FieldError name="organizationName" />
				</FormGroup>
			</Col>
		</Row>
	);
};

SignupFormCreateOrganization.propTypes = {
	signupData: PropTypes.shape({
		organizationName: PropTypes.string,
		accountType: PropTypes.string,
	}),
};

SignupFormCreateOrganization.defaultProps = {
	signupData: {
		organizationName: '',
		accountType: '',
	},
};
