import { useGoogleLogin } from '@react-oauth/google';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GoogleButtonIcon from '../../assets/images/btn_google.svg';

import './ContinueWithGoogle.scss';

export const ContinueWithGoogle = ({ handleSubmit, disabled }) => {
	const { t } = useTranslation();

	const handleGoogleLogin = useGoogleLogin({
		onSuccess: (codeResponse) => handleSubmit({ code: codeResponse.code }),
		flow: 'auth-code',
	});

	return (
		<button disabled={disabled} className="google-login-button px-5 py-3 w-xl-100 w-md-50" type="button" onClick={handleGoogleLogin}>
			<img src={GoogleButtonIcon} alt="google icon" /> {t('Login.google.button')}
		</button>
	);
};

ContinueWithGoogle.propTypes = {
	handleSubmit: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
};

ContinueWithGoogle.defaultProps = {
	disabled: false,
};
