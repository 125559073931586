import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';
import { useProfile } from '../../../components/Profile/ProfileContext';
import BuyPointsSuccess from '../../BuyPoints/BuyPointsSuccess';
import SubscriptionCompleted from '../../SubscriptionCompleted/SubscriptionCompleted';
import { OrderBundleType, PaypalOrderButton } from './Order/Button';
import { PaypalSubscriptionButton } from './Subscription/Button';
import { GRAPH_GLOBALS_QUERY_KEYS } from '../../../api-hooks/graph/globals';

export const PaypalCheckout = (
	{ plan, amount, orderBundleType, channelId, includeDonationFee },
) => {
	const [pointsCheckoutComplete, setPointsCheckoutComplete] = useState(false);
	const [subscriptionCheckoutComplete, setSubscriptionCheckoutComplete] = useState(false);

	const { fetchProfile, profile } = useProfile();
	const queryClient = useQueryClient();

	const onSubscriptionComplete = useCallback(() => {
		fetchProfile();
		setSubscriptionCheckoutComplete(true);
	}, [fetchProfile]);

	const handleCheckoutSuccess = useCallback(() => {
		setPointsCheckoutComplete(true);
		queryClient.invalidateQueries(GRAPH_GLOBALS_QUERY_KEYS.fetchGraphGlobals(profile?._id));
	}, [queryClient, profile]);

	if (subscriptionCheckoutComplete) {
		return <SubscriptionCompleted />;
	}

	if (pointsCheckoutComplete) {
		return (
			<BuyPointsSuccess
				amount={amount}
				orderBundleType={orderBundleType}
			/>
		);
	}

	return plan ? (
		<PaypalSubscriptionButton
			plan={plan}
			onSubscriptionComplete={onSubscriptionComplete}
		/>
	) : (
		<PaypalOrderButton
			amount={amount}
			onOrderPaymentComplete={handleCheckoutSuccess}
			orderBundleType={orderBundleType}
			channelId={channelId}
			includeDonationFee={includeDonationFee}
		/>
	);
};

PaypalCheckout.propTypes = {
	plan: PropTypes.string,
	amount: PropTypes.number,
	orderBundleType: PropTypes.string,
	channelId: PropTypes.string,
	includeDonationFee: PropTypes.bool,
};

PaypalCheckout.defaultProps = {
	plan: '',
	amount: 0,
	orderBundleType: OrderBundleType.PI_POINTS,
	channelId: undefined,
	includeDonationFee: false,
};
