import React from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ModalScreen } from '../../Modal/Screen/Screen';
import { ModalScreenHeader } from '../../Modal/Screen/Header';
import SettingsLibrarySurveysTab from '../../../views/Controlroom/Settings/Library/Surveys/Tab';
import { ButtonPill } from '../../Button';
import { SurveyModalsModalBody } from './ModalBody';
import { useSurveyTemplate } from '../Providers/SurveyTemplateProvider';
import { PaginationProvider } from '../../Pagination/Provider';
import { ListSortingProvider } from '../../ListSorting/Provider';

export const SurveyListModal = () => {
	const { t } = useTranslation();
	const {
		isInviteOpen,
		handleCloseInvite,
		handleOpenSurveyTemplateModal,
	} = useSurveyTemplate();

	return (
		<ModalScreen
			className="p-0"
			bodyClassName="p-0"
			isOpen={isInviteOpen}
			onClose={handleCloseInvite}
			header={(
				<ModalScreenHeader
					onClose={handleCloseInvite}
					title={t('Modals.SurveyListModal.surveys')}
				>
					<Row className="m-0 p-0 w-100">
						<Col xs={10} className="p-0 m-0" />
						<Col xs={2} className="p-0 m-0">
							<div className="d-flex align-itmes-center justify-content-end w-100">
								<ButtonPill color="primary" className="btn-pill mx-1" onClick={() => { handleOpenSurveyTemplateModal(); }}>
									{t('Modals.SurveyListModal.addNew')}
								</ButtonPill>
							</div>
						</Col>
					</Row>
				</ModalScreenHeader>
			)}
		>
			<SurveyModalsModalBody className="h-100 w-100 px-3 pb-3 d-flex flex-column">
				{isInviteOpen && (
					<PaginationProvider initialItemsPerPage={10}>
						<ListSortingProvider>
							<SettingsLibrarySurveysTab />
						</ListSortingProvider>
					</PaginationProvider>
				)}
			</SurveyModalsModalBody>
		</ModalScreen>
	);
};
