import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FaWrench } from 'react-icons/fa';
import { UncontrolledDropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { AppsTabIcon, AppsTabType, useAppsTab } from '../../../../views/Controlroom/Apps/Provider';
import { ContainersID, useControlroomSidebar } from '../../../../views/Controlroom/Sidebar/Provider';

export const ToolsDropdown = ({ className }) => {
	const { t } = useTranslation();
	const { setTab } = useAppsTab();
	const { onToggleContainer, openedContainers } = useControlroomSidebar();

	const updateAppsTab = useCallback((tab) => {
		setTab(tab);
		if (!openedContainers[ContainersID.FIRST]) onToggleContainer(ContainersID.FIRST);
	}, [onToggleContainer, openedContainers, setTab]);

	return (
		<UncontrolledDropdown className={className} direction="down">
			<DropdownToggle color="neutral-secondary" className="bg-transparent shadow-none p-0 d-30">
				<span className="btn-wrapper--icon">
					<FaWrench />
				</span>
			</DropdownToggle>
			<DropdownMenu className="shadow-none p-0">
				{Object.values(AppsTabType).map((app) => (
					<DropdownItem key={`tools-dropdown-${app}`} onClick={() => updateAppsTab(app)}>
						<span className="btn-wrapper--icon mr-2">
							{AppsTabIcon[app]}
						</span>
						{t(`Controlroom.Apps.${app}`)}
					</DropdownItem>
				))}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

ToolsDropdown.propTypes = {
	className: PropTypes.string,
};

ToolsDropdown.defaultProps = {
	className: '',
};
