import React from 'react';
import clsx from 'clsx';
import { FaCamera, FaDesktop, FaLock, FaMailBulk } from 'react-icons/fa';
import { Col, Container, Row } from 'reactstrap';
import { FaArrowRightLong } from 'react-icons/fa6';
import { useTranslation } from 'react-i18next';
import { ModeScreenLayout, useScreenMode } from '../../components/Studio/ScreenMode/Provider';
import { Header } from '../../layout-components';
import { ButtonPill } from '../../components/Button';
import { Footer } from '../Footer/Footer';
import { useLogin } from '../../components/Login/Provider';
import comvisionLogo from '../../images/cv-logo.png';

import './ComvisionLanding.scss';

export const ViewComvisionHomeLanding = () => {
	const { currentModeScreen } = useScreenMode();
	const { openSignup } = useLogin();
	const { t } = useTranslation();

	return (
		<div className="app-wrapper content-darker">
			<div className={clsx('app-main',
				{ 'overflow-hidden': currentModeScreen === ModeScreenLayout.FULLSCREEN })}
			>
				<Header
					search={false}
					isMobileLayout={false}
					isStudioRoute={false}
				/>
				<div className="app-content">
					<Container fluid className="Hero">
						<Row className="h-100">
							<Col lg={6} className="d-flex align-items-center justify-content-center">
								<div className="HeroLeft">
									<h2 className="font-size-xxxl font-weight-bold">Commando Vision</h2>
									<div className="divider mb-3 bg-primary" />
									<p className="font-size-lg">
										{t('Comvision.Landing.heroText')}
									</p>
									<ButtonPill
										onClick={openSignup}
										size="lg"
									>
										<FaArrowRightLong className="mr-3" />
										{t('Comvision.Landing.getStarted')}
									</ButtonPill>
								</div>
							</Col>
							<Col lg={6} className="HeroRight d-flex align-items-center justify-content-center bg-black">
								<div className="d-flex align-items-center justify-content-center">
									<img width="300px" src={comvisionLogo} />
								</div>
							</Col>
						</Row>
					</Container>
					<Container className="VideoSection bg-white" fluid>
						<Row className="m-auto">
							<Col className="d-flex flex-column justify-content-center align-items-center">
								<h2 className="font-size-xxxl font-weight-bold text-black">{t('Comvision.Landing.overview')}</h2>
								<p className="HeroLeft text-center text-black font-size-lg mb-3">
									{t('Comvision.Landing.overviewText')}
								</p>
								<iframe
									className="mt-5 VideoIframe"
									src="https://www.beeyou.tv/francoislouis/watch/FDIZKm725Q1JVO3hAsqjW/embed?disableSuggestions=true&disableSharing=true"
									frameBorder="0"
									allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
									allowFullScreen
									title="Commando Vision Overview"
								/>
							</Col>
						</Row>
					</Container>
					<Container className="py-5">
						<Row>
							<Col md={4} className="py-5">
								<FaCamera size={60} />
								<h4 className="my-3 font-weight-bold">{t('Comvision.Landing.instantVideoFeeds')}</h4>
								<p>
									{t('Comvision.Landing.instantVideoFeedsText')}
								</p>
							</Col>
							<Col md={4} className="py-5">
								<FaLock size={60} />
								<h4 className="my-3 font-weight-bold">{t('Comvision.Landing.highSecurity')}</h4>
								<p>
									{t('Comvision.Landing.highSecurityText')}
								</p>
							</Col>
							<Col md={4} className="py-5">
								<FaDesktop size={60} />
								<h4 className="my-3 font-weight-bold">{t('Comvision.Landing.userFriendlyInterface')}</h4>
								<p>
									{t('Comvision.Landing.userFriendlyInterfaceText')}
								</p>
							</Col>
						</Row>
					</Container>
					<Container className="py-5 " fluid>
						<div className="divider bg-secondary opacity-25 mb-5 mx-4" />
						<Row className="w-100 h-100 m-auto">
							<Col className="d-flex flex-column justify-content-center align-items-center">
								<h2 className="font-size-xxxl font-weight-bold">{t('Comvision.Landing.contact')}</h2>
								<div className="divider mb-3 bg-primary" />
								<p className="font-size-lg">{t('Comvision.Landing.contactInfo')}</p>
								<a className="btn btn-pill btn-primary font-weight-bold py-3 px-4" href="mailto:info@commandovision.com">
									<FaMailBulk className="mr-3" />
									info@commandovision.com
								</a>
							</Col>
						</Row>
					</Container>
					<Footer />
				</div>
			</div>
		</div>
	);
};
