import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, FormGroup, Input, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AdvertisingDashboardCampaign } from './Campaign/Campaign';
import { AdvertisingDashboardDemographics } from './Demographics/Demographics';
import { useFetchAdCampaignById, useFetchAdCampaigns } from '../../../../../api-hooks/ads/campaign';
import { AdvertisingDashboardGoals } from './Goals.js/Goals';
import { ButtonPill } from '../../../../Button';
import { AdvertisingDashboardProgress } from './Progress/Progress';
import { AdCampaignStatus } from '../../../../../lib/AdCampaignStatus';

export const AdvertisingDashboard = ({ onCreateAdCampaign, adCampaignId }) => {
	const [selectedAdCampaignId, setSelectedAdCampaignId] = useState();

	const { t } = useTranslation();

	const {
		data: adCampaigns,
		isLoading: isAdCampaignsLoading,
		isError: isAdCampaignsError,
	} = useFetchAdCampaigns(AdCampaignStatus.ACTIVE);

	const {
		data: adCampaign,
		isLoading: isAdCampaignLoading,
		isError: isAdCampaignError,
	} = useFetchAdCampaignById(adCampaignId || selectedAdCampaignId);

	const handleChangeCampaign = useCallback((id) => {
		setSelectedAdCampaignId(id);
	}, []);

	useEffect(() => {
		if (!adCampaignId && !selectedAdCampaignId && adCampaigns?.length) {
			setSelectedAdCampaignId(adCampaigns[0]._id);
		}
	}, [adCampaignId, adCampaigns, selectedAdCampaignId]);

	return (
		<div className="AdvertisingCampaigns">
			{isAdCampaignsError && (
				<Alert color="danger">{t('Global.error')}</Alert>
			)}
			{isAdCampaignsLoading && (
				<Spinner />
			)}
			{!!onCreateAdCampaign && !!adCampaigns && !adCampaigns.length && (
				<div>
					<p className="pl-3 mb-4">{t('AdCampaings.Ad.thereAreNoAdCampaings')}</p>
					<ButtonPill
						className="ml-2 mb-1 flex-shrink-0"
						color="primary"
						title="Create Ad Campaign"
						onClick={onCreateAdCampaign}
					>
						{t('AdCampaings.Ad.createAdCampaign')}
					</ButtonPill>
				</div>
			)}
			{!!adCampaigns && !!adCampaigns.length && !adCampaignId && (
				<FormGroup className="mb-3">
					<h5 className="mb-3">
						{t('AdCampaings.Ad.myCurrentCampaign')}
					</h5>
					<Input
						className=""
						type="select"
						onChange={(e) => handleChangeCampaign(e.target.value)}
					>
						{adCampaigns.map((campaign) => (
							<option key={`campaign-${campaign._id}`} value={campaign._id}>{campaign.name}</option>
						))}
					</Input>
				</FormGroup>
			)}
			{isAdCampaignError && (
				<Alert color="danger">{t('AdCampaings.Ad.errorFetchingAdCampaign')}</Alert>
			)}
			{isAdCampaignLoading && (
				<Spinner />
			)}
			{!!adCampaign && (
				<>
					<AdvertisingDashboardCampaign adCampaign={adCampaign} />
					<h5 className="mb-3">{t('AdCampaings.Ad.myCurrentCampaign')}</h5>
					<AdvertisingDashboardDemographics adCampaignId={adCampaignId || selectedAdCampaignId} />
					<h5 className="mb-3">{t('AdCampaings.Ad.adGoals')}</h5>
					<AdvertisingDashboardGoals adCampaign={adCampaign} />
					<h5 className="mb-3">{t('AdCampaings.Ad.adProgress')}</h5>
					<AdvertisingDashboardProgress adCampaignId={adCampaignId || selectedAdCampaignId} />
				</>
			)}
		</div>
	);
};

AdvertisingDashboard.propTypes = {
	onCreateAdCampaign: PropTypes.func,
	adCampaignId: PropTypes.string,
};

AdvertisingDashboard.defaultProps = {
	onCreateAdCampaign: undefined,
	adCampaignId: undefined,
};
