import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { AuctionContext } from './Context';
import { useStudio } from '../Studio/Context';
import { ResourceAccessRole } from '../../lib/ResourceAccessRole';

export const AuctionStatus = {
	ACTIVE: 'ACTIVE',
	FINISHED: 'FINISHED',
};

export const AuctionPosition = {
	TOP_LEFT: 'TOP_LEFT',
	TOP_RIGHT: 'TOP_RIGHT',
	CENTER: 'CENTER',
	BOTTOM_LEFT: 'BOTTOM_LEFT',
	BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};

export const AuctionProvider = ({ children, studioRole }) => {
	const { activeContent, setActiveContent } = useStudio();

	const [activeAuction, setActiveAuction] = useState(null);

	const handleEventAuction = useCallback((newAuction) => {
		if (
			[ResourceAccessRole.OWNER, ResourceAccessRole.OPERATOR].includes(studioRole)
			|| newAuction?.status === AuctionStatus.ACTIVE
		) setActiveAuction(newAuction);
		else setActiveAuction(null);
	}, [studioRole]);

	// Set active auction when joining studio
	useEffect(() => {
		if (activeContent?.activeAuction) {
			handleEventAuction(activeContent.activeAuction);
			setActiveContent((state) => ({
				...state,
				activeAuction: null,
			}));
		}
	}, [activeContent?.activeAuction, handleEventAuction, setActiveContent]);

	const value = useMemo(() => ({
		activeAuction,
		handleEventAuction,
		setActiveAuction,
	}), [
		activeAuction,
		handleEventAuction,
		setActiveAuction,
	]);

	return (
		<AuctionContext.Provider value={value}>
			{children}
		</AuctionContext.Provider>
	);
};

AuctionProvider.propTypes = {
	children: PropTypes.node.isRequired,
	studioRole: PropTypes.oneOf(Object.values(ResourceAccessRole)).isRequired,
};
