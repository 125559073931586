/* eslint-disable react/prop-types */
// @ts-check

import clsx from 'clsx';
import React from 'react';
import { FaRegEye } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Badge, Card, Col } from 'reactstrap';

import { AVATAR_SIZE, getFileUrl } from '../../../lib/file';
import { getWatchLink } from '../../../RoutePath';
import { useLocalizedTimeAgo } from '../../../lib/timeAgo';
import { useValidateImage } from '../../../lib/useValidateImage';
import { VideoThumbnail } from '../../Video/Thumbnail';
import defaultAvatar from '../../../images/default-avatar.png';
import Hexagon from '../../Hexagon/Hexagon';

import './Item.scss';

/**
 * @typedef {{
 * 	channelAvatar?: string?,
 * 	className?: string,
 * }} ChannelAvatarProps
 */

const ChannelAvatar = (
	/** @type {ChannelAvatarProps} */
	{ channelAvatar, className },
) => {
	const userAvatar = channelAvatar
		? getFileUrl({ name: channelAvatar }, AVATAR_SIZE.width)
		: defaultAvatar;
	const validateImage = useValidateImage(defaultAvatar);

	return (
		<Hexagon className={clsx('avatar-icon rounded-0 shadow-none mx-auto', className)}>
			<img className="img-fit-container" alt="avatar" src={userAvatar} onError={validateImage} />
		</Hexagon>
	);
};

/**
 * @typedef {{
 * 	video: import('../../../api/elasticsearch').ESVodResult,
 * 	className?: string,
 * 	columnClasses?: string,
 * 	rowInMobile?: boolean,
 * }} VideoItemProps
 */

export const VideoItem = (
	/** @type {VideoItemProps} */
	{
		className = '',
		columnClasses,
		video,
		rowInMobile = false,
	},
) => {
	const timeAgo = useLocalizedTimeAgo();
	const createdAtFormatted = video.createdAt && timeAgo.format(new Date(video.createdAt), 'round');

	if (!video.channelHashtag || !video.watchLinkId) throw new Error('Missing channelHashtag or watchLinkId');

	return (
		<Col
			sm="6"
			md="6"
			lg="4"
			xl="3"
			xs="12"
			className={clsx(
				'VideoItem',
				className,
				columnClasses,
				{ 'px-4 mb-4': !rowInMobile },
				{ 'px-sm-4 mb-sm-4': rowInMobile },
			)}
		>
			<Link to={getWatchLink(video.channelHashtag, video.watchLinkId)}>
				<Card
					className={clsx(
						'card-transparent card-box-hover-rise rounded overflow-hidden fix-overflow',
						className,
						{ 'd-flex d-sm-block flex-row': rowInMobile },
					)}
				>
					<VideoThumbnail
						absoluteChildren={rowInMobile && (
							<ChannelAvatar className="position-absolute top-0 start-0 d-block d-sm-none" channelAvatar={video.channelAvatar} />
						)}
						className="mx-auto h-100 w-100"
						video={video}
					/>
					<div
						className={clsx(
							'bg-darker content-darker d-flex w-100 align-items-center justify-content-between',
							{ 'flex-column flex-sm-row p-1 p-sm-2 mw-45 mw-sm-100': rowInMobile },
							{ 'p-2': !rowInMobile },
						)}
					>
						<div
							className={clsx(
								'd-flex overflow-hidden',
								{ 'flex-column flex-sm-row mw-100': rowInMobile },
							)}
						>
							<div
								className={clsx(
									'avatar-icon-wrapper',
									{ 'me-0 me-sm-1': rowInMobile },
								)}
							>
								<ChannelAvatar
									channelAvatar={video.channelAvatar}
									className={rowInMobile ? 'd-none d-sm-block' : ''}
								/>
							</div>
							<div className="d-flex flex-column justify-content-center overflow-hidden font-size-sm">
								<strong>{video.title}</strong>
								<span className={rowInMobile ? 'text-truncate' : ''}>
									{video.channelLabel}
								</span>
							</div>
						</div>
						<div
							className={clsx(
								'd-flex align-items-end',
								{ 'flex-column': !rowInMobile },
								{ 'flex-row flex-sm-column': rowInMobile },
							)}
						>
							{video.status === 'ready' && (
								<Badge className="bg-light content-light content-fill-svg" pill>
									<FaRegEye className="mr-1" />
									<span className="content-light">{video.vodViews || 0}</span>
								</Badge>
							)}
							{createdAtFormatted && (
								<div
									className={clsx(
										'font-size-xs content-light text-end',
										{ 'ms-2 ms-sm-0': rowInMobile },
									)}
								>
									{createdAtFormatted}
								</div>
							)}
						</div>
					</div>
				</Card>
			</Link>
		</Col>
	);
};
