import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
	ButtonDropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Spinner,
} from 'reactstrap';

import { getLanguageKey } from '../../i18n';
import {
	DEFlag,
	ESFlag,
	FRFlag,
	ITFlag,
	USFlag,
} from './flags';

import { useUpdateChannelPreferences } from '../../api-hooks/channel/preference';
import { useProfile } from '../Profile/ProfileContext';
import './LanguageSelector.scss';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

const languages = [
	{ text: 'Deutsch', code: 'de', flag: <DEFlag /> },
	{ text: 'English (US)', code: 'en', flag: <USFlag /> },
	{ text: 'Español', code: 'es', flag: <ESFlag /> },
	{ text: 'Italiano', code: 'it', flag: <ITFlag /> },
];

if (isBeeyou) {
	languages.push({ text: 'Français', code: 'fr', flag: <FRFlag /> });
}

export const LanguageSelector = ({
	className,
	minimal,
}) => {
	const { i18n } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const { mutate: updateChannelPreferences } = useUpdateChannelPreferences();
	const {
		isProfileUpdating,
		profile,
		updatePreferredLanguage,
	} = useProfile();

	const languageKey = getLanguageKey(i18n.language);

	const handleChangeLanguage = useCallback(async (code) => {
		if (code !== languageKey) {
			updateChannelPreferences({ locale: code });
			if (profile) await updatePreferredLanguage(code);
			else i18n.changeLanguage(code);
		}
	}, [i18n, languageKey, profile, updateChannelPreferences, updatePreferredLanguage]);

	const selectedLanguage = useMemo(
		() => languages.find(({ code }) => code === languageKey), [languageKey],
	);

	return (
		<div className={`LanguageSelector ${className}`}>
			<ButtonDropdown
				isOpen={isOpen}
				toggle={() => setIsOpen((prevState) => !prevState)}
				className="h-100"
			>
				<DropdownToggle
					className={`LanguageSelector__Toggle bg-light content-light h-100 border-0 ${minimal ? 'LanguageSelector__Minimal p-0 shadow-none' : ''}`}
					caret={!minimal}
					outline
				>
					{selectedLanguage?.flag}{!minimal && (<span className="ms-3">{selectedLanguage?.text}</span>)}
				</DropdownToggle>
				<DropdownMenu className="bg-dark">
					{languages.map(({ code, flag, text }) => (
						<DropdownItem
							key={code}
							onClick={() => handleChangeLanguage(code)}
						>
							{flag}<span className="ms-3">{text}</span>
						</DropdownItem>
					))}
				</DropdownMenu>
			</ButtonDropdown>
			{isProfileUpdating && <Spinner size="sm" className="ms-2" />}
		</div>
	);
};

LanguageSelector.propTypes = {
	className: PropTypes.string,
	minimal: PropTypes.bool,
};

LanguageSelector.defaultProps = {
	className: '',
	minimal: false,
};
