import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { format } from 'fecha';

import { fetchAdminUserStatusLogs, fetchAdminVodStatusLogs } from '../../../api/admin/log/log';
import { ReportType } from './Context';

export const ReportActionsHistory = ({ report, type }) => {
	const { t } = useTranslation();
	const [logs, setLogs] = useState([]);

	const featchAdminLogs = useCallback(async () => {
		try {
			if (type === ReportType.CHANNEL) {
				const { data } = await fetchAdminUserStatusLogs(report.userId);
				setLogs(data);
			} else if (type === ReportType.VIDEO) {
				const { data } = await fetchAdminVodStatusLogs(report.videoId);
				setLogs(data);
			}
		} catch (e) {
			console.log(e);
		}
	}, [report.userId, report.videoId, type]);

	useEffect(() => {
		featchAdminLogs();
	}, [featchAdminLogs]);

	return (
		<div className="p-3">
			{!logs?.length
				? (
					<p className="content-dark">{t('Moderation.ReportActionsHistory.noLogs')} {type === ReportType.CHANNEL ? t('Moderation.ReportActionsHistory.user') : t('Moderation.ReportActionsHistory.vod')}.</p>
				)
				: (
					<div className="overflow-auto">
						<Table className="TransactionList_table">
							<thead>
								<tr className="content-dark">
									<th>{t('Moderation.ReportActionsHistory.admin')}</th>
									<th>{t('Moderation.ReportActionsHistory.date')}</th>
									<th>{t('Moderation.ReportActionsHistory.action')}</th>
								</tr>
							</thead>
							<tbody>
								{logs.map((log) => (
									<tr className="content-dark" key={log._id}>
										<td className="text-black">{log.admin?.hashtag}</td>
										<td className="text-black">{format(new Date(log.createdAt), t('Global.DateFormat.dateAndTime'))}</td>
										<td className="text-black">{log.text}</td>
									</tr>
								))}
							</tbody>
						</Table>
					</div>
				)}
		</div>
	);
};

ReportActionsHistory.propTypes = {
	report: PropTypes.shape({
		userId: PropTypes.string,
		videoId: PropTypes.string,
	}).isRequired,
	type: PropTypes.string.isRequired,
};
