// @ts-check
/* eslint-disable react/prop-types */
import clsx from 'clsx';
import React from 'react';
import { IoVolumeMute, IoVolumeMediumSharp } from 'react-icons/io5';
import { Button } from 'reactstrap';
import { usePlayerLiveVolume } from '@technomiam/react-video';

/**
 * @typedef {import('reactstrap').ButtonProps & {
 * 	className?: string,
 * }} MuteLiveButtonProps
 */

export const MuteLiveButton = (
	/** @type {MuteLiveButtonProps} */
	{ className = '', ...props },
) => {
	const { isDisabled, isMuted, setIsMuted } = usePlayerLiveVolume();

	return (
		<Button
			tag="span"
			className={clsx('bg-transparent shadow-none p-2', className)}
			color="neutral-secondary"
			onClick={() => setIsMuted(!isMuted)}
			disabled={isDisabled}
			{...props}
		>
			<span className="btn-wrapper--icon d-inline-block">
				{!isMuted
					? <IoVolumeMediumSharp size={20} />
					: <IoVolumeMute size={20} />}
			</span>
		</Button>
	);
};
