import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button } from 'reactstrap';

import { ReactComponent as PipLayoutIcon } from './pipLayoutIcon.svg';

export const LayoutSelectorButton = ({
	className,
	handleClickLayoutSelector,
	showPipLayoutSelector,
}) => {
	const { t } = useTranslation();

	return (
		<Button
			className={clsx(
				'd-30 p-0 flex-shrink-0 d-flex align-items-center justify-content-center',
				{ 'text-primary': showPipLayoutSelector },
				className,
			)}
			color="neutral-secondary"
			title={t('ChannelButtonsPanel.ChannelButtonsPanel.selectLayout')}
			onClick={handleClickLayoutSelector}
		>
			<PipLayoutIcon width="15" height="15" />
		</Button>
	);
};

LayoutSelectorButton.propTypes = {
	className: PropTypes.string,
	handleClickLayoutSelector: PropTypes.func.isRequired,
	showPipLayoutSelector: PropTypes.bool.isRequired,
};

LayoutSelectorButton.defaultProps = {
	className: '',
};
