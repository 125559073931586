// @ts-check

import { createContext, useContext } from 'react';

/** @import { LibraryAssetCategory } from '../Library/AssetCategory/helper'; */

/** @enum {string} */
export const ElementsSectionTabs = {
	GUESTS: 'guests',
	VIDEOS: 'videos',
	AUDIOS: 'audios',
	GFX: 'gfx',
	IMAGES: 'images',
	PLAYLISTS: 'playlists',
	STREAMS: 'streams',
};

/**
 * @typedef {{
 * selectedCategory: LibraryAssetCategory,
 * selectedMedia: ElementsSectionTabs,
 * setSelectedCategory: (category: React.SetStateAction<LibraryAssetCategory>) => void,
 * setSelectedMedia: (media: React.SetStateAction<ElementsSectionTabs>) => void,
* }} IElementsSectionContext
*/

export const ElementsSectionContext = createContext(
	/** @type {IElementsSectionContext | undefined} */({}),
);

// See : https://stackoverflow.com/a/66331283/2440064
// and for further details by Kent C. Dodds:
// https://kentcdodds.com/blog/how-to-use-react-context-effectively#typescript
export const useElementsSection = () => {
	const elementsSectionContext = useContext(ElementsSectionContext);
	// type guard (removes undefined type)
	if (!elementsSectionContext) {
		throw new Error('useElementsSection must be used within a ElementsSectionProvider');
	}
	return elementsSectionContext;
};
