import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import { getVideoSrc } from '../../lib/file';
import { HLSPlayer } from './HLSPlayer';

export const HLSPlayerPlaylist = ({
	onEnded,
	onVideoChange,
	videos,
	...props
}) => {
	const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

	const handleChangeVideo = useCallback(() => {
		if (currentVideoIndex === videos.length - 1) onEnded();
		else {
			onVideoChange(currentVideoIndex + 1);
			setCurrentVideoIndex((index) => index + 1);
		}
	}, [currentVideoIndex, onEnded, onVideoChange, videos.length]);

	const currentVideo = useMemo(() => videos[currentVideoIndex], [currentVideoIndex, videos]);

	const src = currentVideo && getVideoSrc(currentVideo);

	return (
		<HLSPlayer
			currentVideoIndex={currentVideoIndex}
			onEnded={handleChangeVideo}
			src={src}
			videoId={currentVideo._id}
			{...props}
		/>
	);
};

HLSPlayerPlaylist.propTypes = {
	onEnded: PropTypes.func,
	onVideoChange: PropTypes.func,
	videos: PropTypes.arrayOf(PropTypes.shape({
		_id: PropTypes.string.isRequired,
	})).isRequired,
};

HLSPlayerPlaylist.defaultProps = {
	onEnded: () => {},
	onVideoChange: () => {},
};
