import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { MdFlipCameraIos } from 'react-icons/md';
import { Button } from 'reactstrap';
import {
	useMediaUser,
	useInputs,
} from '@technomiam/react-video';

import { MediaErrorPopover } from './MediaErrorPopover';

export const LocalMobileCamSwap = ({ className, ...props }) => {
	const { getIsUserVideoActive, userVideoRequestErrors } = useMediaUser();
	const { swapFrontAndBackCamera } = useInputs();

	const userVideoRequestError = userVideoRequestErrors.find((e) => (
		e.configId === 0 && e.error
	));
	const userVideoActive = getIsUserVideoActive(0);

	let color = 'primary';
	if (userVideoRequestError) {
		color = 'neutral-danger';
	} else if (!userVideoActive) {
		color = 'neutral-primary';
	}

	const animatedIconColor = userVideoRequestError ? 'danger' : 'primary';

	const handleClick = () => {
		if (userVideoRequestError || !userVideoActive) {
			return;
		}
		swapFrontAndBackCamera();
	};

	return (
		<>
			<Button
				id="LocalCam"
				tag="span"
				className={clsx('LocalCam btn-animated-icon', `btn-animated-icon--${animatedIconColor} content-${animatedIconColor}`, className)}
				color={color}
				onClick={handleClick}
				{...props}
			>
				<span className="btn-wrapper--icon d-inline-block">
					<MdFlipCameraIos size={20} />
				</span>
			</Button>
			<MediaErrorPopover error={userVideoRequestError?.error} target="LocalCam" type="camera" />
		</>
	);
};

LocalMobileCamSwap.propTypes = {
	className: PropTypes.string,
};

LocalMobileCamSwap.defaultProps = {
	className: '',
};
