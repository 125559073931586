import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';
import { RiScreenshot2Line } from 'react-icons/ri';
import { useScreenshot } from '@technomiam/react-video';
import { UserSettingsModal, useUserSettings } from '../../../UserSettings/Context';
import { useStudio } from '../../../Studio/Context';
import { LibraryModalMode } from '../../../UserSettings/LibraryModal/LibraryModalMode';
import { useProfile } from '../../../Profile/ProfileContext';

export const ScreenshotButton = ({
	className,
}) => {
	const { screenShot } = useScreenshot();
	const { openModal } = useUserSettings();
	const { studio } = useStudio();
	const { profile } = useProfile();

	const handleClickScreenshot = async () => {
		const imgFile = await screenShot();
		const isHost = profile?._id === studio.owner._id;
		openModal(
			UserSettingsModal.SELECT_IMAGES,
			undefined,
			!isHost ? studio.owner : undefined,
			undefined,
			imgFile,
			isHost ? LibraryModalMode.MY_LIBRARY : LibraryModalMode.SHARED_BY_CHANNEL,
		);
	};

	return (
		<Button
			className={clsx('ScreenshotButton d-flex align-items-center justify-content-center isRecording', className)}
			color="neutral-secondary"
			onClick={handleClickScreenshot}
			tag="span"
		>
			<span className="btn-wrapper--icon d-inline-block">
				<RiScreenshot2Line color="neutral-secondary" size={20} />
			</span>
		</Button>
	);
};

ScreenshotButton.propTypes = {
	className: PropTypes.string,
};

ScreenshotButton.defaultProps = {
	className: '',
};

export default ScreenshotButton;
