import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-jsonschema-form-validation';
import { FormGroup, Input, Label } from 'reactstrap';

import { FormLabel } from '../../Form/Label';
import { ShareTabUsers } from './Users';
import { ShareRecipientType } from '../Share.helper';

const projectName = import.meta.env.VITE_PROJECT;

const ShareUsersType = {
	USER: ShareRecipientType.USER,
	FOLLOWERS: ShareRecipientType.FOLLOWERS,
	FRIENDS: ShareRecipientType.FRIENDS,
};

export const ShareTabUsersFollowersFriends = ({ onShare, ...props }) => {
	const { t } = useTranslation();
	const [selectedType, setSelectedType] = useState(ShareUsersType.USER);

	const handleShare = useCallback(async (participants, role) => {
		const shares = selectedType === ShareUsersType.USER
			? participants
			: [{
				message: participants[0].message,
				type: selectedType,
			}];

		await onShare(shares, role);
	}, [onShare, selectedType]);

	return (
		<>
			<Form className="ShareTabUsers" schema={{}}>
				<FormGroup tag="fieldset">
					<FormLabel>{t('Share.Tab.UsersFollowersFriends.recipient')}</FormLabel>
					<div className="d-flex flex-row flex-wrap">
						{Object.values(ShareUsersType).map((type) => (
							<FormGroup className="mr-2" check key={`radio-${type}`}>
								<Field
									component={Input}
									type="radio"
									id={`radio-input-${type}`}
									onChange={() => setSelectedType(type)}
									checked={selectedType === type}
								/>
								<Label check for={`radio-input-${type}`} className={`${selectedType === type ? 'content-light' : 'text-secondary'}`}>
									{t(`Share.Tab.UsersFollowersFriends.${type}`, { projectName })}
								</Label>
							</FormGroup>
						))}
					</div>
				</FormGroup>
			</Form>
			<ShareTabUsers
				showUsersSelect={selectedType === ShareUsersType.USER}
				onShare={handleShare}
				{...props}
			/>
		</>
	);
};

ShareTabUsersFollowersFriends.propTypes = {
	onShare: PropTypes.func.isRequired,
};
