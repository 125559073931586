// @ts-check

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ResourceAccessLevel } from '../../../lib/ResourceAccessLevel';
import { Sound, useSound } from '../../Sound/Provider';
import { ChannelConnectionContext } from './ChannelConnectionContext';

/** @typedef {import('./ChannelConnectionContext').ConnectedClient} ConnectedClient */

const getUniquePersonalKey = (/** @type {ConnectedClient} */c) => `${c.channelId}-${c._id}-${c.role}`;
const getUniquePersonalKeyByRole = (/** @type {ConnectedClient} */c, /** @type {ResourceAccessRole} */role) => `${c.channelId}-${c._id}-${role}`;

/**
 * @param {ConnectedClient} client
 * @param {Record<ResourceAccessRole, ConnectedClient[]>} clients
 */
const cleanUpClient = (client, clients) => {
	Object.keys(ResourceAccessRole).forEach((role) => {
		clients[getUniquePersonalKeyByRole(client, role)] = (
			clients[getUniquePersonalKeyByRole(client, role)] ?? []
		).filter((c) => !c.hasLeft);
	});
};

/**
 * @typedef {{
 * 	children: React.ReactNode,
 * }} ChannelConnectionProviderProps
 */

export const ChannelConnectionProvider = (
	/** @type {ChannelConnectionProviderProps} */
	{ children },
) => {
	const [allClients, setAllClients] = useState(
		/** @type {Record<ResourceAccessRole, ConnectedClient[]>} */({}),
	);
	const [connectedClients, setConnectedClients] = useState(
		/** @type {ConnectedClient[]} */([]),
	);
	const [notLoggedInClientsCount, setNotLoggedInClientsCount] = useState(0);
	const { playSound } = useSound();
	const timeout = useRef(/** @type {Record<string, NodeJS.Timeout>} */({}));

	const handleEventConnectionNew = useCallback((
		/** @type {ConnectedClient} */client,
	) => {
		if (client._id) { // Logged in client
			clearTimeout(timeout.current[client._id]);

			setAllClients((aClients) => {
				const nClients = { ...aClients };
				nClients[getUniquePersonalKey(client)] = [
					...(nClients[getUniquePersonalKey(client)] ?? []),
					client,
				];
				cleanUpClient(client, nClients);

				return nClients;
			});
		} else { // Not-logged in client
			setNotLoggedInClientsCount((c) => c + 1);
		}

		if (![ResourceAccessRole.PUBLIC].includes(client.role)) {
			playSound(Sound.NEW_CONNECTION);
		}
	}, [playSound]);

	const handleEventConnectionLeft = useCallback((
		/** @type {ConnectedClient} */client,
		forceClear = false,
	) => {
		if (client._id) { // Logged in client
			clearTimeout(timeout.current[client._id]);

			setAllClients((aClients) => {
				const nClients = { ...aClients };

				if (forceClear) {
					cleanUpClient(client, nClients);
				} else {
					nClients[getUniquePersonalKey(client)] = (
						nClients[getUniquePersonalKey(client)] ?? []
					).map((c) => (c.uuid === client.uuid ? ({ ...c, hasLeft: true }) : c));

					timeout.current[client._id] = setTimeout(
						() => handleEventConnectionLeft(client, true),
						500,
					);
				}

				return nClients;
			});
		} else { // Not-logged in client
			setNotLoggedInClientsCount((c) => c - 1);
		}
	}, []);

	const handleEventConnectionAll = useCallback((
		/** @type {ConnectedClient[]} */clients,
	) => {
		const loggedInClients = clients.filter((c) => c._id);
		setAllClients((aClients) => {
			const nClients = { ...aClients };

			loggedInClients.forEach((client) => {
				nClients[getUniquePersonalKey(client)] = [
					...(nClients[getUniquePersonalKey(client)] ?? []),
					client,
				];
			});

			return nClients;
		});

		const notLoggedInClients = clients.filter((c) => !c._id);
		setNotLoggedInClientsCount(notLoggedInClients.length);
	}, []);

	useEffect(() => {
		if (allClients) {
			const newClients = Object.keys(allClients)
				.map((key) => allClients[key][0])
				.filter((el) => !!el);

			newClients.sort((c1, c2) => {
				if (c1.role === c2.role) {
					return c1.nickname.localeCompare(c2.nickname);
				}

				return ResourceAccessLevel[c2.role] - ResourceAccessLevel[c1.role];
			});

			setConnectedClients(newClients);
		}
	}, [allClients]);

	// clean up any timers left
	useEffect(() => () => {
		Object.keys(timeout.current).forEach((key) => {
			clearTimeout(timeout.current[key]);
		});
	}, []);

	const value = useMemo(() => ({
		connectedClients,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
		notLoggedInClientsCount,
	}), [
		connectedClients,
		handleEventConnectionNew,
		handleEventConnectionLeft,
		handleEventConnectionAll,
		notLoggedInClientsCount,
	]);

	return (
		<ChannelConnectionContext.Provider value={value}>
			{children}
		</ChannelConnectionContext.Provider>
	);
};

ChannelConnectionProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
