import React, { lazy, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaBuysellads, FaChartBar } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useProfile } from '../../Profile/ProfileContext';
import { AdvertisingContent } from './Advertising/Content';
import { AnalyticsProvider } from './Analytics/Provider';

import './Content.scss';
import { useUserSettings } from '../Context';

const AnalyticsContent = lazy(() => import('./Analytics/Content'));

export const AnalyticsAndAdvertisingMenuItems = {
	ANALYTICS: 'ANALYTICS',
	ADVERTISING: 'ADVERTISING',
};

export const AnalyticsAndAdvertisingContent = ({ defaultTab }) => {
	const { t } = useTranslation();
	const [activeMenuItem, setActiveMenuItem] = useState(AnalyticsAndAdvertisingMenuItems.ANALYTICS);
	const { profile } = useProfile();
	const { closeAnalyticsAndAdModal } = useUserSettings();

	useEffect(() => {
		if (defaultTab) {
			setActiveMenuItem(defaultTab);
		}
	}, [defaultTab]);

	return (
		<Row className="h-100 flex-column flex-md-row">
			<Col className="p-0 pt-4 AnalyticsAndAdvertisingSidebar bg-dark" md={2} sm={12}>
				<div className="pr-2">
					<p
						className={
							`m-0 text-secondary cursor-pointer pl-3 py-2 ${activeMenuItem === AnalyticsAndAdvertisingMenuItems.ANALYTICS ? 'ActiveMenuItem text-black' : ''}`
						}
						onClick={() => setActiveMenuItem(AnalyticsAndAdvertisingMenuItems.ANALYTICS)}
					>
						<span className="btn-wrapper--icon mr-2">
							<FaChartBar />
						</span>
						{t('AnalyticsAndAdvertising.Content.myAnalytics')}
					</p>
				</div>
				<div className="pr-2 mt-2">
					<p
						className={
							`m-0 text-secondary cursor-pointer pl-3 py-2 ${activeMenuItem === AnalyticsAndAdvertisingMenuItems.ADVERTISING ? 'ActiveMenuItem text-black' : ''}`
						}
						onClick={() => setActiveMenuItem(AnalyticsAndAdvertisingMenuItems.ADVERTISING)}
					>
						<span className="btn-wrapper--icon mr-2">
							<FaBuysellads />
						</span>
						Advertising
					</p>
				</div>
			</Col>
			<Col className="bg-dark" md={10} sm={12}>
				{activeMenuItem === AnalyticsAndAdvertisingMenuItems.ANALYTICS && (
					<AnalyticsProvider>
						<AnalyticsContent closeModal={closeAnalyticsAndAdModal} channelId={profile._id} />
					</AnalyticsProvider>
				)}
				{activeMenuItem === AnalyticsAndAdvertisingMenuItems.ADVERTISING && (
					<AdvertisingContent />
				)}
			</Col>
		</Row>
	);
};

AnalyticsAndAdvertisingContent.propTypes = {
	defaultTab: PropTypes.string,
};

AnalyticsAndAdvertisingContent.defaultProps = {
	defaultTab: 'ANALYTICS',
};
