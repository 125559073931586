/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
// eslint-disable-next-line max-classes-per-file
import EventEmitter from 'events';
import { decodeJwt } from 'jose';

import { createAxiosInstance } from './axios';

export const PREVIOUS_SESSION_KEY = 'identity.previousSession';

export class IdentityError extends Error {
	name = 'IdentityError';

	type = 'error';

	isIdentityError = true;

	constructor(type, ...params) {
		super(...params);

		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, IdentityError);
		}

		this.type = type;
	}
}

/**
 * events: login, logout
 */
class Identity extends EventEmitter {
	session = {};

	_api = undefined;

	baseURL = undefined;

	localstorageSessionKey = 'identity.session';

	localstoragePreviousSessionKey = PREVIOUS_SESSION_KEY;

	initialize() {
		const savedSession = localStorage.getItem(this.localstorageSessionKey);
		this.session = savedSession ? JSON.parse(savedSession) : this.session;
		return this;
	}

	/**
	 * Override if you need a different api
	 */
	buildApi() {
		return createAxiosInstance({ baseURL: this.baseURL });
	}

	get api() {
		if (!this._api) this._api = this.buildApi();
		return this._api;
	}

	/**
	 * Override if you need to check session expiration differently.
	 * For example using the expiration date of an other token
	 */
	isSessionExpired() {
		return (
			!this.session.refresh_token
			|| this.session.refresh_token.expiresAt <= Date.now()
		);
	}

	setSession({
		access_token,
		id_token,
		refresh_token,
	}) {
		const decodedAccessToken = access_token && decodeJwt(access_token);
		const decodedIdToken = id_token && decodeJwt(id_token);
		const decodedRefreshToken = refresh_token && decodeJwt(refresh_token);

		this.session = {
			...this.session,
			access_token: access_token && {
				expiresAt: decodedAccessToken.exp * 1000,
				payload: decodedAccessToken,
				token: access_token,
			},
			id_token: id_token && {
				expiresAt: decodedIdToken.exp * 1000,
				payload: decodedIdToken,
				token: id_token,
			},
			refresh_token: refresh_token && {
				expiresAt: decodedRefreshToken.exp * 1000,
				payload: decodedRefreshToken,
				token: refresh_token,
			},
		};

		localStorage.setItem(this.localstorageSessionKey, JSON.stringify(this.session));

		return this.session;
	}

	setPreviousSession() {
		localStorage.setItem(this.localstoragePreviousSessionKey, JSON.stringify(this.session));
	}

	getPreviousSession() {
		return JSON.parse(localStorage.getItem(this.localstoragePreviousSessionKey));
	}

	clearPreviousSession() {
		localStorage.removeItem(this.localstoragePreviousSessionKey);
	}

	clear() {
		this.session = {};
		localStorage.removeItem(this.localstorageSessionKey);
	}

	/**
	 * Override if you need to different `login` request
	 */
	async requestLogin(credentials) {
		// eslint-disable-next-line no-nested-ternary
		const { data: tokens } = await this.api.post(`/login/${credentials.email ? 'email' : credentials.phoneNumber ? 'phone' : 'google'}`, credentials);
		return tokens;
	}

	async requestSelectOrganization(credentials) {
		const { data: tokens } = await this.api.post('/login/challenge/organization', credentials);
		return tokens;
	}

	/**
	 * Override if you need to different `token` request
	 */
	async requestToken(refresh_token) {
		const { data: tokens } = await this.api.post('/token', { refresh_token });
		return tokens;
	}

	/**
	 * Override if you need to different `guestToken` request
	 */
	async requestGuestToken(data) {
		const { data: tokens } = await this.api.post('/guest', data);
		return tokens;
	}

	async login(credentials) {
		const loginResponse = await this.requestLogin(credentials);
		if (loginResponse.requireOrganizationChallenge) {
			this.emit('requireOrganizationChallenge', loginResponse);
		} else {
			this.loginWithTokens(loginResponse);
		}
		return loginResponse;
	}

	async loginAsGuest(data) {
		const tokens = await this.requestGuestToken(data);
		this.loginWithTokens(tokens);
	}

	async selectOrganization(credentials) {
		const tokens = await this.requestSelectOrganization(credentials);
		this.loginWithTokens(tokens);
	}

	loginWithTokens(tokens) {
		this.setSession(tokens);
		this.emit('login');
	}

	async loginAfterGoogleSignUp(loginResponse) {
		if (loginResponse.requireOrganizationChallenge) {
			this.emit('requireOrganizationChallenge', loginResponse);
		} else {
			this.loginWithTokens(loginResponse);
		}
		return loginResponse;
	}

	/**
	 * Override if you need to refresh tokens differently.
	 * For example using an access_token instead of the refresh_token
	 */
	async refreshTokens() {
		const refresh_token = await this.getRefreshToken();
		return this.requestToken(refresh_token.token);
	}

	pendingRefreshSession = undefined;

	async refreshSession() {
		// Avoid refreshing session multiple times in a row
		if (this.pendingRefreshSession) return this.pendingRefreshSession;

		this.pendingRefreshSession = (async () => {
			try {
				if (this.isSessionExpired()) {
					throw new IdentityError('session_expired', 'The session has expired.');
				}
				const tokens = await this.refreshTokens();
				this.setSession(tokens);
				this.emit('refresh');
			} catch (error) {
				/**
				 * If there is an identity error or if fetching new tokens
				 * reponses is a 4xx error, then logout
				 * For any other error, do not logout because the session validity is not the cause
				 */
				if (
					error.isIdentityError
					|| (error.response?.status >= 400 && error.response?.status < 500)
				) {
					this.logout();
				}
				throw error;
			} finally {
				this.pendingRefreshSession = undefined;
			}
		})();

		return this.pendingRefreshSession;
	}

	logout() {
		this.clear();
		this.emit('logout');
		this.clearPreviousSession();
	}

	// eslint-disable-next-line class-methods-use-this
	isTokenValid(token) {
		return token.expiresAt > Date.now();
	}

	async getAccessToken() {
		let { access_token } = this.session;
		// do not refresh a not existing token
		if (!access_token) throw new IdentityError('access_token_not_found', 'Access Token not found.');
		// if token invalid, try to refresh it first
		if (!this.isTokenValid(access_token)) {
			await this.refreshSession();
			({ access_token } = this.session);
		}
		// if token is still invalid, throw error
		if (!this.isTokenValid(access_token)) {
			this.logout(); // this is an weird case, a logout is preferred
			throw new IdentityError('access_token_invalid', 'Access Token is invalid.');
		}
		return access_token;
	}

	async getIdToken() {
		let { id_token } = this.session;
		if (!id_token) throw new IdentityError('id_token_not_found', 'ID Token not found.');
		if (!this.isTokenValid(id_token)) {
			await this.refreshSession();
			({ id_token } = this.session);
		}
		if (!this.isTokenValid(id_token)) {
			this.logout(); // this is an weird case, a logout is preferred
			throw new IdentityError('id_token_invalid', 'ID Token is invalid.');
		}
		return id_token;
	}

	async getRefreshToken() {
		const { refresh_token } = this.session;
		if (!refresh_token) throw new IdentityError('refresh_token_not_found', 'Refresh Token not found.');
		if (!this.isTokenValid(refresh_token)) {
			throw new IdentityError('refresh_token_invalid', 'Refresh Token is invalid.');
		}
		return refresh_token;
	}

	async getUser() {
		const { payload } = await this.getIdToken();
		return payload;
	}
}

export default Identity;
