/* eslint-disable react/prop-types */
// @ts-check

import React from 'react';
import 'react-multi-email/style.css';
import { Modal } from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { useAsyncErrorLog } from '../../../lib/hooks';
import { useStudio } from '../Context';
import { ButtonLoading, ButtonPill, ButtonPillOutline } from '../../Button';
import { useActiveStudio } from '../Active/Context';
import { AlertTimeout } from '../../Alert/Timeout';

/**
 * @typedef {{
* 	isOpen: boolean,
* 	toggle: () => void,
* }} StudioGoLiveStopModalProps
*/

export const StudioGoLiveStopModal = (
	/** @type {StudioGoLiveStopModalProps} */
	{
		isOpen,
		toggle,
	},
) => {
	const { t } = useTranslation();
	const {	stoplive } = useStudio();
	const { updateActiveStudio } = useActiveStudio();

	const asyncStopLive = useAsyncCallback(async () => {
		const updatedStudio = await stoplive();
		updateActiveStudio(updatedStudio);
		toggle();
	});

	useAsyncErrorLog(asyncStopLive);

	return (
		<Modal
			centered
			contentClassName="border-0 rounded overflow-hidden bg-transparent align-self-center"
			isOpen={isOpen}
			toggle={toggle}
			zIndex={2000}
		>
			<div className="bg-dark shadow-lg">
				<div className="mt-4 w-100 text-center">
					<h4>{t('GoLive.StopModal.stopPublicLive')}</h4>
				</div>
				{asyncStopLive.error && (
					<AlertTimeout className="mx-3" color="danger" onTimeout={asyncStopLive.reset}>
						{t('Global.error')}
					</AlertTimeout>
				)}
				<div className="mb-4 d-flex justify-content-evenly">
					<ButtonPillOutline
						onClick={toggle}
						color="secondary"
						className=""
					>
						{t('GoLive.StopModal.close')}
					</ButtonPillOutline>
					<ButtonLoading
						color="danger"
						component={ButtonPill}
						loading={asyncStopLive.loading}
						onClick={asyncStopLive.execute}
					>
						{t('GoLive.StopModal.stopLive')}
					</ButtonLoading>
				</div>
			</div>
		</Modal>
	);
};
