import React from 'react';
import PropTypes from 'prop-types';

import { ButtonPillOutline } from '../Button';
import { UserSettingsModal, useUserSettings } from '../UserSettings/Context';
import { MembershipModalTab } from '../UserSettings/MembershipModalTab';

export const AdCreditBuyButton = ({ children, ...props }) => {
	const { openModal } = useUserSettings();

	return (
		<ButtonPillOutline
			color="secondary"
			size="sm"
			onClick={() => openModal(UserSettingsModal.MEMBERSHIP, MembershipModalTab.BUY_AD_CREDITS)}
			{...props}
		>
			{children || 'Buy Ad Credits'}
		</ButtonPillOutline>
	);
};

AdCreditBuyButton.propTypes = {
	children: PropTypes.node,
};

AdCreditBuyButton.defaultProps = {
	children: undefined,
};
