/* eslint-disable react/prop-types */
// @ts-check

import React, { useCallback, useMemo, useState } from 'react';

import { ChannelWatchContext } from './Context';

/**
 * @typedef {{
 *  children: React.ReactNode;
 * }} ChannelWatchProviderProps
 */

export const ChannelWatchProvider = (
	/** @type {ChannelWatchProviderProps} */
	{ children },
) => {
	const [newVodAvailable, setNewVodAvailable] = useState(
		/** @type {{ watchLinkId: string } | undefined} */(undefined),
	);

	const handleEventVodNew = useCallback((
		/** @type {{ vod: { watchLinkId: string } }} */
		{ vod },
	) => {
		setNewVodAvailable(vod);
	}, []);

	const value = useMemo(() => ({
		handleEventVodNew,
		newVodAvailable,
	}), [
		handleEventVodNew,
		newVodAvailable,
	]);

	return (
		<ChannelWatchContext.Provider value={value}>
			{children}
		</ChannelWatchContext.Provider>
	);
};
