import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Col, Modal, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ButtonLoading } from '../../components/Button/Loading';

export const ConfirmationModal = ({
	error,
	errorMessage,
	isOpen,
	loading,
	message,
	onConfirm,
	onDeny,
}) => {
	const { t } = useTranslation();
	return (
		<Modal
			centered
			contentClassName="CurrentShow border-0 shadow-lg rounded overflow-hidden bg-transparent m-auto"
			isOpen={isOpen}
			toggle={onDeny}
			zIndex={2000}
		>
			<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
				<Col xs="8">
					<h4 className="text-center pt-5 pb-3 font-weight-bold mt-4">
						{message}
					</h4>
				</Col>
			</Row>
			{error && (
				<Row className="g-0 w-100 bg-dark d-flex justify-content-center align-items-center">
					<Col xs="8">
						<Alert color="danger">{errorMessage || t('Global.error')}</Alert>
					</Col>
				</Row>
			)}
			<Row className="g-0 w-100 p-3 bg-dark d-flex justify-content-end align-items-center">
				<ButtonLoading
					color="neutral-secondary"
					loading={loading}
					onClick={onConfirm}
					size="lg"
				>
					{t('ConfirmationModal.Index.yes')}
				</ButtonLoading>
				<ButtonLoading
					className="ml-1"
					color="neutral-secondary"
					loading={loading}
					onClick={onDeny}
					size="lg"
				>
					{t('ConfirmationModal.Index.no')}
				</ButtonLoading>
			</Row>
		</Modal>
	);
};

ConfirmationModal.propTypes = {
	error: PropTypes.bool,
	errorMessage: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	loading: PropTypes.bool,
	message: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onDeny: PropTypes.func.isRequired,
};

ConfirmationModal.defaultProps = {
	error: false,
	errorMessage: '',
	loading: false,
	message: 'Are you sure?',
};
