import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Col, Row } from 'reactstrap';
import { useCapturePaypalSubscription, useCreatePaypalSubscription } from '../../../../api-hooks/membership/paypal';

const { VITE_PAYPAL_CLIENT_ID } = import.meta.env;

export const PaypalSubscriptionButton = ({ plan, onSubscriptionComplete }) => {
	const { mutateAsync: createSubscriptionAsync } = useCreatePaypalSubscription();
	const { mutateAsync: captureSubscriptionAsync } = useCapturePaypalSubscription();

	const handleCreateSubscription = useCallback(async () => {
		const subscription = await createSubscriptionAsync(plan);

		return subscription.id;
	}, [createSubscriptionAsync, plan]);

	const handleApproceSubscription = useCallback(async (data) => {
		const subscription = await captureSubscriptionAsync(data.subscriptionID);

		if (subscription) {
			onSubscriptionComplete();
		}
	}, [captureSubscriptionAsync, onSubscriptionComplete]);

	return (
		<Row className="mt-4">
			<Col className="w-100 d-flex justify-content-center pb-2">
				<div className="p-2 bg-white rounded">
					<PayPalScriptProvider
						options={{
							'client-id': VITE_PAYPAL_CLIENT_ID,
							components: 'buttons',
							intent: 'subscription',
							vault: true,
						}}
					>
						<PayPalButtons
							createSubscription={handleCreateSubscription}
							style={{
								label: 'subscribe',
								color: 'blue',
							}}
							onApprove={handleApproceSubscription}
						/>
					</PayPalScriptProvider>
				</div>
			</Col>
		</Row>
	);
};

PaypalSubscriptionButton.propTypes = {
	plan: PropTypes.string.isRequired,
	onSubscriptionComplete: PropTypes.func.isRequired,
};
