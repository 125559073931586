/* eslint-disable react/prop-types */
// @ts-check

import React, { createContext, useCallback, useContext, useMemo, useState, useRef } from 'react';

/**
 * @import { Resizable } from 're-resizable';
 */

/**
 * @typedef {{
 * maxSectionHeight: { [key: ContainersID]: number },
 * onInitialResize: (sidebarType: ControlroomSidebarType) => void,
 * onResize: (containerID: number) => void,
 * onToggleContainer: (containerID: number) => void,
 * openedContainers: number[],
 * resizableRefs: React.MutableRefObject<Resizable?>[],
 * transitionClassNameRef: React.MutableRefObject<string>,
 * }} IControlroomSidebarContext
 */

export const ControlroomSidebarContext = createContext(
	/** @type {IControlroomSidebarContext | undefined} */(undefined),
);

export const useControlroomSidebar = () => {
	const controlroomSidebarContext = useContext(ControlroomSidebarContext);
	// type guard (removes undefined type)
	if (!controlroomSidebarContext) {
		throw new Error('useControlroomSidebar must be used within a ControlroomSidebarProvider');
	}
	return controlroomSidebarContext;
};

/** @enum {number} */
export const ContainersID = {
	FIRST: 0,
	SECOND: 1,
	THIRD: 2,
};

export const HEADER_HEIGHT = 90;

export const MIN_SECTION_HEIGHT = 57;

/** @enum {string} */
export const ControlroomSidebarType = {
	LEFT: 'LEFT',
	RIGHT: 'RIGHT',
};

/**
 * @typedef {{
 * 	children: React.ReactNode;
 * }} ControlroomSidebarProviderProps
 */

export const ControlroomSidebarProvider = (
	/** @type {ControlroomSidebarProviderProps} */
	{ children },
) => {
	const resizableRef1 = useRef(/** @type {Resizable?} */(null));
	const resizableRef2 = useRef(/** @type {Resizable?} */(null));
	const resizableRef3 = useRef(/** @type {Resizable?} */(null));

	const resizableRefs = useMemo(() => [resizableRef1, resizableRef2, resizableRef3], []);
	const transitionClassNameRef = useRef('');

	const [openedContainers, setOpenedContainers] = useState([1, 0, 0]);

	const [maxSectionHeight, setMaxSectionHeight] = useState({
		[ContainersID.FIRST]: 300,
		[ContainersID.SECOND]: 300,
		[ContainersID.THIRD]: 300,
	});

	const [totalMaxHeight, setTotalMaxHeight] = useState(0);

	const setHeightUsingRef = useCallback((
		/** @type {React.MutableRefObject<Resizable?>} */ref,
		/** @type {number} */height,
	) => {
		if (ref.current) {
			ref.current.updateSize({
				height,
				width: '100%',
			});
		}
	}, []);

	const onInitialResize = useCallback((
		/** @type {ControlroomSidebarType} */sidebarType,
	) => {
		const newScreenHeight = window.innerHeight - HEADER_HEIGHT;

		setTotalMaxHeight(newScreenHeight);

		const fullHeight = newScreenHeight - 2 * MIN_SECTION_HEIGHT;

		setMaxSectionHeight({
			[ContainersID.FIRST]: sidebarType === ControlroomSidebarType.LEFT
				? fullHeight
				: MIN_SECTION_HEIGHT,
			[ContainersID.SECOND]: sidebarType === ControlroomSidebarType.RIGHT
				? fullHeight
				: MIN_SECTION_HEIGHT,
			[ContainersID.THIRD]: MIN_SECTION_HEIGHT,
		});

		if (sidebarType === ControlroomSidebarType.LEFT) {
			resizableRefs.map((ref, index) => (
				index === ContainersID.FIRST
					? setHeightUsingRef(ref, fullHeight)
					: setHeightUsingRef(ref, MIN_SECTION_HEIGHT)
			));
		} else {
			resizableRefs.map((ref, index) => (
				index === ContainersID.SECOND
					? setHeightUsingRef(ref, fullHeight)
					: setHeightUsingRef(ref, MIN_SECTION_HEIGHT)
			));
		}

		setOpenedContainers(sidebarType === ControlroomSidebarType.LEFT ? [1, 0, 0] : [0, 1, 0]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setHeightUsingRef]);

	const onResize = useCallback((
		/** @type {number} */containerID,
	) => {
		const totalOtherSectionsHeight = resizableRefs.reduce(
			(acc, cur, index) => (
				index !== containerID ? acc + (cur.current?.resizable?.clientHeight || 0) : acc
			), 0,
		);

		setOpenedContainers((prevState) => (
			prevState.map((value, index) => (index === containerID ? 1 : value))
		));

		const currentMaxHeight = totalMaxHeight - totalOtherSectionsHeight;

		setMaxSectionHeight((prev) => ({
			...prev,
			[containerID]: currentMaxHeight,
		}));

		const currentHeight = resizableRefs[containerID].current?.resizable?.clientHeight || 0;

		if (currentHeight <= MIN_SECTION_HEIGHT) {
			setOpenedContainers((prevState) => (
				prevState.map((value, index) => (index === containerID ? 0 : value))
			));
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [totalMaxHeight]);

	const openContainer = useCallback((
		/** @type {number} */containerID,
	) => {
		const totalOtherSectionsHeight = resizableRefs.reduce(
			(acc, cur, index) => (
				index !== containerID ? acc + (cur.current?.resizable?.clientHeight || 0) : acc
			), 0,
		);

		const currentMaxHeight = totalMaxHeight - totalOtherSectionsHeight;

		if (currentMaxHeight < 2 * MIN_SECTION_HEIGHT) {
			const openedContainerIds = openedContainers.reduce(
				(acc, cur, index) => {
					if (cur) {
						return [...acc, index];
					}
					return acc;
				},
				/** @type {number[]} */([]),
			);

			let newMaxHeight = 0;
			openedContainerIds.forEach((openedContainerId) => {
				const openedHeight = resizableRefs[openedContainerId].current?.resizable?.clientHeight || 0;
				const newOpenedHeight = openedHeight / 2;

				if (newOpenedHeight > MIN_SECTION_HEIGHT) {
					setMaxSectionHeight((prev) => ({
						...prev,
						[openedContainerId]: newOpenedHeight,
					}));
					setHeightUsingRef(resizableRefs[openedContainerId], newOpenedHeight);
					newMaxHeight += newOpenedHeight;
				} else {
					setMaxSectionHeight((prev) => ({
						...prev,
						[openedContainerId]: MIN_SECTION_HEIGHT,
					}));
					setHeightUsingRef(resizableRefs[openedContainerId], MIN_SECTION_HEIGHT);

					setOpenedContainers((prevState) => (
						prevState.map((value, index) => (index === openedContainerId ? 0 : value))
					));
					newMaxHeight += MIN_SECTION_HEIGHT;
				}
			});

			newMaxHeight += MIN_SECTION_HEIGHT;

			setMaxSectionHeight((prev) => ({
				...prev,
				[containerID]: newMaxHeight,
			}));
			setHeightUsingRef(resizableRefs[containerID], newMaxHeight);
		} else {
			setMaxSectionHeight((prev) => ({
				...prev,
				[containerID]: currentMaxHeight,
			}));
			setHeightUsingRef(resizableRefs[containerID], currentMaxHeight);
		}
	}, [openedContainers, resizableRefs, setHeightUsingRef, totalMaxHeight]);

	const extendContainers = useCallback((
		/** @type {number[]} */containerIds,
	) => {
		const totalOccupiedHeight = resizableRefs.reduce((acc, ref, id) => {
			if (containerIds.includes(id)) return acc + (ref.current?.resizable?.clientHeight || 0);
			return acc + MIN_SECTION_HEIGHT;
		}, 0);

		const remainingHeight = totalMaxHeight - totalOccupiedHeight;

		containerIds.forEach((containerId) => {
			const openedHeight = resizableRefs[containerId].current?.resizable?.clientHeight || 0;
			const newOpenedHeight = openedHeight + remainingHeight / containerIds.length;

			setMaxSectionHeight((prev) => ({
				...prev,
				[containerId]: newOpenedHeight,
			}));
			setHeightUsingRef(resizableRefs[containerId], newOpenedHeight);
		});
	}, [resizableRefs, setHeightUsingRef, totalMaxHeight]);

	const onToggleContainer = useCallback((
		/** @type {number} */containerID,
	) => {
		setOpenedContainers((prevState) => (
			// eslint-disable-next-line no-nested-ternary
			prevState.map((value, index) => (index === containerID
				? (value ? 0 : 1)
				: value))
		));

		transitionClassNameRef.current = 'SidebarResizableContainer';

		if (openedContainers[containerID]) {
			// Close container
			setMaxSectionHeight((prev) => ({
				...prev,
				[containerID]: MIN_SECTION_HEIGHT,
			}));
			setHeightUsingRef(resizableRefs[containerID], MIN_SECTION_HEIGHT);

			// Extend other opened containers
			const containersToExtendIds = openedContainers.reduce(
				(acc, isOpen, index) => {
					if (isOpen && index !== containerID) {
						return [...acc, index];
					}
					return acc;
				},
				/** @type {number[]} */([]),
			);
			extendContainers(containersToExtendIds);
		} else {
			openContainer(containerID);
		}

		setTimeout(() => {
			transitionClassNameRef.current = '';
		}, 500);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [openedContainers, setHeightUsingRef]);

	const value = useMemo(() => ({
		maxSectionHeight,
		onInitialResize,
		onResize,
		onToggleContainer,
		openedContainers,
		resizableRefs,
		transitionClassNameRef,
	}), [
		maxSectionHeight,
		onInitialResize,
		onResize,
		onToggleContainer,
		openedContainers,
		resizableRefs,
		transitionClassNameRef,
	]);

	return (
		<ControlroomSidebarContext.Provider value={value}>
			{children}
		</ControlroomSidebarContext.Provider>
	);
};
