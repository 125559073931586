import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Badge } from 'reactstrap';
import { useMediaUser } from '@technomiam/react-video';
import { FaCog } from 'react-icons/fa';

export const SettingsButton = ({
	className,
	handleToggleSettings,
}) => {
	const { t } = useTranslation();
	const { userActiveTracks } = useMediaUser();

	const isNonDefaultConfigUserMediaActive = useMemo(
		() => !!(userActiveTracks || []).find((track) => (track.configId !== 0)),
		[userActiveTracks],
	);

	return (
		<Button
			className={`d-30 p-0 btn-animated-icon btn-animated-icon--secondary flex-shrink-0 shadow-none ${className}`}
			color="neutral-secondary"
			id="Settings"
			onClick={handleToggleSettings}
			title={t('ChannelButtonsPanel.ChannelButtonsPanel.settings')}
		>
			<span className="btn-wrapper--icon">
				<FaCog size={20} />
			</span>
			{isNonDefaultConfigUserMediaActive && (
				<Badge
					color="primary"
					className="badge-circle badge-position top-0 right-0 zIndex-2 shadow-none"
				>
		&nbsp;
				</Badge>
			)}
		</Button>
	);
};

SettingsButton.propTypes = {
	className: PropTypes.string,
	handleToggleSettings: PropTypes.func.isRequired,
};

SettingsButton.defaultProps = {
	className: '',
};
