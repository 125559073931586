import PropTypes from 'prop-types';
import React, { Suspense, useCallback, useMemo, useState } from 'react';
import { OrganizationSwitchContext } from './Context';
import { OrganizationSwitchModal } from './Modal';

export const OrganizationSwitchProvider = ({ children }) => {
	const [switchModalOpen, setSwitchModalOpen] = useState(false);

	const openOrganizationSwitchModal = useCallback(() => {
		setSwitchModalOpen(true);
	}, []);

	const closeOrganizationSwitchModal = useCallback(() => {
		setSwitchModalOpen(false);
	}, []);

	const context = useMemo(() => ({
		setSwitchModalOpen,
		openOrganizationSwitchModal,
		closeOrganizationSwitchModal,
	}), [
		setSwitchModalOpen,
		openOrganizationSwitchModal,
		closeOrganizationSwitchModal,
	]);

	return (
		<OrganizationSwitchContext.Provider value={context}>
			{children}
			<Suspense fallback={null}>
				{switchModalOpen && (
					<OrganizationSwitchModal
						isOpen={switchModalOpen}
						closeModal={() => setSwitchModalOpen(false)}
					/>
				)}
			</Suspense>
		</OrganizationSwitchContext.Provider>
	);
};

OrganizationSwitchProvider.propTypes = {
	children: PropTypes.node,
};

OrganizationSwitchProvider.defaultProps = {
	children: undefined,
};
