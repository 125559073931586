import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as studioApi from '../../api/studio/studio';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { axiosMutationWrapper, axiosQueryWrapper } from '../utils/axios-wrapper';

export const STUDIO_QUERY_KEYS = {
	fetchActiveStudio: () => ['api', 'studio', 'active'],
	fetchPresaleTicketsByStudioId: (studioId) => ['api', 'studio', 'presale', studioId],
	fetchStudioOverview: (studioId) => ['api', 'studio', 'overview', studioId],
};

export const useFetchActiveStudio = () => {
	const { isLoggedIn } = useAuthentication();

	return useQuery(
		STUDIO_QUERY_KEYS.fetchActiveStudio(),
		axiosQueryWrapper(studioApi.fetchActiveStudio),
		{ retry: 0, enabled: !!isLoggedIn },
	);
};

export const useFetchStudioPresaleTickets = (studioId) => useQuery(
	STUDIO_QUERY_KEYS.fetchPresaleTicketsByStudioId(studioId),
	axiosQueryWrapper(studioApi.fetchStudioPresaleTickets, studioId),
	{ staleTime: 0, enabled: !!studioId },
);

export const useFetchStudioOverview = (studioId) => useQuery(
	STUDIO_QUERY_KEYS.fetchStudioOverview(studioId),
	axiosQueryWrapper(studioApi.fetchStudioOverview, studioId),
	{ staleTime: 0, enabled: !!studioId },
);

export const useRefundStudioPresaleTicket = () => useMutation(
	axiosMutationWrapper(studioApi.refundStudioPresaleTicket),
);

export const useAnswerStudioAttendance = () => {
	const queryClient = useQueryClient();

	return useMutation(
		axiosMutationWrapper(studioApi.answerStudioAttendace), {
			onSuccess: (_, { studioId }) => {
				queryClient.invalidateQueries(STUDIO_QUERY_KEYS.fetchStudioOverview(studioId));
			},
		},
	);
};

export const useUpdateStudioAllowGuests = () => useMutation(
	axiosMutationWrapper(studioApi.updateAllowGuests),
);
