import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { ShareModalContext } from './Context';
import { ResourceAccessRole } from '../../../lib/ResourceAccessRole';
import { ShareModal } from './Modal';

export const ShareModalProvider = ({ children }) => {
	const [isShareModalOpen, setIsShareModalOpen] = useState(false);

	const [shareModalProps, setShareModalProps] = useState({
		channel: null,
		content: null,
		contentType: null,
		defaultShareMessage: '',
		roles: [ResourceAccessRole.PUBLIC],
		studio: null,
		tabs: [],
	});

	const toggleShareModal = useCallback(({
		channel,
		content,
		contentType,
		defaultShareMessage,
		roles = [ResourceAccessRole.PUBLIC],
		studio,
		tabs,
	}) => {
		setShareModalProps({
			channel,
			content,
			contentType,
			defaultShareMessage,
			roles,
			studio,
			tabs,
		});
		setIsShareModalOpen((s) => !s);
	}, []);

	const context = useMemo(() => ({ toggleShareModal }), [toggleShareModal]);

	return (
		<ShareModalContext.Provider value={context}>
			{children}
			{isShareModalOpen && (
				<ShareModal
					toggle={toggleShareModal}
					{...shareModalProps}
				/>
			)}
		</ShareModalContext.Provider>
	);
};

ShareModalProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
