import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAsyncCallback } from 'react-async-hook';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import FormSubmit from '../../../Form/Submit';
import { FormLabel } from '../../../Form/Label';
import { ButtonPillOutline } from '../../../Button';
import { FormPhone } from '../../../Form/Phone';
import { ResourceAccessRole } from '../../../../lib/ResourceAccessRole';
import { ShareRecipientType, ShareTabType } from '../../Share.helper';
import { ShareRoleInput } from '../../RoleInput';
import { AlertTimeout } from '../../../Alert/Timeout';

import './Sms.scss';

const phoneNumberSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		phoneNumber: {
			type: 'string',
		},
		message: {
			type: 'string',
			minLength: 1,
		},
	},
	required: [
		'phoneNumber',
		'message',
	],
};

const getInitialData = (defaultMessage) => ({
	phoneNumber: '',
	message: defaultMessage || '',
});

export const ShareTabSms = ({
	alreadySharedUsers,
	defaultMessage,
	inputColor,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonTranslationKey,
	showSuccessMessage,
}) => {
	const { t } = useTranslation();

	const [data, setData] = useState(
		getInitialData(defaultMessage || t(
			'Share.Tab.Sms.defaultSmsMessage',
			{ projectName: import.meta.env.VITE_PROJECT === 'beeyou' ? 'Beeyou.tv' : 'Commando Vision' },
		)),
	);

	const clear = () => setData((s) => ({ ...s, phoneNumber: '' }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const alreadyAddedSmsShares = alreadySharedUsers.reduce((acc, share) => {
			if (share.type !== ShareRecipientType.SMS) return acc;
			return {
				...acc,
				[share.phoneNumber]: share,
			};
		}, {});

		if (alreadyAddedSmsShares[data.phoneNumber]) return;
		await onShare([{
			type: ShareRecipientType.SMS,
			phoneNumber: data.phoneNumber,
			message: data.message,
		}], selectedRole);
		clear();
	});

	const handleChange = (values) => {
		setData({
			...data,
			...values,
		});
	};

	const isEmpty = !data.phoneNumber;
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	return (
		<Form
			className="SmsForm"
			data={data}
			onSubmit={handleSubmitAsync.execute}
			schema={phoneNumberSchema}
		>
			{showSuccessMessage && handleSubmitAsync.status === 'success' && (
				<AlertTimeout color="success" onTimeout={handleSubmitAsync.reset} timeoutTime={3000}>
					{t('Share.shareSuccessfullySent')}
				</AlertTimeout>
			)}
			{handleSubmitAsync.error && (
				<AlertTimeout color="danger" onTimeout={handleSubmitAsync.reset}>
					{t('Global.error')}
				</AlertTimeout>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.SMS}
			/>
			<FormGroup>
				<FormLabel>{t('Share.Tab.Sms.phone')}</FormLabel>
				<Field
					component={FormPhone}
					name="phoneNumber"
					placeholder={t('Share.Tab.Sms.enterPhoneNumber')}
					className={`ShareTabSms_PhoneInput bg-${inputColor} content-${inputColor} px-2 border-${inputColor}`}
					onChange={(value) => handleChange({ phoneNumber: value })}
					value={data.phoneNumber}
				/>
				<FieldError name="phoneNumber" />
			</FormGroup>
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<div className="d-flex mb-3">
					<ButtonPillOutline
						className="mr-2"
						color={inputColor}
						disabled={isActionDisabled}
						onClick={() => { clear(); }}
					>
						{t('Share.Buttons.clear')}
					</ButtonPillOutline>
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={handleSubmitAsync.loading}
						title={t('Share.Tab.Sms.sendInvitationBySms')}
					>
						{t(shareButtonTranslationKey)}
					</FormSubmit>
				</div>
			</div>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className={`bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
					component={Input}
					name="message"
					onChange={(e) => handleChange({ message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={data.message}
				/>
				<FieldError name="message" />
			</FormGroup>
		</Form>
	);
};

ShareTabSms.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonTranslationKey: PropTypes.string.isRequired,
	showSuccessMessage: PropTypes.bool,
};

ShareTabSms.defaultProps = {
	defaultMessage: '',
	inputColor: 'dark',
	showSuccessMessage: true,
};
