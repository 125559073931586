import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQueryClient } from 'react-query';

import { ProfileContext } from './ProfileContext';
import * as channelFriendsApi from '../../api/channel/friends';
import { useAuthentication } from '../Authentication/Authentication';
import { FRIENDS_QUERY_KEYS } from '../../api-hooks/channel/friends';
import { useFetchGraphGlobalsProfile, useSetGraphGlobalsProfile } from '../../api-hooks/graph/globals';
import i18n from '../../i18n';
import { useUpdateProfile } from '../../api-hooks/profile/me';

const getProfileFromUser = (user) => {
	if (!user) return undefined;
	return {
		_id: user.sub,
		avatar: user.picture,
		birthdate: user.birthdate,
		email: user.email,
		firstname: user.given_name,
		hashtag: user.preferred_username,
		nickname: user.nickname,
		organizationOwner: user.organizationOwner,
		organizationRole: user.organizationRole,
		organization: { _id: user.organization },
	};
};

export const ProfileProvider = ({ children }) => {
	const queryClient = useQueryClient();
	const { user } = useAuthentication();

	const defaultProfile = useMemo(() => getProfileFromUser(user), [user]);

	const {
		data: profile = defaultProfile,
		refetch: fetchProfile, // TODO: remove this ?
		isSuccess: isProfileFetched,
	} = useFetchGraphGlobalsProfile();
	const setProfile = useSetGraphGlobalsProfile();

	const patchProfile = useCallback(
		(patch) => setProfile((currentProfile) => ({ ...currentProfile, ...patch })),
		[setProfile],
	);

	const {
		mutate: updateProfile,
		isLoading: isProfileUpdating,
	} = useUpdateProfile();

	const followChannel = useCallback(async (channelId, origin) => {
		const { data } = await channelFriendsApi.followChannel(channelId, origin);
		patchProfile(data);

		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(channelId),
		);
	}, [queryClient, patchProfile]);

	const unfollowChannel = useCallback(async (channelId, origin) => {
		const { data } = await channelFriendsApi.unfollowChannel(channelId, origin);
		patchProfile(data);
		queryClient.invalidateQueries(
			FRIENDS_QUERY_KEYS.fetchFriendStatustByChannelId(channelId),
		);
	}, [queryClient, patchProfile]);

	const updateStatusDisclosed = useCallback(async (statusDisclosed) => {
		const { data } = await channelFriendsApi.updateStatusDisclosed(statusDisclosed);
		patchProfile(data);
	}, [patchProfile]);

	const updatePreferredLanguage = useCallback(async (preferredLanguage) => updateProfile(
		{ preferredLanguage },
		{ onSuccess: (data) => patchProfile(data) },
	), [patchProfile, updateProfile]);

	useEffect(() => {
		if (isProfileFetched && profile) {
			const localPreferredLanguage = localStorage.getItem('i18nextLng');

			if (localPreferredLanguage && !profile.preferredLanguage) {
				updatePreferredLanguage(localPreferredLanguage);
			} else if (localPreferredLanguage !== profile.preferredLanguage) {
				i18n.changeLanguage(profile.preferredLanguage);
			}
		}
	}, [isProfileFetched, profile, updatePreferredLanguage]);

	const contextValue = useMemo(() => ({
		fetchProfile,
		followChannel,
		isProfileUpdating,
		profile,
		updatePreferredLanguage,
		updateStatusDisclosed,
		unfollowChannel,
		patchProfile,
		setProfile,
	}), [
		fetchProfile,
		followChannel,
		isProfileUpdating,
		profile,
		updatePreferredLanguage,
		updateStatusDisclosed,
		unfollowChannel,
		patchProfile,
		setProfile,
	]);

	return (
		<ProfileContext.Provider value={contextValue}>
			{children}
		</ProfileContext.Provider>
	);
};

ProfileProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
