import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FaInfoCircle } from 'react-icons/fa';
import { Popover, PopoverBody } from 'reactstrap';

export const CampaignCreateTooltip = ({ container, target, text }) => {
	const [showPopover, setShowPopover] = useState(false);

	return (
		<>
			<span
				onMouseEnter={() => setShowPopover(true)}
				onMouseLeave={() => setShowPopover(false)}
				id={target}
			>
				<FaInfoCircle
					size={12}
					className="ml-1"
				/>
			</span>
			<Popover
				placement="bottom"
				isOpen={showPopover}
				target={target}
				container={container}
			>
				<PopoverBody className="text-black">
					{text}
				</PopoverBody>
			</Popover>
		</>
	);
};

CampaignCreateTooltip.propTypes = {
	container: PropTypes.string.isRequired,
	target: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
};
