import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Badge } from 'reactstrap';
import { FaBook, FaTeamspeak, FaFileInvoice, FaQuestionCircle, FaUsers } from 'react-icons/fa';

import { Path, getChannelLink, getLink } from '../../RoutePath';
import { useFetchTotalUnreadCount } from '../../api-hooks/conversation';
import { useAuthentication } from '../../components/Authentication/Authentication';
import { useMenu } from '../../components/Drawer/Context';
import { useProfile } from '../../components/Profile/ProfileContext';
import { usePwa } from '../../components/Pwa/Provider';
import { StudioLoadingType } from '../../components/Studio/LoadingType';
import { useCurrentStudio } from '../../components/Studio/useCurrentStudio';
import { StudioScheduleModal, useStudioSchedule } from '../../components/StudioSchedule/Context';
import { UserSettingsModal, useUserSettings } from '../../components/UserSettings/Context';
import { ReactComponent as MyStudioIcon } from '../../images/ic-hp-my-studio.svg';
import { LiveIcon, MeetIcon, MyChannelIcon, MyMessagesIcon, WatchIcon } from '../SidebarMenu/icons';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';
const isComvision = import.meta.env.VITE_PROJECT === 'comvision';

export const useSidebarItems = () => {
	const { user } = useAuthentication();
	const { profile } = useProfile();
	const { t } = useTranslation();
	const { toggleMenu, setActiveTab } = useMenu();
	const { data: unreadCounts } = useFetchTotalUnreadCount();
	const totalUnreadCount = unreadCounts?.total ?? 0;
	const history = useHistory();
	const location = useLocation();
	const { openModal: openUserSettingsModal } = useUserSettings();
	const { openModalCustom } = usePwa();

	const {
		openModal,
		startInstantStudio,
		studioLoadingType,
		instantStudioLoading,
	} = useStudioSchedule();

	const {
		currentStudio,
		isCurrentStudioStarted,
		isCurrentStudioMine,
		isCurrentStudioOperatedByMe,
	} = useCurrentStudio();

	const isControlledStudioStarted = useMemo(() => isCurrentStudioStarted
    && (isCurrentStudioMine || isCurrentStudioOperatedByMe),
	[isCurrentStudioMine, isCurrentStudioOperatedByMe, isCurrentStudioStarted]);

	const toggleSidePanelBreakpoint = 991.98;
	const isCurrentLocationHome = /\/|\/home\/|\/help\//.test(history.location.pathname);
	const isWidthToggleSidebarMobile = window.innerWidth < toggleSidePanelBreakpoint;

	const handleStartInstantStudio = useCallback((isPublic) => {
		startInstantStudio(
			profile._id,
			isPublic,
		);
	}, [startInstantStudio, profile]);

	const generalSidebarItems = useMemo(() => [
		user && profile?.hashtag && (
			{
				label: t('SidebarMenu.Index.myChannel'),
				icon: <MyChannelIcon className="icon" />,
				to: getChannelLink(profile.hashtag),
				hideSidebarOnClick: true,
			}
		),
		user && profile?.hashtag && (
			{
				label: t('SidebarMenu.Index.myMessages'),
				badge: (
					<div
						className="position-absolute sibebar-menu-badge"
					>
						<span className="btn-wrapper--icon mr-1">
							{totalUnreadCount > 0 && (
								<Badge
									color="danger"
									className="UserMenuDrawer_badge badge-circle shadow-none badge-position badge-position--top-left-sidebar-menu"
								>
									<span className="text-xs">{totalUnreadCount}</span>
								</Badge>
							)}
						</span>
					</div>
				),
				icon: <MyMessagesIcon className="icon" />,
				to: location.pathname,
				action: () => {
					toggleMenu();
					setActiveTab('Messages');
				},
				toggleSidebarOnClick: isWidthToggleSidebarMobile,
			}
		),
		user && profile?.hashtag && (
			{
				label: t('SidebarMenu.Index.libraries'),
				icon: <FaBook className="icon" />,
				action: () => {
					openUserSettingsModal(UserSettingsModal.LIBRARY);
				},
				hideSidebarOnClick: true,
				to: location.pathname,
			}
		),
	], [
		isWidthToggleSidebarMobile,
		location,
		openUserSettingsModal,
		profile,
		setActiveTab,
		t,
		toggleMenu,
		totalUnreadCount,
		user,
	]);

	const secondarySidebarItems = useMemo(() => [
		(isBeeyou || !!user) && {
			label: t('SidebarMenu.Index.live'),
			icon: <LiveIcon className="icon" />,
			to: getLink(Path.HOME_ANCHOR, { anchor: 'coming-up-live' }),
			toggleSidebarOnClick: !isCurrentLocationHome,
		},
		(isBeeyou || !!user) && {
			label: isComvision ? t('CV.myTeam') : t('SidebarMenu.Index.meetTheBees'),
			icon: isComvision ? <FaTeamspeak className="icon" /> : <MeetIcon className="icon" />,
			to: getLink(Path.HOME_MEET_BEES),
			toggleSidebarOnClick: false,
		},
		(isBeeyou || !!user) && {
			label: t('SidebarMenu.Index.watchVideos'),
			icon: <WatchIcon className="icon" />,
			to: getLink(Path.HOME_WATCH_VIDEOS),
			toggleSidebarOnClick: false,
		},
		profile && isBeeyou && {
			label: t('SidebarMenu.Index.posts'),
			icon: <FaFileInvoice className="icon" />,
			to: `/${profile.hashtag}?tab=POSTS`,
			toggleSidebarOnClick: false,
		},
	], [isCurrentLocationHome, profile, t, user]);

	const helpCenterParentItem = useMemo(() => ({
		label: t('SidebarMenu.Index.helpCenter'),
		icon: <FaQuestionCircle className="icon" />,
	}), [t]);

	const helpCenterItems = useMemo(() => [
		{ label: t('SidebarMenu.Index.gettingStarted'), to: getLink(Path.HELP_ANCHOR, { anchor: 'gettingStarted' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.userGuide'), to: getLink(Path.HELP_USERGUIDE), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.faqs'), to: getLink(Path.HELP_ANCHOR, { anchor: 'faq' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.troubleshooting'), to: getLink(Path.HELP_ANCHOR, { anchor: 'troubleshooting' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('SidebarMenu.Index.InstallApp'), to: location.pathname, onClick: openModalCustom, hideSidebarMenu: true, toggleSidebarOnClick: true },
	], [location, openModalCustom, t]);

	const aboutParentItem = useMemo(() => ({
		label: t('SidebarMenu.Index.about'),
		icon: <FaUsers className="icon" />,
	}), [t]);

	const aboutSidebarItems = useMemo(() => [
		{ label: t('NewUser.Banner.Blue.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'about' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Green.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'monetization' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Teal.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'philosophy' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
		{ label: t('NewUser.Banner.Yellow.title'), to: getLink(Path.NEWUSERINFO_ANCHOR, { anchor: 'users' }), hideSidebarMenu: true, toggleSidebarOnClick: true },
	], [t]);

	const studioSidebarItems = useMemo(() => [
		!isControlledStudioStarted && {
			label: isComvision ? t('CV.CommandCenter') : t('SidebarMenu.Index.privateMeeting'),
			to: location.pathname,
			hideSidebarMenu: false,
			onClick: () => handleStartInstantStudio(false),
			isLoading: instantStudioLoading && studioLoadingType === StudioLoadingType.MEETING,
		},
		!isControlledStudioStarted && isBeeyou && {
			label: t('SidebarMenu.Index.startPublicLive'),
			to: location.pathname,
			hideSidebarMenu: false,
			onClick: () => handleStartInstantStudio(true),
			isLoading: instantStudioLoading && studioLoadingType === StudioLoadingType.PUBLIC_LIVE,
		},
		isComvision && {
			label: isControlledStudioStarted ? t('CV.JoinCommandCenter') : t('CV.ScheduleCommandCenter'),
			to: getLink(Path.STUDIO),
			hideSidebarMenu: true,
		},
		isBeeyou && {
			label: isControlledStudioStarted ? t('SidebarMenu.Index.join') : t('SidebarMenu.Index.scheduleStudio'),
			to: getLink(Path.STUDIO),
			hideSidebarMenu: true,
		},
		isBeeyou && {
			label: t('SidebarMenu.Index.manage'),
			to: location.pathname,
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openModal(StudioScheduleModal.MANAGE),
			toggleColapse: true,
		},
		isControlledStudioStarted && ({
			label: t('SidebarMenu.Index.controller'),
			to: location.pathname,
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openModal(StudioScheduleModal.EDIT, currentStudio?._id),
		}),
		isBeeyou && {
			label: t('SidebarMenu.Index.calendar'),
			to: location.pathname,
			toggleSidebarOnClick: !isCurrentLocationHome,
			onClick: () => openModal(StudioScheduleModal.CALENDAR),
		},
	].filter(Boolean), [
		currentStudio,
		handleStartInstantStudio,
		instantStudioLoading,
		isControlledStudioStarted,
		isCurrentLocationHome,
		location,
		openModal,
		studioLoadingType,
		t,
	]);

	const studioSidebarParentItem = useMemo(() => ({
		label: t('SidebarMenu.Index.start'),
		icon: <MyStudioIcon className="icon" />,
	}), [t]);

	return useMemo(() => ({
		generalSidebarItems,
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		studioSidebarItems,
		studioSidebarParentItem,
		aboutParentItem,
		aboutSidebarItems,
	}), [
		generalSidebarItems,
		secondarySidebarItems,
		helpCenterItems,
		helpCenterParentItem,
		studioSidebarItems,
		studioSidebarParentItem,
		aboutParentItem,
		aboutSidebarItems,
	]);
};
