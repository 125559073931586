// @ts-check

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Alert } from 'reactstrap';

/**
 * @typedef {{
 * 	onTimeout: () => void,
 * 	timeoutTime?: number,
 * } & import('reactstrap').AlertProps} AlertTimeoutProps
 */

export const AlertTimeout = (
	/** @type {AlertTimeoutProps} */
	{
		onTimeout,
		timeoutTime = 5000,
		...props
	},
) => {
	useEffect(() => {
		const timer = setTimeout(onTimeout, timeoutTime);
		return () => {
			clearTimeout(timer);
		};
	});

	return (
		<Alert {...props} />
	);
};

AlertTimeout.propTypes = {
	onTimeout: PropTypes.func.isRequired,
	timeoutTime: PropTypes.number,
};

AlertTimeout.defaultProps = {
	timeoutTime: 5000,
};
