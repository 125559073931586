import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Field, FieldError } from 'react-jsonschema-form-validation';
import { FormGroup, Input } from 'reactstrap';
import { useAsyncCallback } from 'react-async-hook';
import { useTranslation } from 'react-i18next';

import { ButtonPillOutline } from '../../../Button';
import FormSubmit from '../../../Form/Submit';
import { FormEmails } from '../../../Form/Emails';
import { FormLabel } from '../../../Form/Label';
import { ResourceAccessRole } from '../../../../lib/ResourceAccessRole';
import { ShareRecipientType, ShareTabType } from '../../Share.helper';
import { ShareRoleInput } from '../../RoleInput';
import { AlertTimeout } from '../../../Alert/Timeout';

import './Email.scss';

const emailSchema = {
	type: 'object',
	additionalProperties: false,
	properties: {
		emails: {
			type: 'array',
			items: {
				type: 'string',
				format: 'email',
			},
			minItems: 1,
		},
		message: {
			type: 'string',
			maxLength: 1000,
		},
	},
};

const getInitialData = (defaultMessage) => ({
	emails: [],
	message: defaultMessage || '',
});

export const ShareTabEmail = ({
	alreadySharedUsers,
	defaultMessage,
	inputColor,
	onShare,
	roles,
	selectedRole,
	setSelectedRole,
	shareButtonTranslationKey,
	showSuccessMessage,
}) => {
	const { t } = useTranslation();

	const [data, setData] = useState(getInitialData(defaultMessage));

	const clear = () => setData((s) => ({ ...s, emails: [] }));

	const handleSubmitAsync = useAsyncCallback(async () => {
		const alreadySharedEmails = alreadySharedUsers.reduce((acc, participant) => {
			if (participant.type !== ShareRecipientType.EMAIL) return acc;
			return {
				...acc,
				[participant.email]: participant,
			};
		}, {});

		const emails = data.emails.map((email) => {
			if (!alreadySharedEmails[email]) {
				return {
					type: ShareRecipientType.EMAIL,
					email,
					message: data.message,
				};
			}
			return null;
		});
		await onShare(emails, selectedRole);
		clear();
	});

	const handleChange = (values) => {
		setData({
			...data,
			...values,
		});
	};

	const isEmpty = !(data.emails?.length > 0);
	const isActionDisabled = isEmpty || handleSubmitAsync.loading;

	const oppositeInputColor = inputColor === 'dark' ? 'light' : 'dark';

	return (
		<Form
			className="ShareTabEmail"
			schema={emailSchema}
			data={data}
			onSubmit={handleSubmitAsync.execute}
		>
			{showSuccessMessage && handleSubmitAsync.status === 'success' && (
				<AlertTimeout color="success" onTimeout={handleSubmitAsync.reset} timeoutTime={3000}>
					{t('Share.shareSuccessfullySent')}
				</AlertTimeout>
			)}
			{handleSubmitAsync.error && (
				<AlertTimeout color="danger" onTimeout={handleSubmitAsync.reset}>
					{t('Global.error')}
				</AlertTimeout>
			)}
			<ShareRoleInput
				value={selectedRole}
				onChange={(role) => setSelectedRole(role)}
				roles={roles}
				shareTabType={ShareTabType.EMAIL}
			/>
			<FormGroup>
				<FormLabel htmlFor="emails">{t('Share.sendTo')}</FormLabel>
				<Field
					component={FormEmails}
					name="emails"
					placeholder={t('Share.Tab.Email.emails')}
					emails={data.emails}
					className={`bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
					tagClassName={`bg-${oppositeInputColor} content-${oppositeInputColor}`}
					onChange={(emails) => handleChange({ emails })}
				/>

				<FieldError name="emails" />
			</FormGroup>
			<div className="d-flex flex-column flex-sm-row justify-content-start justify-content-sm-between align-items-start align-items-sm-center pl-1">
				<div className="d-flex mb-3">
					<ButtonPillOutline
						className="mr-2"
						color={inputColor}
						disabled={isActionDisabled}
						onClick={() => { clear(); }}
					>
						{t('Share.Buttons.clear')}
					</ButtonPillOutline>
					<FormSubmit
						className="btn-pill shadow-none"
						disabled={isActionDisabled}
						loading={handleSubmitAsync.loading}
						title={t('Share.Tab.Email.sendInvitationByEmail')}
					>
						{t(shareButtonTranslationKey)}
					</FormSubmit>
				</div>
			</div>
			<FormGroup>
				<FormLabel>{t('Share.Message.message')}</FormLabel>
				<Field
					className={`bg-${inputColor} content-${inputColor} rounded-1 border-${inputColor}`}
					component={Input}
					name="message"
					onChange={(e) => handleChange({ message: e.target.value })}
					placeholder={t('Share.Message.addCustomMessage')}
					rows="3"
					type="textarea"
					value={data.message}
				/>
				<FieldError name="message" />
			</FormGroup>
		</Form>
	);
};

ShareTabEmail.propTypes = {
	alreadySharedUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	defaultMessage: PropTypes.string,
	inputColor: PropTypes.oneOf(['dark', 'light']),
	onShare: PropTypes.func.isRequired,
	roles: PropTypes.arrayOf(PropTypes.oneOf(Object.keys(ResourceAccessRole))).isRequired,
	selectedRole: PropTypes.oneOf(Object.keys(ResourceAccessRole)).isRequired,
	setSelectedRole: PropTypes.func.isRequired,
	shareButtonTranslationKey: PropTypes.string.isRequired,
	showSuccessMessage: PropTypes.bool,
};

ShareTabEmail.defaultProps = {
	defaultMessage: '',
	inputColor: 'dark',
	showSuccessMessage: true,
};
