import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row } from 'reactstrap';
import { ModalScreen } from '../../../Modal/Screen/Screen';
import { useUserSettings } from '../../../UserSettings/Context';
import { AdvertisingDashboard } from '../../../UserSettings/AnalyticsAndAdvertising/Advertising/Dashboard/Dashboard';
import { ModalScreenHeader } from '../../../Modal/Screen/Header';

export const AdminAdCampaignOverviewModal = ({ isOpen, adCampaignId }) => {
	const { closeModal } = useUserSettings();

	return (
		<ModalScreen
			bodyClassName="bg-gray py-0"
			isOpen={isOpen}
			onClose={closeModal}
			className="AnalyticsAndAdvertisingModal"
			header={(
				<ModalScreenHeader
					onClose={closeModal}
					title="Ad Campaign Overview"
				/>
			)}
		>
			<Container>
				<Row className="py-4 content-dark">
					<AdvertisingDashboard
						adCampaignId={adCampaignId}
					/>
				</Row>
			</Container>
		</ModalScreen>
	);
};

AdminAdCampaignOverviewModal.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	adCampaignId: PropTypes.string,
};

AdminAdCampaignOverviewModal.defaultProps = {
	adCampaignId: undefined,
};
