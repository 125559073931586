import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { Collapse, NavItem, NavLink, Spinner } from 'reactstrap';
import { Link } from 'react-router-dom';

export const SidebarHeaderCollapse = ({ parentItem, childrenItems, toggleMenu }) => {
	const [openCollapse, setOpenCollapse] = useState(false);

	const handleCollapse = useCallback(() => {
		setOpenCollapse((prevCollapse) => !prevCollapse);
	}, []);

	const handleClick = useCallback((childItem) => {
		const { onClick, hideSidebarMenu, toggleSidebarOnClick } = childItem;

		if (onClick) {
			onClick();
		}

		if (hideSidebarMenu) {
			setOpenCollapse(false);
		}

		if (toggleSidebarOnClick) {
			toggleMenu();
		}
	}, [toggleMenu]);

	return (
		<NavItem>
			<NavLink onClick={handleCollapse}>
				{!!parentItem.icon && (
					<span className="btn-wrapper--icon mr-2">
						{parentItem.icon}
					</span>
				)}
				{parentItem.label}
				<span className="btn-wrapper--icon ml-2">
					{openCollapse ? <FaChevronUp /> : <FaChevronDown /> }
				</span>
			</NavLink>
			<Collapse className="pl-3" isOpen={openCollapse}>
				{childrenItems.map((childItem) => (
					<NavLink
						key={childItem.label}
						onClick={() => handleClick(childItem)}
						tag={Link}
						to={childItem.to}
					>
						{!!childItem.icon && (
							<span className="btn-wrapper--icon mr-2">
								{childItem.icon}
							</span>
						)}
						{childItem.label}
						{!!childItem.isLoading && <Spinner size="sm" className="ml-3" />}
					</NavLink>
				))}
			</Collapse>
		</NavItem>
	);
};

SidebarHeaderCollapse.propTypes = {
	parentItem: PropTypes.shape({
		label: PropTypes.string,
		icon: PropTypes.node,
	}).isRequired,
	childrenItems: PropTypes.arrayOf(PropTypes.shape({
		label: PropTypes.string,
		icon: PropTypes.node,
		to: PropTypes.string,
		toggleSidebarOnClick: PropTypes.bool,
		toggleColapse: PropTypes.bool,
		isLoading: PropTypes.bool,
	})).isRequired,
	toggleMenu: PropTypes.func.isRequired,
};
