/* eslint-disable react/prop-types */
// @ts-check

import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

/**
 * @typedef {{
 * 	className?: string,
 * }} ListLoadingProps
 */

export const ListLoading = (
	/** @type {ListLoadingProps} */
	{ className = '' },
) => {
	const { t } = useTranslation();
	return (
		<div className={clsx('d-flex', className)}>
			<span className="mr-2">
				<FaSpinner className="icon-spin" />
			</span>
			<strong className="text-truncate">{t('ItemList.Loading')}</strong>
		</div>
	);
};
