import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { FaCompress, FaEllipsisH, FaExpand, FaPaintBrush, FaTimes } from 'react-icons/fa';
import { HiOutlineMagnifyingGlassPlus } from 'react-icons/hi2';
import { AiOutlineZoomIn } from 'react-icons/ai';
import clsx from 'clsx';
import { Button, DropdownMenu, DropdownToggle, Modal, UncontrolledDropdown } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDrawer } from '@technomiam/react-video';

import { isMobile, isMobileOrIpad } from '../../../lib/userAgent';
import { LocalCam } from './LocalUserMedia/LocalCam';
import { LocalMic } from './LocalUserMedia/LocalMic';
import { LocalDrawer } from './LocalUserMedia/LocalDrawer';
import { GuestHandRaising } from './Guest/HandRaising';
import { RecordButton } from './LocalUserMedia/RecordButton';
import { ChannelModeListening, ChannelModeSpeakerSwitch } from './ChannelMode/ChannelMode';
import { ChannelButtonsPanelLayoutSelector } from './LayoutSelector/LayoutSelector';
import { MediaManager } from '../../UserMedia/Manager/Manager';
import { AdButton } from './Ad/Button';
import { useVote } from '../../Vote/Provider';
import { ScreenshotButton } from './LocalUserMedia/ScreenshotButton';
import { LayoutSelectorButton } from './LayoutSelector/Button';
import { LocalMobileCamSwap } from './LocalUserMedia/LocalMobileCamSwap';
import { MuteLiveButton } from './MuteLiveButton';
import { ZoomKnob } from './ZoomKnob';
import ZoomKnobModalPreview from './LocalUserMedia/ZoomKnobModalPreview';
import { SettingsButton } from './Settings/Button';
import { ToolsDropdown } from './Tools/Dropdown';
import { CropButton } from './Crop/Button';
import { LocalCamPreview } from './LocalUserMedia/LocalCamPreview';
import { isLocalSharingCapable, LocalShare } from './LocalUserMedia/LocalShare';
import { TalkbackReplyButton } from './Talkback/ReplyButton';

import './ForStudio.scss';

const isBeeyou = import.meta.env.VITE_PROJECT === 'beeyou';

export const ChannelButtonsPanelForStudio = ({
	centerChildren,
	handleClickFullScreen,
	handleToggleCropVideo,
	handleToggleSettings,
	hashtag,
	isController,
	isFullscreen,
	isHandRaised,
	isMobilePortrait,
	leftChildren,
	onClickRaiseHand,
	onKeySelectBackground,
	rightChildren,
	setAllMuted,
	setHostMuted,
}) => {
	const { t } = useTranslation();
	const { isDrawEnabled, setShowCanvas, showCanvas } = useDrawer();
	const { activeVote } = useVote();

	const [showPipLayoutSelector, setShowPipLayoutSelector] = useState(false);
	const [threeDotsOpen, setThreeDotsOpen] = useState(false);

	const [showZoomKnob, setShowZoomKnob] = useState(false);
	const [isPersonalZoomOpen, setIsPersonalZoomOpen] = useState(false);
	const handleClickPersonalZoom = () => {
		setIsPersonalZoomOpen((prevState) => !prevState);
	};

	const handleClickOpenZoomKnob = () => {
		setShowZoomKnob((prevState) => !prevState);
	};
	useEffect(() => {
		if (activeVote && isDrawEnabled) {
			setShowCanvas(false);
		}
	}, [isDrawEnabled, activeVote, setShowCanvas]);

	const handleToggleDraw = () => {
		setShowCanvas(!showCanvas);
	};

	const [showSettings, setShowSettings] = useState(false);

	const handleClickLayoutSelector = () => {
		setShowPipLayoutSelector((prevState) => !prevState);
		setThreeDotsOpen(false);
	};

	return (
		<>
			{showSettings && (
				<Modal
					centered
					contentClassName="border-0 shadow-lg rounded overflow-hidden bg-darker form-dark align-self-stretch"
					isOpen={showSettings}
					toggle={() => setShowSettings((state) => !state)}
					size="lg"
					zIndex={2000}
				>
					<Button
						color="neutral-secondary"
						className="ml-auto mr-2 shadow btn-pill d-30 p-0 m-2"
						title={t('ChannelButtonsPanel.ChannelButtonsPanel.close')}
						onClick={() => setShowSettings((state) => !state)}
					>
						<span className="btn-wrapper--icon">
							<FaTimes />
						</span>
					</Button>
					<div className="mx-auto col-md-12 col-lg-10 col-xl-7 mt-2">
						<h3 className="display-4 mb-2 font-weight-bold">{t('ChannelButtonsPanel.ChannelButtonsPanel.inputDevices')}</h3>
						<p className="font-size-lg mb-5">
							{t('ChannelButtonsPanel.ChannelButtonsPanel.chooseSource')}
						</p>
						<MediaManager onKeySelectBackground={onKeySelectBackground} />
					</div>
				</Modal>
			)}
			{showPipLayoutSelector && (
				<div className="ChannelButtonsPanelForStudio_PipLayout position-absolute left-0 w-100">
					<div className="d-flex pl-1 pt-1 pb-1 w-100">
						<ChannelButtonsPanelLayoutSelector
							setShowPipLayoutSelector={setShowPipLayoutSelector}
						/>
					</div>
				</div>
			)}
			{isController && isMobile && showZoomKnob && (
				<ZoomKnob
					handleZoomDone={() => setShowZoomKnob(false)}
				/>
			)}
			{!isController && (<LocalCamPreview className="rounded d-none d-lg-block mr-2" />)}
			<div className={clsx('w-100 d-flex flex-column position-relative')}>
				<div className="w-100 d-flex justify-content-between align-items-center position-relative">
					<div className="ForStudio LeftContent d-flex align-items-center">
						<SettingsButton
							className="d-none d-md-inline-flex mr-1"
							handleToggleSettings={() => (
								handleToggleSettings ? handleToggleSettings() : setShowSettings(true)
							)}
						/>
						{isMobileOrIpad ? (
							<LocalMobileCamSwap className="d-30 p-0 mr-1 flex-shrink-0" />
						) : null}
						<LocalCam className="LocalCam d-30 p-0 mr-1 flex-shrink-0" title={t('ChannelButtonsPanel.ChannelButtonsPanel.camera')} />
						<Button
							className="mr-1 shadow-none d-30 p-0"
							onClick={handleClickPersonalZoom}
							color="neutral-secondary"
						>
							<span className="btn-wrapper--icon">
								<HiOutlineMagnifyingGlassPlus size={20} />
							</span>
						</Button>
						<ZoomKnobModalPreview
							isOpen={isPersonalZoomOpen}
							toggle={handleClickPersonalZoom}
							isMobilePortrait={isMobilePortrait}
							isController={isController}
							onClickRaiseHand={onClickRaiseHand}
							isHandRaised={isHandRaised}
						/>
						<LocalMic className="LocalMic d-30 p-0 mr-1 flex-shrink-0" title={t('ChannelButtonsPanel.ChannelButtonsPanel.mic')} />
						<TalkbackReplyButton className="d-30 mr-1 flex-shrink-0" hashtag={hashtag} />
						{isLocalSharingCapable && (<LocalShare hashtag={hashtag} className="d-30 p-0 mr-1 flex-shrink-0 d-none d-sm-flex" />)}
						{isController && (
							<>
								<ChannelModeSpeakerSwitch className="neutral-secondary dh-30 p-0 switch-primary mr-1" />
								<LayoutSelectorButton
									className="mr-1 d-none d-sm-flex"
									showPipLayoutSelector={showPipLayoutSelector}
									handleClickLayoutSelector={handleClickLayoutSelector}
								/>
							</>
						)}
						<div className="d-none d-md-flex">
							{isController && (
								<>
									<RecordButton hashtag={hashtag} className="d-30 p-0 mr-1 btn-animated-icon btn-animated-icon--secondary flex-shrink-0" />
									{isBeeyou && (<AdButton className="d-30 p-0 flex-shrink-0 mr-1 btn-animated-icon btn-animated-icon--secondary" />)}
									<ChannelModeListening setAllMuted={setAllMuted} setHostMuted={setHostMuted} className="neutral-secondary d-30 p-0 mr-1" />
								</>
							)}
							<ScreenshotButton className="d-30 p-0 mr-1 flex-shrink-0 btn-animated-icon btn-animated-icon--secondary" />
							{!isController && (
								<GuestHandRaising
									className="d-30 p-0 mr-1 btn-animated-icon btn-animated-icon--secondary flex-shrink-0 shadow-none"
									onClickRaiseHand={onClickRaiseHand}
									isHandRaised={isHandRaised}
								/>
							)}
						</div>
						<UncontrolledDropdown className="d-md-none" isOpen={threeDotsOpen} toggle={() => setThreeDotsOpen((prev) => !prev)} direction="up">
							<DropdownToggle color="neutral-secondary d-30 p-0 mr-1">
								<FaEllipsisH />
							</DropdownToggle>
							<DropdownMenu className="ChannelButtonsPanelForStudio_ThreeDotsMenu bg-dark shadow-none border border-secondary p-2 d-flex flex-wrap flex-sm-nowrap">
								<SettingsButton
									className="mr-1 mb-1 mb-sm-0"
									handleToggleSettings={() => (
										handleToggleSettings ? handleToggleSettings() : setShowSettings(true)
									)}
								/>
								{isController && (
									<Button
										className="shadow-none border-0 d-30 mr-1 mb-1 mb-sm-0 p-0"
										onClick={() => {
											handleClickOpenZoomKnob();
											setThreeDotsOpen(false);
										}}
										color="neutral-secondary"
									>
										<span className="btn-wrapper--icon">
											<AiOutlineZoomIn />
										</span>
									</Button>
								)}
								{isLocalSharingCapable && (<LocalShare hashtag={hashtag} className="d-30 p-0 mr-1 mb-1 mb-sm-0 flex-shrink-0" />)}
								{isController && (
									<>
										<LayoutSelectorButton
											className="mr-1 mb-1 mb-sm-0 d-sm-none"
											showPipLayoutSelector={showPipLayoutSelector}
											handleClickLayoutSelector={handleClickLayoutSelector}
										/>
										<RecordButton hashtag={hashtag} className="d-30 p-0 mr-1 mb-1 mb-sm-0 btn-animated-icon btn-animated-icon--secondary flex-shrink-0" />
										{isBeeyou && (<AdButton className="d-30 p-0 flex-shrink-0 mr-1 mb-1 mb-sm-0 btn-animated-icon btn-animated-icon--secondary" />)}
										<ChannelModeListening setAllMuted={setAllMuted} setHostMuted={setHostMuted} className="neutral-secondary d-30 p-0 mr-1 mb-1 mb-sm-0" />
									</>
								)}
								<ScreenshotButton className="d-30 p-0 mr-1 mb-1 mb-sm-0 flex-shrink-0 btn-animated-icon btn-animated-icon--secondary" />
								{!isController && (
									<GuestHandRaising
										className="d-30 p-0 mr-1 mb-1 mb-sm-0 btn-animated-icon btn-animated-icon--secondary flex-shrink-0 shadow-none"
										onClickRaiseHand={onClickRaiseHand}
										isHandRaised={isHandRaised}
									/>
								)}
								{isFullscreen && isMobilePortrait && (<CropButton className="mr-1 mb-1 mb-sm-0" handleToggleCropVideo={handleToggleCropVideo} />)}
							</DropdownMenu>
						</UncontrolledDropdown>

						{leftChildren}
					</div>
					{centerChildren && (
						<div className="CenterContent d-flex align-items-center justify-content-center flex-grow-1">
							{centerChildren}
						</div>
					)}
					<div className="RightContent d-flex align-items-center">
						{isController && !isFullscreen
							&& (<ToolsDropdown className="d-none d-xl-flex mr-2" />)}
						{isFullscreen && isMobilePortrait && (
							<CropButton className="d-none d-md-flex" handleToggleCropVideo={handleToggleCropVideo} />
						)}
						{rightChildren}
						<MuteLiveButton title="Mute/Unmute live" className="d-30 p-0 mr-1 flex-shrink-0 shadow-none" />
						{!!handleClickFullScreen && (
							<Button
								className="d-30 p-0 btn-no-focus mx-1"
								color="neutral-secondary bg-transparent"
								title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleScreenMode')}
								onClick={(e) => handleClickFullScreen() && e.preventDefault()}
							>
								<span className="btn-wrapper--icon d-inline-block">
									{isFullscreen
										? <FaCompress size={20} />
										: <FaExpand size={20} />}
								</span>
							</Button>
						)}
						{(isDrawEnabled && !isController) && (
							<div
								className={clsx(
									'ChannelButtonsPanel_drawer r-0 top-0 right-0 d-flex align-items-center',
									showCanvas && 'open',
									isFullscreen ? 'bg-light' : 'bg-darker',
								)}
								onClick={(e) => { e.stopPropagation(); }}
							>
								<Button
									className="d-30 p-0 mr-2"
									color="neutral-secondary"
									title={t('ChannelButtonsPanel.ChannelButtonsPanel.toggleDrawing')}
									onClick={handleToggleDraw}
								>
									<span className="btn-wrapper--icon">
										{showCanvas ? <FaTimes /> : <FaPaintBrush />}
									</span>
								</Button>
								{showCanvas && <LocalDrawer />}
							</div>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

ChannelButtonsPanelForStudio.propTypes = {
	centerChildren: PropTypes.node,
	handleClickFullScreen: PropTypes.func,
	handleToggleCropVideo: PropTypes.func,
	handleToggleSettings: PropTypes.func,
	hashtag: PropTypes.string,
	isController: PropTypes.bool,
	isFullscreen: PropTypes.bool,
	isHandRaised: PropTypes.bool,
	isMobilePortrait: PropTypes.bool,
	leftChildren: PropTypes.node,
	onClickRaiseHand: PropTypes.func,
	onKeySelectBackground: PropTypes.func,
	rightChildren: PropTypes.node,
	setAllMuted: PropTypes.func,
	setHostMuted: PropTypes.func,
};

ChannelButtonsPanelForStudio.defaultProps = {
	centerChildren: null,
	handleClickFullScreen: undefined,
	handleToggleCropVideo: undefined,
	handleToggleSettings: undefined,
	hashtag: null,
	isController: false,
	isFullscreen: false,
	isHandRaised: false,
	isMobilePortrait: false,
	leftChildren: null,
	onClickRaiseHand: undefined,
	onKeySelectBackground: undefined,
	rightChildren: null,
	setAllMuted: undefined,
	setHostMuted: undefined,
};
