import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from 'react';
// eslint-disable-next-line import/no-unresolved
import { useMixing, useMediaCrop } from '@technomiam/react-video';
import { isMobileOrIpad } from '../../../lib/userAgent';
import wheel from './wheel.png';
import './ZoomKnob.scss';

const MAX_ZOOM = 4;
const maxRotate = {
	min: -50,
	max: 50,
};
let currentAngle = maxRotate.max;

export const ZoomKnob = ({
	handleZoomDone,
	isPersonalZoom,
	isMobilePortrait,
}) => {
	const { notifyCrop } = useMixing();
	const { setPersonalCrop } = useMediaCrop();
	const [rotationAngle, setRotationAngle] = useState(currentAngle);
	const [isDragging, setIsDragging] = useState(false);
	const containerRef = useRef(null);

	const calculateZoomCoordinates = useCallback((zoomFactor) => {
		const canvasSize = isMobilePortrait ? { width: 720, height: 1280 }
			: { width: 1280, height: 720 };

		const canvasCenter = {
			x: canvasSize.width / 2,
			y: canvasSize.height / 2,
		};

		const zoomedWidth = canvasSize.width / zoomFactor;
		const zoomedHeight = canvasSize.height / zoomFactor;

		const offsetX = (canvasSize.width - zoomedWidth) * (canvasCenter.x / canvasSize.width);
		const offsetY = (canvasSize.height - zoomedHeight) * (canvasCenter.y / canvasSize.height);

		const topLeft = {
			x: offsetX,
			y: offsetY,
		};

		const topRight = {
			x: offsetX + zoomedWidth,
			y: offsetY,
		};

		const bottomLeft = {
			x: offsetX,
			y: offsetY + zoomedHeight,
		};

		return {
			x: topLeft.x,
			y: topLeft.y,
			width: topRight.x - topLeft.x,
			height: bottomLeft.y - topLeft.y,
		};
	}, [isMobilePortrait]);

	const handleZoomGlobal = useCallback((zoomFactor) => {
		const { x, y, width, height } = calculateZoomCoordinates(zoomFactor);

		if (isPersonalZoom) {
			if (zoomFactor === 1) {
				setPersonalCrop(undefined);
				return;
			}
			setPersonalCrop({
				factor: zoomFactor,
				x,
				y,
				width,
				height,
				orientation: isMobilePortrait,
			});
		} else {
			notifyCrop({
				x,
				y,
				width,
				height,
				size: {
					width: 1280,
					height: 720,
				},
			});
		}
	}, [notifyCrop, setPersonalCrop, isPersonalZoom, calculateZoomCoordinates, isMobilePortrait]);

	const handleMouseMove = useCallback((e) => {
		if (!isDragging) return;

		const { clientX } = isMobileOrIpad ? e.touches[0] : e;
		const { left, width } = containerRef.current.getBoundingClientRect();

		const mouseX = clientX - left;
		const centerX = width / 2;

		let degrees = (mouseX - centerX) * 0.5;

		if (degrees >= maxRotate.max) degrees = maxRotate.max;
		if (degrees <= maxRotate.min) degrees = maxRotate.min;

		setRotationAngle(degrees);
	}, [isDragging]);

	const handleMouseClick = useCallback(() => {
		setIsDragging(true);
	}, []);

	const getZoomFactorFromAngle = useCallback(() => {
		let factor = Math.abs((rotationAngle + maxRotate.min) / 100.0) * MAX_ZOOM;
		if (factor < 1) factor = 1;
		if (factor > MAX_ZOOM) factor = MAX_ZOOM;
		currentAngle = rotationAngle;
		return factor.toFixed(2);
	}, [rotationAngle]);

	useEffect(() => {
		handleZoomGlobal(1);
		currentAngle = maxRotate.max;
		setRotationAngle(currentAngle);
	}, [handleZoomGlobal, isMobilePortrait]);

	const handleMouseEnd = useCallback(() => {
		setIsDragging(false);

		const zoomFactor = getZoomFactorFromAngle();

		handleZoomGlobal(zoomFactor);
		if (handleZoomDone) handleZoomDone();
	}, [handleZoomDone, handleZoomGlobal, getZoomFactorFromAngle]);

	const globalZoomStyle = {
		overflow: 'hidden',
		top: '-55px',
		left: '50%',
		width: '50%',
		height: '100%',
		backgroundColor: 'rgba(0, 0, 0, 0.2)',
		margin: 0,
	};
	const personalZoomStyle = {
		bottom: '0',
	};

	return (
		<div
			ref={containerRef}
			className="position-absolute"
			style={isPersonalZoom ? personalZoomStyle : globalZoomStyle}
			onMouseDown={handleMouseClick}
			onMouseMove={handleMouseMove}
			onMouseUp={handleMouseEnd}
			onTouchMove={handleMouseMove}
			onTouchStart={handleMouseClick}
			onTouchEnd={handleMouseEnd}
		>
			<p
				className="position-absolute end-50"
				style={{
					marginTop: isPersonalZoom ? '30px' : '25px',
					fontSize: isPersonalZoom ? '1.5rem' : '1.2rem',
				}}
			>
				{getZoomFactorFromAngle()}
			</p>
			<img
				className={isPersonalZoom ? 'ZoomKnobWheel' : 'ZoomKnobWheelGlobal'}
				src={wheel}
				width="100%"
				draggable="false"
				style={{
					transform: `rotate(${rotationAngle}deg)`,
				}}
			/>
		</div>
	);
};

ZoomKnob.propTypes = {
	handleZoomDone: PropTypes.func,
	isPersonalZoom: PropTypes.bool,
	isMobilePortrait: PropTypes.bool,
};

ZoomKnob.defaultProps = {
	isPersonalZoom: false,
	handleZoomDone: undefined,
	isMobilePortrait: false,
};
